import { Component, HostBinding, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { AuthService } from 'app/core/services/auth.service';

@Component({
  selector: 'change-language-button',
  templateUrl: './translate-button.component.html',
  styleUrls: []
})
export class TranslateButtonComponent implements OnInit {

    @ViewChild(TemplateRef) template: TemplateRef<any>;

    languages: Array<{ label: string, code: string }> = [
        { label: 'Use Default',                     code: ''                    },
        { label: 'languages.english',               code: 'english'             },
        { label: 'languages.traditional_chinese',   code: 'traditional_chinese' },
        { label: 'languages.simplified_chinese',    code: 'simplified_chinese'  },
        { label: 'languages.japanese',              code: 'japanese'            },
        { label: 'languages.deutsch',               code: 'deutsch'             },
        { label: 'languages.italian',               code: 'italian'             },
        { label: 'languages.french',                code: 'french'              },
        { label: 'languages.spanish',               code: 'spanish'             },
        { label: 'languages.pakistani',             code: 'pakistani'           },
    ];

    ref: MatBottomSheetRef<any> = null;

    constructor(
        private bottomSheet: MatBottomSheet,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.authService.loginUser.subscribe(u => {
            if( u && u.isPlatformAdmin ) {
                this.languages.push({ label: 'languages.debug', code: 'debug' });
            } else {
                this.languages = this.languages.filter(l => l.code != 'debug');
            }
        })
    }

    @HostBinding('click')
    open()
    {
        this.ref = this.bottomSheet.open(this.template);
    }

    setLanguage(lang: string)
    {
        this.authService.setForceLanguage(lang);
        if( this.ref ) {
            this.ref.dismiss();
        }
    }

}
