<div class="paginator-wrapper" [ngStyle]="{display: mode == 'desktop' ? '' : 'none'}">
    <div class="description">
        <span style="color:#444"><b>{{ 'datatable.total_rows_1' | translate }}:</b> {{ total }} <b>{{ 'datatable.total_rows_2' | translate }}</b></span>
    </div>
    <div class="paginator">
        <span style="margin-right: 1em;white-space: nowrap;">
            {{ 'datatable.show_rows_1' | translate }}
            <mat-select [attr.title]="form.get('limit')?.value" [value]="form.value.limit" (selectionChange)="form.get('limit')?.setValue($event.value)" class="form-control" style="display:inline-block;width: 5.5em;" [disabled]="fixed_limit">
                <mat-option *ngFor="let limit of limit_options" [value]="limit">{{ limit }}</mat-option>
            </mat-select>
            {{ 'datatable.show_rows_2' | translate }}
        </span>
        <span class="paginator-buttons">
            <span>{{ 'datatable.page' | translate }}:</span>
            <button mat-icon-button class="paginator-button" [disabled]="page == 1" (click)="handlePageBackward(0)" href="javascript:void"> <mat-icon>fast_rewind</mat-icon> </button>
            <button mat-icon-button class="paginator-button" [disabled]="page == 1" (click)="handlePageBackward(1)" href="javascript:void"> <mat-icon style="transform: rotate(180deg);">play_arrow</mat-icon> </button>
            <div [attr.title]="form.get('page')?.value">
                <mat-select [value]="form.value.page" (selectionChange)="form.get('page')?.setValue($event.value)" style="display:inline-block; width: 5.5em;" class="form-control">
                    <mat-option *ngFor="let page of forLoop(total_page | async)" [value]="page">{{ page }}</mat-option>
                </mat-select>
                <span style="margin-left: 8px;"> / {{total_page | async}}</span>
            </div>
            <button mat-icon-button class="paginator-button" [disabled]="page == (total_page | async)" (click)="handlePageForward(1)" href="javascript:void"> <mat-icon>play_arrow</mat-icon> </button>
            <button mat-icon-button class="paginator-button" [disabled]="page == (total_page | async)" (click)="handlePageForward(0)" href="javascript:void"> <mat-icon>fast_forward</mat-icon> </button>
        </span>
    </div>
</div>
<div class="paginator-wrapper-mobile" [ngStyle]="{display: mode == 'mobile' ? '' : 'none'}">
    <div class="description">
        <div class="rows"><b>{{ 'datatable.total_rows_1' | translate }}:</b> {{ total }} <b>{{ 'datatable.total_rows_2' | translate }}</b></div>
        <div class="limit">
            {{ 'datatable.show_rows_1' | translate }}
            <mat-select [attr.title]="form.get('limit')?.value" [value]="form.value.limit" (selectionChange)="form.get('limit')?.setValue($event.value)" class="form-control" style="display:inline-block;width: 5.5em;" [disabled]="fixed_limit">
                <mat-option *ngFor="let limit of limit_options" [value]="limit">{{ limit }}</mat-option>
            </mat-select>
            {{ 'datatable.show_rows_2' | translate }}
        </div>
    </div>
    <div class="paginator">
        <button mat-icon-button class="paginator-button" [disabled]="page == 1" (click)="handlePageBackward(0)" href="javascript:void"> <mat-icon>fast_rewind</mat-icon> </button>
        <button mat-icon-button class="paginator-button" [disabled]="page == 1" (click)="handlePageBackward(1)" href="javascript:void"> <mat-icon style="transform: rotate(180deg);">play_arrow</mat-icon> </button>
        <mat-select [attr.title]="form.get('page')?.value" [value]="form.value.page" (selectionChange)="form.get('page')?.setValue($event.value)" style="display:inline-block; width: 5.5em;" class="form-control">
            <mat-option *ngFor="let page of forLoop(total_page | async)" [value]="page">{{ page }}</mat-option>
        </mat-select>
        <span style="margin-left: 8px;"> / {{total_page | async}}</span>
        <button mat-icon-button class="paginator-button" [disabled]="page == (total_page | async)" (click)="handlePageForward(1)" href="javascript:void"> <mat-icon>play_arrow</mat-icon> </button>
        <button mat-icon-button class="paginator-button" [disabled]="page == (total_page | async)" (click)="handlePageForward(0)" href="javascript:void"> <mat-icon>fast_forward</mat-icon> </button>
    </div>
</div>
