import { ContentChild, Directive, Input, ViewChild, Component, HostListener, ElementRef, HostBinding } from "@angular/core";

@Directive({ 
    selector: '[dt-auto-grid],[dtAutoGrid]', 
    host: { class: 'auto-grid-wrapper' },
})
export class DtAutoGridWrapper {

    @Input('dt-auto-grid')
    get autoGridDash(): number { return this._unitWidth; }
    set autoGridDash(width: number) { this._setWidthInput(width); }

    @Input('dtAutoGrid')
    get autoGrid(): number { return this._unitWidth; }
    set autoGrid(width: number) { this._setWidthInput(width); }

    _unitWidth: number = 220;

    _setWidthInput(width: number) { 
        if( width ) {
            this._unitWidth = width; 
        }
    }

    // Columns
    _cols: number = null;
    get cols() { return this._cols; }
    set cols(cols: number) {
        if( cols && cols != this._cols ) {
            this._cols = cols;

            for(let i = 1 ; i <= 12 ; i++) {
                const col = i < cols ? i : cols;
                this.el.nativeElement.style.setProperty(`--auto-grid-col-${i}`, col);
            }
        }
    }

    constructor(public el: ElementRef<any>) { }

    ngAfterViewInit() {
        setTimeout(this.onResize.bind(this), 10);
        const el = this.el.nativeElement;
        el.style.setProperty('--auto-grid-col-min-width', this._unitWidth + 'px');
    }

    @HostListener('window:resize', ['$event'])
    onResize = throttle((event?: any) => {
        
        if( !this.el ) { return; }

        const wrapper = this.el.nativeElement;
        const wrapper_width: number = wrapper.offsetWidth;
        
        if( wrapper && wrapper instanceof Element ) {
            const col_width = parseInt(window.getComputedStyle(wrapper).gridTemplateColumns.split(' ')[0].replace("px", ""));
            const cols = Math.floor( wrapper_width / col_width );
            
            this.cols = cols;
        }

    }, 200);

}

@Directive({ selector: '[dt-auto-grid-col],[dtAutoGridColumn]' })
export class DtAutoGridColumn {

    @Input('dt-auto-grid-col')
    get autoGridColDash(): 1|2|3|4|5|6|7|8|9|10|11|12 { return this._columns; }
    set autoGridColDash(width: 1|2|3|4|5|6|7|8|9|10|11|12) { this._setColumns(width); }

    @Input('dtAutoGridColumn')
    get autoGridCol(): 1|2|3|4|5|6|7|8|9|10|11|12 { return this._columns; }
    set autoGridCol(width: 1|2|3|4|5|6|7|8|9|10|11|12) { this._setColumns(width); }

    _columns: 1|2|3|4|5|6|7|8|9|10|11|12 = 1;
    _setColumns(width: 1|2|3|4|5|6|7|8|9|10|11|12) { 
        if( width ) {
            this._columns = width; 
        }
    }

    constructor() { }

    @HostBinding('class.auto-grid-col-1')  get grid_cols_1(): boolean  { return this._columns == 1;  };
    @HostBinding('class.auto-grid-col-2')  get grid_cols_2(): boolean  { return this._columns == 2;  };
    @HostBinding('class.auto-grid-col-3')  get grid_cols_3(): boolean  { return this._columns == 3;  };
    @HostBinding('class.auto-grid-col-4')  get grid_cols_4(): boolean  { return this._columns == 4;  };
    @HostBinding('class.auto-grid-col-5')  get grid_cols_5(): boolean  { return this._columns == 5;  };
    @HostBinding('class.auto-grid-col-6')  get grid_cols_6(): boolean  { return this._columns == 6;  };
    @HostBinding('class.auto-grid-col-7')  get grid_cols_7(): boolean  { return this._columns == 7;  };
    @HostBinding('class.auto-grid-col-8')  get grid_cols_8(): boolean  { return this._columns == 8;  };
    @HostBinding('class.auto-grid-col-9')  get grid_cols_9(): boolean  { return this._columns == 9;  };
    @HostBinding('class.auto-grid-col-10') get grid_cols_10(): boolean { return this._columns == 10; };
    @HostBinding('class.auto-grid-col-11') get grid_cols_11(): boolean { return this._columns == 11; };
    @HostBinding('class.auto-grid-col-12') get grid_cols_12(): boolean { return this._columns == 12; };

}

const throttle = (func: Function, threshhold: number) => {
    
    var last, timer;
    
    if( threshhold <= 0 ) threshhold = 250;
    
    return function () {
        var context = this;
        var args = arguments;
        var now = +new Date()
        
        if (last && now < last + threshhold) {
            if( timer ) clearTimeout(timer);

            timer = setTimeout(() => {
                last = now
                func.apply(context, args)
            }, threshhold);
        } else {
            if( timer ) clearTimeout(timer);

            last = now;
            func.apply(context, args);
        }
    }
}