import { Webtag } from './../../pages/admin/webtags/models';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Observable, Subscription, merge, Subject } from 'rxjs';
import { tap, map, catchError, debounceTime } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

export enum AccountRoles {
    SystemAdmin = 0,
    ProductAdmin = 1,
    PlatformAdmin = 2,
    WebtagAdmin = 3,
    NormalUser = 4,
}

export interface LoginUser {
    account_id: string;
    account_email: string;
    account_name: string;
}

@Injectable({ providedIn: 'root' })
export class LanguageService {

    constructor() {}

    public languageLoadingSubject: Subject<boolean> = new Subject<boolean>();
    public languageLoading: Observable<boolean> = this.languageLoadingSubject.pipe(debounceTime(100));

}
