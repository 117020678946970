import { Component, OnInit, Inject } from '@angular/core';
import { InputDialogConfig } from '../model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-input-dialog',
    templateUrl: './input-dialog.component.html',
    styleUrls: []
})
export class InputDialogComponent implements OnInit {

    constructor(
        public dialogRef: MatDialogRef<InputDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputDialogConfig,
    ) {}

    ngOnInit(): void {}

}
