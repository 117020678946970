import { InjectionToken } from "@angular/core";
import { FormArray, FormGroup, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from "@angular/forms";
import { BehaviorSubject, Observable, Subject } from "rxjs";

/**
 * Form Settings
 */
export const FORM_DEFAULT_STYLE = new InjectionToken<FormInputStyles>('form-style-token', {
    factory: () => 'classic'
});

export type FormInputStyles =
    | 'classic'
    | 'float'
    | 'fill'
    | 'outline'
    | 'mobile';

export class CfgForm {

    // Define input type
    style?: FormInputStyles;

    // FormGroup validators.
    validators: Array<ValidatorFn>;

    // Inputs
    inputs: Array<FormInput>;

    // Form instance;
    form!: UntypedFormGroup;

    // File Store
    fileStore: {[key: string]: FileList | null} = {};

    cols: CfgFormColumns;

    colMinWidth?: string;

	responsive: CfgFormResponsiveOptions = {};

    constructor(config: {
        // Define input type - default is classic
        style?: FormInputStyles,

        // FormGroup validators.
        validators?: Array<ValidatorFn>,

        // The inputs of this form
        inputs: Array<FormInput>,

        cols?: CfgFormColumns,

        colMinWidth?: string,

		responsive?: CfgFormResponsiveOptions,
    })
    {
        this.style = config.style;
        this.validators = config.validators || [];
        this.inputs = config.inputs;
        this.cols = config.cols || 1;
        this.colMinWidth = config.colMinWidth;
		this.responsive = config.responsive;

        this.initForm();
    }

    // Init form group
    initForm()
    {
        const form_controls: any = {};
        this.inputs.forEach((i, index) => {

            const fc = this._toFormControl(i);

            if( i.type === 'file' ) {
                this.fileStore[i.name] = null;
            }

            form_controls[i.name] = fc;
        });

        this.form = new UntypedFormGroup(form_controls, this.validators);
    }

    _toFormControl(i: FormInput): UntypedFormControl | FormArray {
        if (i.type === 'array') {
            const array = i as InputArray;
            const group = [];
            if (array.defaultValue && array.defaultValue.length > 0) {
                array.defaultValue.forEach((value, index) => {
                    const group_item = {};
                    array.inputs.forEach(input => {
                        const fc = this._toFormControl(input);
                        group_item[input.name] = fc;
                    });
                    const fg = new UntypedFormGroup(group_item, array.validators ? array.validators : []);
                    fg.patchValue(value);
                    group.push(fg);
                });
            }

            return new FormArray(Object.values(group), array.validators ? array.validators : []);
        }

        const fc = new UntypedFormControl(i.defaultValue !== undefined ? i.defaultValue : '', i.validators ? i.validators : []);
        if( i.disabled !== undefined || i.readonly !== undefined ) {
            if( i.disabled !== undefined && i.disabled || i.readonly !== undefined && i.readonly ) {
                fc.disable();
            }
        }

        if( i.type === 'time' && i.defaultValue && typeof i.defaultValue !== 'string' ) {
            let h: string = (''+(i.defaultValue.hour || 0)).padStart(2, '0');
            let m: string = (''+(i.defaultValue.minute || 0)).padStart(2, '0');

            fc.patchValue(`${h}:${m}`);
        }

        return fc;
    }

    getValue(): any
    {
        const value: any = Object.assign({}, this.form.getRawValue());
        this.inputs.forEach(i => {
            if( i.type === 'file' ) {
                value[i.name] = this.fileStore[i.name];
            }
        });

        return value;
    }

    // Lock form
    lock() { this.form.disable(); }

    // Unlock form
    unlock()
    {
        this.form.enable();
        this.updateInputDisabledState();
    }

    updateInputDisabledState()
    {
        this.inputs.forEach( i => {
            const ctrl = this.form.get(i.name);
            if( !ctrl ) return;
            i.disabled ? ctrl.disable() : ctrl.enable();
        });
    }

    setFile( name: string, files: FileList )
    {
        this.fileStore[name] = files;
    }

	markFormTouched()
	{
		this.markFormGroupTouched( this.form );
		this.form.markAsTouched();
	}

	private markFormGroupTouched(formGroup: UntypedFormGroup)
	{
		(<any>Object).values(formGroup.controls).forEach((control: UntypedFormControl | UntypedFormGroup) => {
			control.markAsTouched();

			if (control['controls'])
			{
				this.markFormGroupTouched(control as UntypedFormGroup);
			}
			else
			{
				control.patchValue( control.value, { onlySelf: true } );
			}
		});
	}

    setSelectOptions(name: string, options: FormSelectOption[])
    {
        const input_index = this.inputs.findIndex(input => input.name === name);
        (this.inputs[input_index] as SelectInput).options = options;
        this.inputs[input_index].optionChanged.next(true);
    }

    setListOptions(name: string, options: string[])
    {
        const input_index = this.inputs.findIndex(input => input.name === name);
        const input = this.inputs[input_index] as TextInput;

        // if (input.type != 'text') { return; }
        input.datalist = options;
        input.optionChanged.next(true);
    }

    getInput(name: string): FormInput | null {
        return this.inputs.find(i => i.name == name);
    }

    formArrayAddRow(name: string) {
        const input_index = this.inputs.findIndex(input => input.name === name);
        const input = this.inputs[input_index] as InputArray;
        if (input.type != 'array') { return; }

        const fg = new FormGroup({});
        input.inputs.forEach(input => fg.addControl(input.name, this._toFormControl(input)));

        const form_arr: FormArray = this.form.controls[name] as FormArray;
        if (form_arr) {
            form_arr.push(fg);
        }
    }

    formArrayRemoveRow(name: string, index: number) {
        const input_index = this.inputs.findIndex(input => input.name === name);
        const input = this.inputs[input_index] as InputArray;
        if (input.type != 'array') { return; }

        const form_arr: FormArray = this.form.controls[name] as FormArray;
        if (form_arr) {
            form_arr.removeAt(index);
        }
    }

}


/**
 * Input Settings
 */
export type FormInput =
    | TextInput
    | TextareaInput
    | NumberInput
    | SelectInput
    | CheckboxInput
    | RadioInput
    | DateInput
    | TimeInput
    | FileInput
    | CustomInput
    | InputArray;

export interface FormInputInterface extends
      TextInput
    , TextareaInput
    , NumberInput
    , SelectInput
    , CheckboxInput
    , RadioInput
    , DateInput
    , TimeInput
    , FileInput
    , CustomInput
    , InputArray
{}


// Available input types
export type FormInputType =
    | 'text'
    | 'password'
    | 'textarea'
    | 'select'
    | 'number'
    | 'checkbox'
    | 'radio'
    | 'date'
    | 'time'
    | 'file'
    | 'custom'
    | 'array'; // Array input is a repeatable input group.

// HTML Input Type
export type HTMLInputType =
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
;

// An default select option struct
export interface FormSelectOption {

    label: string;

    value: unknown;

    disabled?: boolean;

    hidden?: boolean;

}

interface BASE_OPTIONS {

    name: string;

    label: string;

	icon?: string;

    defaultValue?: unknown;

    validators?: Array<ValidatorFn>;

    hint?: string;

    readonly?: boolean;

    disabled?: boolean;

    cols?: CfgFormColumns;

    disableAutoScale?: boolean;

    errorMessages?: FormErrorMessageRules;

	breakLine?: boolean;

	autoCompleteOff?: boolean;

}

// Input setting base class
export class BaseInput
{

    // Define input type
    type: FormInputType;

    // The name of the reactive form
    name: string;

    // The name of the html element
    label: string;

	// Angular Material Icon
	icon: string;

    // Reactive form control validators.
    validators: Array<ValidatorFn>;

    // Mark input is required
    required: boolean;

    // Default value of this input
    defaultValue: any;

    // Readonly
    readonly: boolean;

    // Disable
    disabled: boolean;

    // Hint
    hint: string;

    // Input Span Columns
    cols: CfgFormColumns;

    // Disable auto scale width
    disableAutoScale: boolean;

    // Custom Error Messages
    errorMessages: FormErrorMessageRules | null;

	// Break Line After This Input
	breakLine: boolean;

    // autocompolete on / off control
    autoCompleteOff: boolean;

    optionChanged: Subject<any> = new Subject();
    optionChanged$: Observable<any> = this.optionChanged.asObservable();

    constructor(type: FormInputType, config: {} & BASE_OPTIONS)
    {
        this.type = type;
        this.name = config.name;
		this.icon = config.icon || '';
        this.label = config.label;
        this.validators = config.validators || [];
        this.required = this.validators.indexOf( Validators.required ) >= 0;
        this.defaultValue = config.defaultValue !== undefined ? config.defaultValue : null;
        this.disabled = config.disabled || false;
        this.readonly = config.readonly || false;
        this.hint = config.hint || '';
        this.cols = config.cols || 1;
        this.disableAutoScale = config.disableAutoScale || false;
        this.errorMessages = config.errorMessages || null;
		this.breakLine = config.breakLine || false;
        this.autoCompleteOff = config.autoCompleteOff || false;
    }

}

// Text input
export class TextInput extends BaseInput
{

    htmlInputType?: HTMLInputType;

    placeholder?: string;

    datalist?: string[];

    constructor(config: { htmlInputType?: HTMLInputType, placeholder?: string, datalist?: string[] } & BASE_OPTIONS)
    {
        super('text', config);

        this.htmlInputType = config.htmlInputType || 'text';

        this.placeholder = config.placeholder || '';

        this.datalist = config.datalist || undefined;
    }
}

// Password input
export class PasswordInput extends BaseInput
{
    constructor(config: {} & BASE_OPTIONS)
    {
        super('password', config);
    }
}

// Textarea
export class TextareaInput extends BaseInput
{
    constructor(config: {} & BASE_OPTIONS)
    {
        super('textarea', config);
    }
}

// Number input
export class NumberInput extends BaseInput
{

    min?: number;

    max?: number;

    placeholder?: string;

    step?: number = 1;

    constructor(config: {
        min?: number,
        max?: number,
        placeholder?: string,
        step?: number,
    } & BASE_OPTIONS)
    {
        super('number', config);

        this.min = config.min;
        this.max = config.max;
        this.placeholder = config.placeholder;
        this.step = config.step || 1;
    }
}

// Dropdown input
export class SelectInput extends BaseInput
{

    // Disable reset
    disableReset?: boolean;

    // Selectable  options
    options?: Array<FormSelectOption>;

    // select multiple option
    multiple?: boolean;

    // Enable search option
    enableSearchOption?: boolean;

    constructor(config: {
        options: Array<FormSelectOption>,
        multiple?: boolean,
        disableReset?: boolean,
        enableSearchOption?: boolean,
    } & BASE_OPTIONS)
    {
        super('select', config);

        this.options = config.options;
        this.multiple = config.multiple || false;
        this.disableReset = config.disableReset || false;
        this.enableSearchOption = config.enableSearchOption || false;
    }

}

// Checkbox Input
export class CheckboxInput extends BaseInput
{

    // Selectable  options
    options?: Array<FormSelectOption>;

    // style min width
    minWidth?: string;

    constructor(config: {
        options: Array<FormSelectOption>,
        minWidth?: string,
    } & BASE_OPTIONS)
    {
        super('checkbox', config);

        this.options = config.options;
        this.minWidth = config.minWidth;
    }

}

// Radio Input
export class RadioInput extends BaseInput
{

    // Selectable  options
    options?: Array<FormSelectOption>;

    // style min width
    minWidth?: string;

    constructor(config: {
        options: Array<FormSelectOption>,
        minWidth?: string;
    } & BASE_OPTIONS)
    {
        super('radio', config);

        this.options = config.options;
        this.minWidth = config.minWidth;
    }

}

// Date Input
export class DateInput extends BaseInput
{

    // After this date can be selectable
    minDate?: Date;

    // Before this date can be selectable
    maxDate?: Date;

    constructor(config: {
        min?: Date,
        max?: Date,
    } & BASE_OPTIONS)
    {
        super('date', config);

        this.minDate = config.min;
        this.maxDate = config.max;
    }

}

// Form Column Options
export type CfgFormColumns = 1 | 2| 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
export type CfgFormResponsiveOptions = {
	sm?: 		CfgFormColumns, // sm - 640 px

	md?: 		CfgFormColumns, // md - 768 px

	lg?: 		CfgFormColumns, // lg - 1024 px

	xl?: 		CfgFormColumns, // xl - 1280 px

	'2xl'?: 	CfgFormColumns, // 2xl - 1536 px
};

// Time input available hour options.
export type TimeInputHourOptions = 0  | 1  | 2  | 3  | 4  | 5  | 6  | 7  | 8  | 9
                                 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19
                                 | 20 | 21 | 22 | 23 ;

// Time input available minute options.
export type TimeInputMinuteOptions = 0  | 1  | 2  | 3  | 4  | 5  | 6  | 7  | 8  | 9
                                   | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19
                                   | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28 | 29
                                   | 30 | 31 | 32 | 33 | 34 | 35 | 36 | 37 | 38 | 39
                                   | 40 | 41 | 42 | 43 | 44 | 45 | 46 | 47 | 48 | 49
                                   | 50 | 51 | 52 | 53 | 54 | 55 | 56 | 57 | 58 | 59 ;

// Time input
export class TimeInput extends BaseInput
{

    minTime?: { hour: TimeInputHourOptions, minute: TimeInputMinuteOptions };

    maxTime?: { hour: TimeInputHourOptions, minute: TimeInputMinuteOptions };

    hourOptions?: Array<FormSelectOption> = [];

    minuteOptions?: Array<FormSelectOption> = [];

    constructor(config: {
        min?: { hour: TimeInputHourOptions, minute: TimeInputMinuteOptions },
        max?: { hour: TimeInputHourOptions, minute: TimeInputMinuteOptions },
    } & BASE_OPTIONS)
    {
        super('time', config);

        if( config.defaultValue && typeof config.defaultValue === 'string' ) {
            const [hour, minute] = config.defaultValue.split(':');
            this.defaultValue = {
                hour: parseInt(hour),
                minute: parseInt(minute),
            };
        }

        this.minTime = config.min;
        this.maxTime = config.max;

        // Gen options
        if( this.hourOptions && this.minuteOptions )
        {
            for( let i = 0 ; i < 24 ; i++ ) this.hourOptions.push({ value: i, label: (''+i).padStart(2, '0') });
            for( let i = 0 ; i < 60 ; i++ ) this.minuteOptions.push({ value: i, label: (''+i).padStart(2, '0') });
        }
    }

}

// File input
export class FileInput extends BaseInput
{

    // select multiple option
    multiple?: boolean;

    // Accept file filter
    accept?: string;

    constructor(config: {
        multiple?: boolean,
        accept?: string,
    } & BASE_OPTIONS)
    {
        super('file', config);

        this.multiple = config.multiple || false;
        this.accept = config.accept;
    }

}

// Input Array
export class InputArray extends BaseInput {

    inputs?: Array<FormInput>;

    wrapper_class?: any;

    constructor(config: {
        inputs: Array<FormInput>,
        wrapper_class?: any,
    } & BASE_OPTIONS)
    {
        super('array', config);

        this.inputs = config.inputs;
        this.wrapper_class = config.wrapper_class || {};
    }
}

// Input by custom templateRef
export class CustomInput extends BaseInput
{
    constructor(config: {} & BASE_OPTIONS)
    {
        super('custom', config);
    }
}

/**
 * Error messages
 */
export interface FormErrorMessageRules
{
    [key: string]: (e: any) => { label: string, variables: any };
}
export const DEFAULT_FORM_ERROR_MESSAGES: FormErrorMessageRules =
{
    required: 			() 	=> ({ label: 'errors.required_field', 	variables: {} }),
    max: 				(e) => ({ label: 'errors.max_value', 		variables: e  }),
    min: 				(e) => ({ label: 'errors.min_value', 		variables: e  }),
    maxlength: 			(e) => ({ label: 'errors.max_length', 		variables: e  }),
    minlength: 			(e) => ({ label: 'errors.min_length', 		variables: e  }),
    email: 				() 	=> ({ label: `errors.email_format`, 	variables: {} }),
    pattern: 			()	=> ({ label: `errors.format`, 			variables: {} }),
    passwordValidator: 	() 	=> ({ label: `errors.password_format`, 	variables: {} }),
    jsonValidator: 		() 	=> ({ label: `errors.json_format`, 		variables: {} }),
}
export const FORM_ERROR_MESSAGES = new InjectionToken<FormErrorMessageRules>('form-error-messages-token', {
    factory: () => DEFAULT_FORM_ERROR_MESSAGES
});
