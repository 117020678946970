<div mat-dialog-title>{{ data.title | translate }}</div>
<div mat-dialog-content style="padding-bottom: 2rem;">
    <span [innerHTML]="data.message"></span>
    <mat-form-field class="w-100">
        <mat-label>{{ data.input.label | translate }}</mat-label>
        <input matInput [placeholder]="data.input.placeholder" [value]="data.input.default" [required]="data.input.require" #input>
        <mat-hint *ngIf="data.input.hint">{{ data.input.hint | translate }}</mat-hint>
    </mat-form-field>
</div>
<div mat-dialog-actions [align]="data.action_align">
    <button mat-stroked-button mat-dialog-close color="{{ data.close_btn_color }}" class="mr-1">{{ data.close_btn_text | translate }}</button>
    <button mat-stroked-button [mat-dialog-close]="input.value" color="{{ data.confirm_btn_color }}">{{ data.confirm_btn_text | translate }}</button>
</div>