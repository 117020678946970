import { UntypedFormControl } from '@angular/forms';
import { DataTable, DataTableColumn } from './../data-table/models';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'data-table-column-list',
    templateUrl: './data-table-column-list.component.html',
    styleUrls: ['./data-table-column-list.component.scss'],
    providers: [ TranslatePipe ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataTableColumnListComponent implements OnInit {

    @Input() config: DataTable<any> = new DataTable<any>({});
    @Output() configChange: EventEmitter<DataTable<any>> = new EventEmitter();

    form: UntypedFormControl = new UntypedFormControl([]);

    subscriptions: Array<Subscription> = [];

    constructor(private ChangeDetectorRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.form.patchValue(this.config.displayCols);
        this.subscriptions.push(
            this.form.valueChanges.subscribe(v => {
                if( this.config ) {
                    const c = this.config;
                    c.displayCols = v;
                    this.configChange.emit( c );
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnChanges(changes: { config?: DataTable<any> }) {
        if( changes.config ) {
            if( !this.ArrayEqual(this.form.value, changes.config.displayCols)  ) {
                this.form.patchValue( changes.config.displayCols );
            }
        }
    }

    ArrayEqual(a: Array<any>, b: Array<any>): boolean {
        if (a?.length != b?.length) return false;
        for (let i = 0; i < a.length; i++) {
            if (a[i] instanceof Array && b[i] instanceof Array) {
                if ( !this.ArrayEqual(a[i], b[i]) ){
                    return false;
                }
            } else if ( a[i] != b[i] ) {
                return false;
            }
        }
        return true;
    }

    Checked(v: any): boolean {
        return this.config.displayCols.indexOf( v ) >= 0;
    }

    handleChecked(e: MatCheckboxChange) {
        let arr: Array<string> = this.form.value;
        if( e.checked ) {
            if( arr.indexOf(e.source.value) < 0 ) {
                arr.push( e.source.value );
            }
        } else {
            if( arr.indexOf(e.source.value) >= 0 ) {
                arr.splice( arr.indexOf(e.source.value), 1);
            }
        }
        this.form.patchValue( arr );
    }

    drop(event: CdkDragDrop<{title: string, poster: string}[]>) {
        const config = this.config
        moveItemInArray(config.columns, event.previousIndex, event.currentIndex);
        config.columns = config.columns;
        this.configChange.emit(config);
    }

    identify(index: number, item: DataTableColumn<any>){
        return item.name;
    }

}

