import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpErrorResponse, HttpHeaders, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, timeout } from 'rxjs/operators';
import { AlertService } from 'app/shared/angular-material/snake-bar-module/services/alert.service';
import { AuthService } from './auth.service';

const DEFAULT_TIMEOUT = 5 * 60 * 1000;

@Injectable({ providedIn: 'root' })
export class ApiService {

    constructor(
        private http: HttpClient,
        public router: Router,
        public alertService: AlertService,
        public authService: AuthService,
    ) { }

    get(path: string, params: any = {}): Observable<any> {
        return this.http.get(path, { params }).pipe( catchError(this.handle401And403.bind(this)), catchError(this.formatError), timeout(DEFAULT_TIMEOUT) );
    }

    put(path: string, body: object = {}): Observable<any> {
        return this.http.put(path, JSON.stringify(body)).pipe( catchError(this.handle401And403.bind(this)), catchError(this.formatError), timeout(DEFAULT_TIMEOUT) );
    }

    post(path: string, body: object = {}, options?: any): Observable<any> {
        return this.http.post(path, JSON.stringify(body), options).pipe( catchError(this.handle401And403.bind(this)), catchError(this.formatError), timeout(DEFAULT_TIMEOUT) );
    }

    postFormData(path: string, fd: FormData, options?: any): Observable<any> {
        return this.http.post(path, fd, options).pipe( catchError(this.handle401And403.bind(this)), catchError(this.formatError), timeout(DEFAULT_TIMEOUT) );
    }

    putFormData(path: string, fd: FormData, options?: any): Observable<any> {
        return this.http.put(path, fd, options).pipe( catchError(this.handle401And403.bind(this)), catchError(this.formatError), timeout(DEFAULT_TIMEOUT) );
    }

    patch(path: string, body: object = {}): Observable<any> {
        return this.http.patch(path, JSON.stringify(body)).pipe( catchError(this.handle401And403.bind(this)), catchError(this.formatError), timeout(DEFAULT_TIMEOUT) );
    }

    delete(path, params: any = {}): Observable<any> {
        return this.http.delete(path, { params: params }).pipe( catchError(this.handle401And403.bind(this)), catchError(this.formatError), timeout(DEFAULT_TIMEOUT) );
    }

    deleteWithBody(path, body: any = {}): Observable<any> {
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: body,
        };
        return this.http.delete(path, options).pipe( catchError(this.handle401And403.bind(this)), catchError(this.formatError), timeout(DEFAULT_TIMEOUT) );
    }

    private formatError(err: HttpErrorResponse) {
        return throwError(err.error);
    }

    private handle401And403(error: HttpErrorResponse) {
        if ( error.status === 401 ) {
            const reg = new RegExp("\/api\/ipvboxes\/")
            if ( reg.test(error.url) ) {
                
            } else {
                // this.alertService.error(error.message);
                this.authService.setUser(null);
                this.router.navigate(['/login']);
            }
        }

        if ( error.status === 403 && error.error.code === 'AuthError' ) {
            // this.alertService.error(error.message);
            // this.router.navigate(['/logout']);
        }

        return throwError(error);
    }
}
