import { NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeComponent } from './date-range.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_DATE_FORMATS } from 'app/shared/angular-material/configs';
import { AccountSelectorComponent } from './account-selector.component';
import { CustomValidatorsModule } from 'app/shared/validators';
import { WorkTypeSelectorComponent } from './work-type-selector.component';
import { AngularMaterialModule } from 'app/shared/angular-material/angular-material.module';
import { WebtagSelectorComponent } from './webtag-selector.component';
import { FormWorkDetailComponent } from './form-work-detail.component';
import { DataTableModule } from 'app/shared/data-table';
import { LeftRightSelectorModule } from './left-right-selector/left-right-selector.module';

/* Swiper */
import { SiteSelectorModule } from './site-selector/site-selector.module';
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Navigation, Pagination, A11y, Zoom } from 'swiper/core';
import { MatBadgeModule } from '@angular/material/badge';
SwiperCore.use([ Navigation, Pagination, A11y, Zoom ]);

const components = [
    DateRangeComponent,
    AccountSelectorComponent,
    WorkTypeSelectorComponent,
    WebtagSelectorComponent,
    FormWorkDetailComponent,
];
const modules = [
    TranslateModule,
    AngularMaterialModule,
    CustomValidatorsModule,
    DataTableModule,
    SwiperModule,
    MatBadgeModule,
];
const exp_modules = [SiteSelectorModule, LeftRightSelectorModule];
const providers: Array<Provider> = [
    { provide: MAT_DATE_LOCALE, useValue: 'zh-TW' },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true} },
    {
        provide: MAT_DATE_FORMATS,
        useValue: MY_DATE_FORMATS,
    },
];

@NgModule({
    declarations: [...components],
    imports: [CommonModule, ReactiveFormsModule, ...modules],
    exports: [...components, ...exp_modules],
    providers: [...providers]
})
export class FormUiComponentsModule { }
