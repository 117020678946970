import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CustomValidatorsModule } from 'app/shared/validators';

/* Material Modles */
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { TranslateModule } from '@ngx-translate/core';

/* Components */
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { InputDialogComponent } from './components/input-dialog.component';
import { FormDialogComponent } from './components/form-dialog.component';
import { DialogLoadingComponent } from './components/dialog-loading.component';
import { CfgFormDialogComponent } from './components/cfg-form-dialog.component';
import { CfgFormModule } from 'app/shared/cfg-form';

const components = [ ConfirmDialogComponent, InputDialogComponent, FormDialogComponent, DialogLoadingComponent, CfgFormDialogComponent ];
const entry_components = [ ConfirmDialogComponent, InputDialogComponent, FormDialogComponent, DialogLoadingComponent, CfgFormDialogComponent ];
const inside_modules = [ 
    MatIconModule, MatDialogModule, MatButtonModule, 
    MatInputModule, MatFormFieldModule, MatCheckboxModule, 
    MatRadioModule, MatSelectModule, MatDividerModule, 
    MatProgressBarModule, TranslateModule, MatSlideToggleModule, 
];
const modules = [ CommonModule, ReactiveFormsModule, CustomValidatorsModule, TranslateModule, CfgFormModule ];

@NgModule({
    declarations: [...components],
    imports: [...modules, ...inside_modules],
    providers: [
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                // maxHeight: '90vh',
                // maxWidth: '80%',
                hasBackdrop: true,
                autoFocus: true,
            },
        },
    ],
    exports: [...components]
})
export class DialogModule { }
