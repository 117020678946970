import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

/* Modules */
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { PersonalModule } from './pages/personal/personal.module';
import { LoginModule } from './pages/login/login.module';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts/core';

/* Services */
import { LanguageService } from './core/i18n/language.service';

/* Components */
import { AppComponent } from './app.component';

/* Ngx Translate */
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CachedHttpTranslationLoader } from './core/i18n/ngx-translate-loader';
import { environment } from '../environments/environment';
export function createTranslateLoader(http: HttpClient, langService: LanguageService) {
    return new CachedHttpTranslationLoader(langService, http, '/api/auth/site/language/', '');
}

/* Service Worker */
import { ServiceWorkerModule } from '@angular/service-worker';
@NgModule({
    declarations: [ AppComponent ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        CoreModule,
        SharedModule,
        HttpClientModule,
        /* Modules */
        LoginModule,
        // ChartsModule,
        TranslateModule.forRoot({
            defaultLanguage: 'traditional_chinese',
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient, LanguageService]
            }
        }),
        NgxEchartsModule.forRoot({ echarts }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
        //   registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {}
