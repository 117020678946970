<div mat-dialog-title *ngIf="data.title">
    <mat-icon *ngIf="data.icon">{{ data.icon }}</mat-icon>
    {{ data.title | translate }}
</div>
<mat-divider></mat-divider>
<mat-dialog-content style="padding-top: 1.5rem;padding-bottom: 1.5rem;">
    <p *ngIf="data.message">{{ data.message | translate }}</p>
    <div class="{{ getGridClass() }} gap-4">
        <ng-container *ngFor="let i of data.inputs">
            <div class="form-content {{ getColumnClass(i) }}">
                <div class="title" [attr.required]="isRequired(i) ? '' : null">{{ i.label | translate }}</div>
                <div class="input"> <ng-container [ngTemplateOutlet]="InpnutTemplate" [ngTemplateOutletContext]="{input: i}"></ng-container> </div>
            </div>
        </ng-container>
    </div>
</mat-dialog-content>
<mat-divider></mat-divider>
<mat-dialog-actions align="end" style="padding-top: 1.15em;padding-bottom: 1.15em;">
    <button mat-stroked-button mat-dialog-close color="{{ data.close_btn_color }}" class="mr-1">{{ data.close_btn_text | translate }}</button>
    <button mat-stroked-button (click)="close()" [disabled]="form.invalid" color="{{ data.confirm_btn_color }}">{{ data.confirm_btn_text | translate }}</button>
</mat-dialog-actions>
<div *ngIf="loading" style="position: absolute;width: calc(100% + 48px);height: 100%;top: 0;left: -24px;background-color: rgba(255, 255, 255, 0.7);font-weight: 700;font-size: large;">
    <app-dialog-loading></app-dialog-loading>
</div>

<ng-template #InpnutTemplate let-i="input">
    <!-- Form Field Common Types -->
    <ng-container *ngIf="['radio','checkbox', 'file', 'switch'].indexOf(i.type) == -1 else otherInputs">
        <mat-form-field [floatLabel]="form.get(i.name).value ? 'always' : 'auto'">
            <mat-label></mat-label>
            
            <!-- Input Control -->
            <ng-container [ngSwitch]="i.type">

                <!-- Text Input -->
                <input [name]="i.name" *ngSwitchCase="'text'" matInput type="text" [formControl]="form.get(i.name)">

                <!-- Number Input -->
                <input [name]="i.name" *ngSwitchCase="'number'" matInput type="number" [formControl]="form.get(i.name)">

                <!-- Date Input (Native) -->
                <input [name]="i.name" *ngSwitchCase="'date'" matInput type="date" [formControl]="form.get(i.name)">

                <!-- Select Input -->
                <ng-container *ngSwitchCase="'select'">
                    <mat-select [formControl]="form.get(i.name)" [placeholder]="form.get(i.name).value" [multiple]="i.multiple">
                        <mat-select-trigger>
                            {{ getSelectedLabel(i, form.get(i.name).value) }}
                        </mat-select-trigger>
                        <mat-option disabled>
                            <input #filter_input matInput placeholder="{{ 'common.filter' | translate }}..." autocomplete="off">
                        </mat-option>
                        <mat-divider></mat-divider>
                        <mat-option *ngIf="!isRequired(i)" [value]="null"></mat-option>
                        <ng-container *ngIf="i.options as options">
                            <ng-container *ngIf="filterOptions(filter_input.value, i) as filted_options">
                                <mat-option *ngIf="filted_options.length == 0" disabled>{{ 'common.form.no_option_found' | translate }}</mat-option>
                                <mat-option *ngFor="let opt of options" [value]="opt.value" [disabled]="opt.disabled" [ngStyle]="{'display': filted_options.indexOf(opt) >= 0 ? '' : 'none'}">{{ opt.label | translate }}</mat-option>
                            </ng-container>
                        </ng-container>
                    </mat-select>
                </ng-container>

                <!-- Textarea -->
                <ng-container *ngSwitchCase="'textarea'">
                    <textarea   matInput
                                [name]="i.name"
                                [formControl]="form.get(i.name)"
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="3"
                                cdkAutosizeMaxRows="10"></textarea>
                </ng-container>
            </ng-container>
            
            <mat-hint *ngIf="i.hint">{{ i.hint | translate }}</mat-hint>
            <mat-error *ngIf="form.get(i.name).invalid">
                <error-messages [control]="form.get(i.name)"></error-messages>
            </mat-error>

        </mat-form-field>
    </ng-container>
    <ng-template #otherInputs>
        <ng-container [ngSwitch]="i.type">
            <div *ngSwitchCase="'radio'" style="margin-top: 3px;">
                <mat-error *ngIf="form.get(i.name).invalid">
                    <error-messages [control]="form.get(i.name)"></error-messages>
                </mat-error>
                <mat-radio-group [formControl]="form.get(i.name)" class="grid-items" [ngStyle]="getGridStyle(i)">
                    <mat-radio-button *ngFor="let opt of i.options" [value]="opt.value" color="primary">{{ opt.label | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngSwitchCase="'checkbox'">
                <mat-error *ngIf="form.get(i.name).invalid">
                    <error-messages [control]="form.get(i.name)"></error-messages>
                </mat-error>
                <div class="grid-items" [ngStyle]="getGridStyle(i)">
                    <mat-checkbox *ngFor="let opt of i.options" [value]="opt.value" color="primary" (change)="handleCheckboxChange(i, $event)" [checked]="isChecked(i, opt)">{{ opt.label | translate }}</mat-checkbox>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'switch'">
                <div style="margin-top: 1rem;">
                    <mat-slide-toggle [formControl]="form.get(i.name)" [name]="i.name"></mat-slide-toggle>
                </div>
            </ng-container>
            <ng-container *ngSwitchCase="'file'">
                <mat-form-field>
                    <mat-label *ngIf="form.get(i.name).value"></mat-label>
                    <button type="button" mat-icon-button matPrefix (click)="file_input.click()" style="font-size: 18px;margin-right: 8px;">
                        <mat-icon>attach_file</mat-icon>
                    </button>
                    <input matInput [formControl]="form.get(i.name)" placeholder="Please select file" readonly>
                    <input type="file" hidden #file_input style="display: none;" [attr.accept]="i.accept" [multiple]="i.multiple" (change)="handleFileInputChange(i, file_input.files)">
                    <mat-error *ngIf="form.get(i.name).invalid">
                        <error-messages [control]="form.get(i.name)"></error-messages>
                    </mat-error>
                </mat-form-field>
            </ng-container>
        </ng-container>
    </ng-template>
</ng-template>
