import { ErrorStateMatcher } from '@angular/material/core';
import { UntypedFormControl, FormGroupDirective, NgForm, ValidationErrors } from '@angular/forms';
import { Injectable } from '@angular/core';

/* Error when invalid control is dirty, touched, or submitted. */
@Injectable()
export class CustomErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

export class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        const isControlError = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        const isGroupError = !!(form && form.errors && (form.dirty || form.touched || isSubmitted));
        return isControlError || isGroupError;
    }
}

/* 自製FormControl元件的 Error State */
export class BindHostErrorStateMatcher implements ErrorStateMatcher {

    constructor( public errors: () => ValidationErrors | null  ) {}

    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const host_err = this.errors();
        const isSubmitted = form && form.submitted;
        return !!((control && control.invalid || host_err) && (control.dirty || control.touched || isSubmitted));
    }

}