import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
    selector: 'error-messages',
    templateUrl: './error-messages.component.html',
})
export class ErrorMessageComponent {

    private readonly errorMessages = {
        required: () => `This field is required`, // Angular Default Validators
        min: (params) => `This field value must be greater than or equal to ${params.min}`,
        max: (params) => `This field value must be less than or equal to ${params.max}`,
        minlength: (params) => `The min number of characters is ${params.requiredLength}`,
        maxlength: (params) => `The max allowed number of characters is ${params.requiredLength}`,
        email: () => `This is not a valid email format`,
        pattern: () => `Please match the requested format`,
        owlDateTimeParse: () => `Invalid date or time format`, // Plugin Validators
        passwordValidator: () => `Invalid password format`, // Custom Validators
        jsonValidator: () => `Invalid json format`,
        URLValidator: () => `Invalid URL format`,
        equalValidator: () => `This field must match the previous field`, // Cross Field Validators
    };

    @Input() private control: AbstractControl;

    @Input() public other_messages: {[key: string]: (params?: any) => string} = {};

    showError(): boolean {
      return this.control &&
        this.control.errors &&
        (this.control.dirty || this.control.touched);
    }

    getError(): string {
        for ( const field of Object.keys(this.control.errors) ) {
            return this.getMessage(field, this.control.errors[field]);
        }
    }

    private getMessage(type: string, params: any) {
      if (!(type in this.errorMessages)) {
        if (!(type in this.other_messages)) {
          return ''; // 'This field is entered incorrectly';
        }
        return this.other_messages[type](params);
      }
      return this.errorMessages[type](params);
    }
}
