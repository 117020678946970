import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'display-toggle',
    templateUrl: './display-toggle.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DisplayToggleComponent implements OnInit {

    @Input() show: boolean = false;

    @Input() show_icon: string = 'visibility';

    @Input() hidden_icon: string = 'visibility_off';

    constructor() { }

    ngOnInit(): void {}

}
