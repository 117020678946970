import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatCheckboxChange } from '@angular/material/checkbox';
import { FormDialogData, FormDialogInput, FormDialogInputOption } from '../model';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslatePipeProvider } from 'app/core/i18n/ngx-translate-pipe';

@Component({
    selector: "app-form-dialog-component",
    templateUrl: './form-dialog.component.html',
    styleUrls: ['./form-dialog.component.scss'],
    providers: [ TranslatePipeProvider ]
})

export class FormDialogComponent {
    
    form: UntypedFormGroup;
    columns: number = 1;
    loading: boolean = false;
    fileStore: {[key: string]: FileList} = {};
    requiredValidator = Validators.required;

    constructor(
        private i18n: TranslatePipe,
        @Inject(MAT_DIALOG_DATA) public data: FormDialogData<any>,
        private dialogRef: MatDialogRef<FormDialogComponent>
    ){
        if( data.columns > 1 ) { this.columns = data.columns; }
        this.initForm();
    }

    async close() {
        if( this.data.callback ) {
            this.loading = true;
            if( await this.data.callback(this.getFormValue()) ) {
                this.dialogRef.close(true);
            } else {
                this.loading = false;
            }
        } else {
            this.dialogRef.close(this.getFormValue());
        }
    }

    initForm() {
        const form_controls = {};
        this.data.inputs.forEach(i => {
            const fc = new UntypedFormControl(i.defaultValue !== undefined ? i.defaultValue : '', i.validators ? i.validators : []);
            if( i.disabled !== undefined || i.readonly !== undefined ) {
                if( i.disabled !== undefined && i.disabled || i.readonly !== undefined && i.readonly ) {
                    fc.disable();
                }
            }
            form_controls[i.name] = fc;
            if( i.type == 'file' ) {
                this.fileStore[i.name] = null;
            } else if ( i.type == 'switch' ) {
                fc.patchValue(i.defaultValue !== undefined ? !!i.defaultValue : false);
            }
        });

        this.form = new UntypedFormGroup(form_controls)
    }

    getFormValue() {
        const data = this.form.getRawValue();
        this.data.inputs.filter(i => i.type == 'file').forEach(i => {
            data[i.name] = this.fileStore[i.name];
        });
        return data;
    }

    filterOptions(target: string, cfg: FormDialogInput): Array<{ value: any, label: string }> {
        if( Array.isArray( cfg.options ) ) {
            if( target.length ){
                return cfg.options.filter(f => this.i18n.transform(f.label).toLowerCase().includes( target.toLowerCase() ));
            } else {
                return cfg.options;
            }
        } else {
            return [];
        }
    }

    getColumnClass(i: FormDialogInput): string {
        const columns = this.columns
        const column = i.columns ? i.columns : 1;
        
        if( i.type == 'radio' || column > columns ) { return `col-span-${columns}`; }

        return `col-span-${column}`;
    }

    getGridClass() {
        return `grid grid-cols-${this.columns}`;
    }

    getGridStyle(i: FormDialogInput): {[key: string]: any} {
        return {
            "grid-template-columns": `repeat(${i.columns ? i.columns : 1}, 1fr)`
            // "--columns": i.columns ? i.columns : 1
        };
    }

    handleFileInputChange(i: FormDialogInput, files: FileList): void {
        if( files instanceof FileList && files.length ) {
            this.form.get(i.name).patchValue(`${files[0].name}` + (files.length > 1 ? `(+ ${files.length - 1} files)` : ""));
        } else if ( files instanceof File && files ) {
            this.form.get(i.name).patchValue(files.name);
        } else {
            this.form.get(i.name).patchValue("");
        }
        this.fileStore[i.name] = files;
    }

    isRequired(i: FormDialogInput): boolean {
        return i.validators? i.validators.indexOf(this.requiredValidator) >= 0 : false;
    }

    isChecked(i: FormDialogInput, option: FormDialogInputOption): boolean {
        const control = this.form.get(i.name);
        if( control && Array.isArray(control.value) ) {
            if( control.value.indexOf(option.value) >= 0 ) {
                return true;
            }
        }

        return false;
    }
    
    handleCheckboxChange(i: FormDialogInput, e: MatCheckboxChange): void {
        const control = this.form.get(i.name);
        control.markAsTouched();
        control.markAsDirty();
        if( control ) {
            if( e.checked ) {
                if( Array.isArray(control.value) && control.value.indexOf(e.source.value) >= 0 ){
                    return;
                }
                if( Array.isArray(control.value) ) {
                    control.patchValue( control.value.concat(e.source.value) );
                } else {
                    control.patchValue([e.source.value]);
                }
            } else {
                if( Array.isArray(control.value) ) {
                    control.patchValue( control.value.filter(v => v != e.source.value) );
                }
            }
        }
    }

    getSelectedLabel(i: FormDialogInput, value: any ): string {
        if(     value === null 
            ||  value === undefined 
            || (Array.isArray(value) && value.length == 0) ) { return ""; }

        if( Array.isArray(value) ) {
            const opt = i.options.find(opt => opt.value == value);
            return this.i18n.transform(opt ? opt.label : value[0] as string) + `(+ ${value.length - 1})`;
        } else {
            const opt = i.options.find(opt => opt.value == value);
            return this.i18n.transform(opt ? opt.label : value);
        }
    }

}