import { BehaviorSubject, Observable, Subject } from "rxjs";
import { PagingEvent } from "./data-table-wrapper/models";

export type SortFn<T> = (a: T, b: T) => number;


// DataStore - DataStore class for data table, support pagination, sorting, filtering
export class DataStore<T> {

    _data: T[] = [];
    get data() { return this._data; }
    set data(d: T[]) {
        this._data = d;
        this.handlePageChange();
    }

    private _total: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    total: Observable<number> = this._total.asObservable();

    _rows: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
    rows: Observable<T[]> = this._rows.asObservable();

    custom_filters: { [key: string]: (filterV: any) => (row: T, idx: number) => boolean } = {};
    sort_fns: { [key: string]: SortFn<T> } = {};

    filtedData: T[] = [];
    filtedData$: Subject<T[]> = new Subject<T[]>();

    _lastPagingEvent: PagingEvent<T>;

    constructor(
        custom_filters?: { [key: string]: (filterV: any) => (row: T, idx: number) => boolean },
        sort_fns?: { [key: string]: SortFn<T> },
    ) {
        this.custom_filters = custom_filters ?? {};
        this.sort_fns = sort_fns ?? {};
    }
    handlePageChange(p?: PagingEvent<any>) {
        if (!p) p = this._lastPagingEvent;
        else this._lastPagingEvent = p;

        if (!p) return;

        let tmp = this.data.slice();

        if (this.data.length) {
            if (p && p.filters) {
                for(let k in p.filters) {
                    if (k == 'sort') {
                        const sort = p.filters['sort'];
                        // prefix is '-' mean DESC
                        const order = sort[0] == '-' ? 'DESC' : 'ASC';
                        const prop = sort[0] == '-' ? sort.substr(1) : sort;
                        if (this.sort_fns[prop]) {
                            const fn = this.sort_fns[prop];
                            tmp = tmp.sort((a,b) => fn(a,b) * (order == 'ASC' ? 1 : -1));
                        } else {
                            const prop = sort[0] == '-' ? sort.substr(1) : sort;
                            tmp = tmp.sort(SortBy(prop, order));
                        }
                    } else if (this.data[0][k] !== undefined) {
                        const v = p.filters[k];
                        if( v !== undefined ) {
                            const filterFn = this.custom_filters[k] || ((v) => ((r: any, idx: number) => r[k].toString().includes(v)));
                            tmp = tmp.filter(filterFn(v));
                        }
                    } else {
                        const v = p.filters[k];
                        const filterFn = this.custom_filters[k];
                        if (v !== undefined && filterFn) {
                            tmp = tmp.filter(filterFn(v));
                        }
                    }
                }
            }
        }

        this.filtedData = tmp;
        this.filtedData$.next(tmp);
        this._rows.next(tmp.slice(p.offset, p.offset + p.limit));
        this._total.next(tmp.length);
    };

}

/**
 * SortBy - Return functions that can be used in array sort
 */
export function SortBy( col: string, order: 'ASC' | 'DESC' = 'ASC' ) {
    return (a: any,b: any) => {
        if( a[col] == b[col] ) return 0;

        if( order == 'ASC' ) {
            return a[col] > b[col] ? 1 : -1;
        } else {
            return a[col] > b[col] ? -1 : 1;
        }
    }
}

// Function Debounce
export function debounce (func: Function, delay: number) {

    var timer = null;

    return function () {
        var context = this;
        var args = arguments;

        if( timer ) clearTimeout(timer);

        timer = setTimeout(() => func.apply(context, args), delay);
    }
}

// Function Throttle
export function throttle(func: Function, threshhold: number) {

    var last, timer;

    if( threshhold <= 0 ) threshhold = 250;

    return function () {
        var context = this;
        var args = arguments;
        var now = +new Date()

        if (last && now < last + threshhold) {
            if( timer ) clearTimeout(timer);

            timer = setTimeout(() => {
                last = now
                func.apply(context, args)
            }, threshhold);
        } else {
            if( timer ) clearTimeout(timer);

            last = now;
            func.apply(context, args);
        }
    }
}
