import { UntypedFormControl } from '@angular/forms';
import { CfgFormFieldDefDirective } from '../directives/cfg-form-input-template.directive';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, HostBinding, HostListener, Inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { CfgForm, CfgFormColumns, FormInput, FormInputStyles, FORM_DEFAULT_STYLE } from '../cfg-form.type';
import { QueryList } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    selector: 'cfg-form, [cfg-form]',
    templateUrl: './cfg-form.component.html',
    styleUrls: ['./cfg-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CfgFormComponent implements OnInit, OnDestroy {

    @Input('CfgForm') CfgForm!: CfgForm;

    @ContentChildren(CfgFormFieldDefDirective) fieldDefs!: QueryList<CfgFormFieldDefDirective>;

    @HostBinding('style') get getStyle() {
        return {
            '--cfg-form-cols': this.total_cols,
        }
    }

    @HostBinding('class') get getClassName() {
        const vars: any = {
            'cfg-form': true,
            'mobile-style': this.CfgForm.style === 'mobile',
        };
        return Object.keys(vars).map( key => vars[key] ? key : '' ).filter( cls => !!cls ).join(' ');
    }

    private subscriptions: Subscription[] = [];

	total_cols: CfgFormColumns = 1;
	columnsMap: {[key:string]: CfgFormColumns} = {};

    constructor(
        @Inject(FORM_DEFAULT_STYLE) private defaultStyle: FormInputStyles,
        private changeDet: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        if( this.CfgForm && !this.CfgForm.style )
        {
            this.CfgForm.style = this.defaultStyle;
        }
        this.subscriptions.push(
			this.CfgForm.form.statusChanges.subscribe(() => this.changeDet.markForCheck()),
            this.CfgForm.form.valueChanges.subscribe(() => this.changeDet.markForCheck())
        );
		this.calculateInputColumns();
    }

    ngOnDestroy()
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    // Get field def
    getFieldDef(field: string): CfgFormFieldDefDirective | null
    {
        if( !(this.fieldDefs instanceof QueryList) ){ return null;}
        return this.fieldDefs.find( c => c.name === field ) || null;
    }

    // Get inpu template by field name
    getInputTemplate(field: string): TemplateRef<any> | null
    {
        const def = this.getFieldDef(field);
        if ( !def || !def.inputDef ) return null;

        return def.inputDef.template;
    }

    // Get inpu template by field name
    getHintTemplate(field: string): TemplateRef<any> | null
    {
        const def = this.getFieldDef(field);
        if ( !def || !def.hintLabelDef ) return null;

        return def.hintLabelDef.template;
    }

    // Get inpu template by field name
    getLabelTemplate(field: string): TemplateRef<any> | null
    {
        const def = this.getFieldDef(field);
        if ( !def || !def.labelDef ) return null;

        return def.labelDef.template;
    }

    // Get inpu template by field name
    getErrorLabelTemplate(field: string): TemplateRef<any> | null
    {
        const def = this.getFieldDef(field);
        if ( !def || !def.errLabelDef ) return null;

        return def.errLabelDef.template;
    }

    getFormControl(i: FormInput): UntypedFormControl | null
    {
        const input = this.CfgForm.form.get( i.name );

        if( input ) return input as UntypedFormControl;

        return null;
    }

    getCol(i: FormInput): CfgFormColumns
    {
        if( this.CfgForm.style === 'mobile' ) { return 12; } // force full width

        return Math.ceil(Math.min(this.total_cols, i.cols) / this.total_cols * 12) as CfgFormColumns;
        // if( this.CfgForm.style === 'mobile' ) { return this.total_cols; } // force full width

        // return i.cols;
    }

	// Get input before area template by field name
    getBeforeAreaTemplate(field: string): TemplateRef<any> | null
    {
        const def = this.getFieldDef(field);
        if ( !def || !def.beforeAreaDef ) return null;

        return def.beforeAreaDef.template;
    }

	// Get input after area template by field name
    getAfterAreaTemplate(field: string): TemplateRef<any> | null
    {
        const def = this.getFieldDef(field);
        if ( !def || !def.afterAreaDef ) return null;

        return def.afterAreaDef.template;
    }

	// Get break line template by field name
    getBreakLineTemplate(field: string): TemplateRef<any> | null
    {
        const def = this.getFieldDef(field);
        if ( !def || !def.breakLineDef ) return null;

        return def.breakLineDef.template;
    }

	@HostListener('window:resize')
	calculateInputColumns()
	{
		const break_points = {
			'2xl': 	1536 ,
			'xl': 	1280 ,
			'lg': 	1024 ,
			'md': 	768	 ,
			'sm': 	640	 ,
		};
		let col = this.CfgForm.cols;
		for(let key of Object.keys(break_points))
		{
			if( window.innerWidth >= break_points[key] && this.CfgForm.responsive && this.CfgForm.responsive[key] )
			{
				col = this.CfgForm.responsive[key];
				break;
			}
		}
		this.total_cols = col;
		this.CfgForm.inputs.forEach(input => {
			this.columnsMap[input.name] = this.getCol( input );
		});
	}

}
