<div class="flex items-center py-2 px-4 bg-gray-200">
    <!-- <mat-icon *ngIf="data.icon" class="mr-4">{{ data.icon }}</mat-icon> -->
    <span class="text-black text-xl font-semibold">{{ data.title | translate }}</span>
    <div class="flex-auto"></div>
    <button mat-dialog-close mat-icon-button tabindex="-1"> <mat-icon>close</mat-icon> </button>
</div>
<mat-divider style="margin: 0 -24px;"></mat-divider>
<mat-dialog-content style="padding: 0px">
    <div class="px-4 py-2 grid gap-4 grid-cols-{{columns}}">
        <div *ngFor="let i of form.inputs" [cfg-input]="form" [name]="i.name" class="w-full p-2"></div>
    </div>
</mat-dialog-content>

<div class="px-4 py-2 bg-gray-100 flex gap-4">
    <button mat-dialog-close class="flex-auto btn bg-gray-50 hover:bg-white border">{{ data.close_btn_text | translate }}</button>
    <button (click)="close()" class="flex-auto btn btn-primary" [disabled]="form.form.invalid">{{ data.confirm_btn_text | translate }}</button>
</div>
<!--
<mat-dialog-actions align="center" class="bg-gray-200">
    <button mat-stroked-button mat-dialog-close color="{{ data.close_btn_color }}" class="mr-1">{{ data.close_btn_text | translate }}</button>
    <button mat-stroked-button (click)="close()" [disabled]="form.form.invalid" color="{{ data.confirm_btn_color }}">{{ data.confirm_btn_text | translate }}</button>
</mat-dialog-actions>
-->
<div *ngIf="loading" style="position: absolute;width: calc(100% + 48px);height: 100%;top: 0;left: -24px;background-color: rgba(255, 255, 255, 0.7);font-weight: 700;font-size: large;">
    <app-dialog-loading></app-dialog-loading>
</div>