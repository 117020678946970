import { Webtag } from './../../pages/admin/webtags/models';
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of, Observable, Subscription, merge } from 'rxjs';
import { BackendHost, BackendPath } from 'app/configs/backend';
import { tap, map, catchError, debounceTime } from 'rxjs/operators';
import { Account } from 'app/pages/login/models';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { SiteSelectorService } from './site.service';

const api = `${BackendHost}${BackendPath}`;
const LocalStorageLanguageKey = "USER-LANG"

export enum AccountRoles {
    SystemAdmin = 0,
    ProductAdmin = 1,
    PlatformAdmin = 2,
    WebtagAdmin = 3,
    NormalUser = 4,
}

export interface LoginUser {
    account_id: string;
    account_email: string;
    account_name: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService implements OnDestroy {

    public siteWebtagSubject: BehaviorSubject<Webtag> = new BehaviorSubject(null);
    public siteWebtag: Observable<Webtag> = this.siteWebtagSubject.asObservable();

    public loginUserSubject: BehaviorSubject<Account | null> = new BehaviorSubject(null);
    public loginUser: Observable<Account | null> = this.loginUserSubject.asObservable();

    public readableWebtagsSubject: BehaviorSubject<Webtag[]> = new BehaviorSubject(null);
    public readableWebtags: Observable<Webtag[]> = this.readableWebtagsSubject.asObservable();

    public languageLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(true);
    public languageLoading: Observable<boolean> = this.languageLoadingSubject.asObservable();

    private forceLanguage: string = '';

    private subscriptions: Array<Subscription> = [];

    constructor(
        private http: HttpClient,
        private titleService: Title,
        private translate: TranslateService,
        private router: Router,
    ) {
        if( localStorage.getItem(LocalStorageLanguageKey) ) {
            this.setForceLanguage( localStorage.getItem(LocalStorageLanguageKey) );
        }
        this.updateLanguage();
        this.subscriptions.push(
            this.siteWebtag.subscribe(webtag => {
                if ( webtag ) {
                    this.titleService.setTitle( webtag.name );
                }
            }),
            merge(this.siteWebtag, this.loginUser).pipe( debounceTime(2000) ).subscribe(v => {
                if( this.getLanguage() ) {
                    this.translate.use( this.getLanguage() );
                }
            }),
        );
    }

    ngOnInit() {}

    ngOnDestroy(): void
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    /* FOR APP_INITIALIZER */
    initData(): Promise<any>
    {
        return this.http.get<any>(`${api}/auth/user`)
                    .pipe(
                        tap( res => {
                            const data = new Account();
                            Object.assign(data, res.data);
                            this.loginUserSubject.next(data);
                        }),
                        map( res => true ),
                        catchError( err => of(true) ),
                    )
                    .toPromise();
    }

    /* FOR APP_INITIALIZER */
    initWebtag(): Promise<any>
    {
        return this.http.get<any>(`${api}/auth/site`)
                    .pipe(
                        tap( res => this.siteWebtagSubject.next(res.data) ),
                        map( res => true ),
                        catchError( err => {
                            this.router.navigate(["/404"], { skipLocationChange: true });
                            return of(true);
                        }),
                    )
                    .toPromise();
    }

    updateLanguage(): void
    {
        this.translate.use( this.getLanguage() );
    }

    /** FOR APP_INITIALIZER */
    initReadableWebtags(): Promise<any>
    {
        return this.http.get<any>(`${api}/auth/readable/webtags`)
                    .pipe(
                        tap( res => this.readableWebtagsSubject.next(res.data) ),
                        map( res => true ),
                        catchError( err => { this.readableWebtagsSubject.next([]); return of(true); } ),
                    )
                    .toPromise();
    }

    // 取得當前使用者
    getUser(): Account { return this.loginUserSubject.value; }

    // 設定當前使用者
    setUser(u: Account) {
        this.loginUserSubject.next(u);
    }

    // 確認當前使用者擁有某權限
    userHasPermission(permission: string): boolean {
        const user = this.getUser();

        // 不管權限
        if( !permission ){ return true; }
        // 使用者為SuperUser
        if( this.isPlatformAdmin() ) { return true; }
        // 無menu_group者代表其無任何權限
        if( !user.menu_group ) { return false; }

        const permissions = user.menu_group.modules.find(m => m.module_id.toLowerCase() == permission.toLowerCase());
        // 無設定
        if( !permissions ) { return false; }

        const   C = permissions.create,
                R = permissions.read,
                U = permissions.update,
                D = permissions.delete;
        // 無任何權限
        if( (C || R || U || D) == false ) { return false; }

        return true;
    }

    // 取得當前網站Webtag
    getWebtag(): Webtag { return this.siteWebtagSubject.value; }

    // 設定當前網站Webtag
    setWebtag(w: Webtag) { this.siteWebtagSubject.next(w); }

    // 取得當前語系代號
    getLanguage(): string
    {
        const user = this.loginUserSubject.value;
        const webtag = this.siteWebtagSubject.value;

        if( this.forceLanguage ) {
            return this.forceLanguage;
        } else if( user && !!user.language ) {
            return user.language;
        } else if ( webtag && !!webtag.language ) {
            return webtag.language;
        } else {
            return '';
        }
    }

    // 設定強制的語系
    setForceLanguage(lang: string)
    {
        this.forceLanguage = lang;
        if( lang ) {
            localStorage.setItem( LocalStorageLanguageKey, lang );
        } else {
            localStorage.removeItem( LocalStorageLanguageKey );
        }
        this.translate.use( this.getLanguage() );
    }

    // 使用者是否為系統管理員或平台管理員
    isPlatformAdmin(): boolean
    {
        const user = this.loginUserSubject.value;

        return user && user.role <= AccountRoles.PlatformAdmin;
    }

}
