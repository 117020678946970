import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { FullscreenComponent } from './fullscreen.component';

@NgModule({
    declarations: [
        FullscreenComponent
    ],
    imports     : [
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        TranslateModule,
    ],
    exports     : [
        FullscreenComponent
    ]
})
export class FullscreenModule {}
