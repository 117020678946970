import { MenuGroup } from "app/pages/admin/menu_groups/models";
import { SiteGroup } from "app/pages/admin/site_groups/models";

export interface LoginData {
    account: string;
    password: string;
}

export class Account {
    webtag_sn: number;
    webtag_id: string;
    id: number;
    name: string;
    account: string;
    password?: string;
    role: number;
    level: number;
    status: number;
    email: string;
    phone: string;
    language: string;
    menu_group_sn: number;
    site_group_sn: number;
    menu_group?: MenuGroup;
    menu_group_name?: string;
    site_group_name?: string;
    menu_group_id?: string;
    site_group_id?: string;
    site_group?: SiteGroup;
    created_at: string;
    updated_at: string;

    // 是否為管理者
    get isManager(): boolean {
        return this.level >= 5;
    }

    // 是否為WebtagAdmin
    get isWebtagAdmin(): boolean {
        return this.role == AccountRoles.WebtagAdmin;
    }

    // 是否為系統管理員
    get isPlatformAdmin(): boolean {
        return this.role <= AccountRoles.PlatformAdmin;
    }

    hasPermission( permission: string ): boolean {
        if ( this.isPlatformAdmin ) {
            return true;
        }
        return this.menu_group?.modules?.find( m => m.module_id == permission ) != null;
    }

}

export enum AccountRoles {
    SystemAdmin = 0,
    ProductAdmin = 1,
    PlatformAdmin = 2,
    WebtagAdmin = 3,
    NormalUser = 4,
}
