import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy, HostBinding, ViewChild, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, FormGroupDirective, NgForm, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDateRangePicker } from '@angular/material/datepicker';
import { MatFormFieldAppearance } from '@angular/material/form-field';

import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'date-range-input',
    templateUrl: './date-range.component.html',
    styles: [`
        :host {
            display: flex;
            min-width: 200px;

            &.show-prev-next-btn {
                min-width: 300px;
            }
        }

        :host ::ng-deep mat-form-field {
            width: 100%;      
        }

        /* :host.no-hint-margin ::ng-deep .mat-form-field-wrapper {
            padding-bottom: 0px;
        }

        :host.no-hint-margin ::ng-deep .mat-form-field-underline {
            bottom: 0;
        } */

    `],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent implements OnInit 
{

    /**
     * 是否顯示FormField Hint區域
     */
    @Input('no-margin') noMargin: boolean = true;

    /**
     * Input 樣式
     * 
     * @type MatFormFieldAppearance
     */
    @Input() appearance: MatFormFieldAppearance = 'fill';

    /** 
     * 日期起始時間 
     * 
     * @type Date
     */
    @Input('start') date_start: Date = new Date();

    /** 
     * 日期起始日期變更事件 
     * 
     * @returns Date
     */
    @Output('startChange') date_start_change: EventEmitter<Date> = new EventEmitter<Date>();

    /** 
     * 日期起始時間 
     * 
     * @type Date
     */
    @Input('end') date_end: Date = new Date();

    /** 
      * 日期起始日期變更事件 
      * 
      * @returns Date
      */
    @Output('endChange') date_end_change: EventEmitter<Date> = new EventEmitter<Date>();

    /**
     * 日期前後移動時的天數
     * 
     * @default 1
     * @unit 日
     */
    @Input('step') step: number = 1;

    /**
     * 日期區間天數
     * 
     * @default 0 : 不做驗證
     */
    @Input('max-days') rangeMaxDays = 0;

    @HostBinding('class.no-hint-margin') get GetNoMarginClass() { return this.noMargin; }

    @ViewChild(MatDateRangePicker) picker: MatDateRangePicker<moment.Moment>;

    @Input() @HostBinding('class.show-prev-next-btn') show_prev_next_btn: boolean = false;

    /**
     * Range Form Group
     * 
     * @value { start: moment, end: moment }
     */
    form: UntypedFormGroup = new UntypedFormGroup({
        start: new UntypedFormControl(moment(this.date_start), Validators.required),
        end: new UntypedFormControl(moment(this.date_start), Validators.required),
    }, [RangeMaxDaysValidator( () => this.rangeMaxDays )]);

    /**
     * Error State Matcher
     * 
     * 用來讓group可以同步錯誤
     */
    _ErrorStateMatcher = new DateRangeErrorStateMatcher();

    private _subscriptions: Array<Subscription> = [
        this.form.get('start').valueChanges.pipe( debounceTime(10) ).subscribe( v => this.handleStartChange(v) ),
        this.form.get('end').valueChanges.pipe( debounceTime(10) ).subscribe( v => this.handleEndChange(v) ),
    ];

    constructor(private chgDete: ChangeDetectorRef) { }

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void 
    {
        if( changes.date_start && moment(changes.date_start.currentValue).format("yyyy-MM-DD") != this.form.value.start.format('yyyy-MM-DD') )
        {
            this.form.get('start').patchValue(moment( changes.date_start.currentValue ), { emitEvent: true, onlySelf: false });
        }
        if( changes.date_end && moment(changes.date_end.currentValue).format("yyyy-MM-DD") != this.form.value.end.format('yyyy-MM-DD') )
        {
            this.form.get('end').patchValue(moment( changes.date_end.currentValue ), { emitEvent: true, onlySelf: false });
        }
    }

    ngOnDestroy()
    {
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    /**
     * 處理日期向後延伸
     */
    handleNext(): void 
    {
        this.handleChangeRangeDate(1)
    }

    /**
     * 處理日期向前延伸
     */
    handlePrev(): void 
    {
        this.handleChangeRangeDate(-1)
    }

    /**
     * 處理日期變更
     * @param count 日期天數
     */
    handleChangeRangeDate(count: number) 
    {
        const { start, end }: { start: moment.Moment, end: moment.Moment } = this.form.value;
        let scope: moment.unitOfTime.DurationConstructor = "day";

        if( count > 1 || count < -1 ) { scope = "days" }

        start.add(count * this.step, scope);
        end.add(count  * this.step, scope);

        this.form.patchValue({ start, end });
    }

    /**
     * 處理當起始日期變動時的Two-way binding
     * 
     * @param v moment.Moment 來自form.get('start').valueChanges
     */
    handleStartChange(v: moment.Moment)
    {
        if( this.form.valid && this.form.get('start').valid )
        {
            this.date_start_change.emit( v.toDate() );
        }
    }

    /**
     * 處理當結束日期變動時的Two-way binding
     * 
     * @param v moment.Moment 來自form.get('end').valueChanges
     */
    handleEndChange(v: moment.Moment)
    {
        if( this.form.valid && this.form.get('end').valid )
        {
            this.date_end_change.emit( v.toDate() );
        }
    }

}

// 
const RangeMaxDaysValidator= (valueAccessor: () => number): ValidatorFn => (f): ValidationErrors => {
    const maxDays = valueAccessor();
    const start: moment.Moment = f.value.start;
    const end: moment.Moment = f.value.end; 

    // 一天以上才判斷
    if( maxDays <= 0 ) { return null }

    // 只處理天數問題
    if( !start || !end ) { return null; }

    if( end.diff( start, 'days' ) > maxDays )
    {
        return {
            'max-days': {
                msg: 'msg.invalid_max_range_days',
                maxDays: maxDays,
            }
        }
    }

    return null;
}

class DateRangeErrorStateMatcher implements ErrorStateMatcher {
    
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        const isControlError = !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
        const isGroupError = !!(form && form.errors && (form.dirty || form.touched || isSubmitted));

        return isControlError || isGroupError;
    }

}