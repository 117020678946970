import { ValidatorFn, UntypedFormGroup, ValidationErrors, UntypedFormControl } from '@angular/forms';

/* Equal Validator */
export const equalValidator = (colA: string, colB: string): ValidatorFn => (control: UntypedFormGroup): ValidationErrors | null => {
    const fieldA = control.get(colA);
    const fieldB = control.get(colB);

    // If the field is not found, do not verify
    if ( !fieldA || !fieldB ) { return null; }

    return fieldA && fieldB && fieldA.value !== fieldB.value ? { equalValidator: true } : null;
};

/* Password Validator */
export const passwordValidator: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
    const reg = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\!\$\#\%\@\^\&\*]).{4,}$');

    return !reg.test(control.value) || control.value.length < 8 ? { passwordValidator: true } : null;
};

/* Json Validator */
export const jsonValidator: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
    if ( control.value == null || control.value === '' ) { return null; }
    try {
        const val = JSON.parse(control.value);
        if (typeof(val) !== 'object') { throw Error('this is not a valid json string'); }
        return null;
    } catch (e) {
        return { jsonValidator: true };
    }
};

/* URL Validator */
export const URLValidator: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
    const reg = /^https:\/\/[a-zA-Z0-9\-_]*\..{2,}$/gm;

    return !reg.test(control.value) || control.value.length < 8 ? { URLValidator: true } : null;
};
