import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'app/shared/shared.module';
import { CommonModule } from '@angular/common';

/* Components */
import { LoginComponent } from './components/login/login.component';
import { LogoutComponent } from './components/logout/logout.component';

@NgModule({
    declarations: [LoginComponent, LogoutComponent],
    imports: [ CommonModule, SharedModule, RouterModule ],
    providers: []
})
export class LoginModule { }
