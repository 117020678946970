<!-- Input Templates -->
<ng-container *ngIf="input">

    <ng-container [ngSwitch]="i.type">

        <!-- Text -->
        <ng-container *ngSwitchCase="'text'">

            <mat-form-field [appearance]="appearance" [ngStyle]="{ 'display': i.htmlInputType === 'hidden' ? 'none' : '' }">
                <mat-icon *ngIf="i.icon" [svgIcon]="i.icon" matPrefix class="mr-2"></mat-icon>
                <mat-label>
                    <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </mat-label>
                <input [name]="i.name" matInput [type]="i.htmlInputType" [formControl]="input" [value]="input.value" [required]="i.required" [readonly]="i.readonly" [attr.autocomplete]="i.autoCompleteOff ? 'off' : 'on'" [attr.placeholder]="i.placeholder">
                <mat-hint *ngIf="i.hint"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-hint>
                <mat-error *ngIf="input?.errors"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-error>
            </mat-form-field>

        </ng-container>

        <!-- Password -->
        <ng-container *ngSwitchCase="'password'">

            <mat-form-field [appearance]="appearance">
                <mat-icon *ngIf="i.icon" [svgIcon]="i.icon" matPrefix class="mr-2"></mat-icon>
                <mat-label>
                    <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </mat-label>
                <input [name]="i.name" matInput type="password" [formControl]="input" [value]="input.value" [required]="i.required" [readonly]="i.readonly" [attr.autocomplete]="i.autoCompleteOff ? 'off' : 'on'">
                <mat-hint *ngIf="i.hint"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-hint>
                <mat-error *ngIf="input?.errors"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-error>
            </mat-form-field>

        </ng-container>

        <!-- Number -->
        <ng-container *ngSwitchCase="'number'">

            <mat-form-field [appearance]="appearance">
                <mat-icon *ngIf="i.icon" [svgIcon]="i.icon" matPrefix class="mr-2"></mat-icon>
                <mat-label>
                    <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </mat-label>
                <input [name]="i.name" matInput type="number" [formControl]="input" [value]="input.value" [min]="i.min" [max]="i.max" [required]="i.required" [readonly]="i.readonly" [attr.autocomplete]="i.autoCompleteOff ? 'off' : 'on'">

                <mat-hint *ngIf="i.hint"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-hint>
                <mat-error *ngIf="input?.errors"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-error>
            </mat-form-field>

        </ng-container>

        <!-- Select -->
        <ng-container *ngSwitchCase="'select'">

            <mat-form-field [appearance]="appearance">
                <mat-icon *ngIf="i.icon" [svgIcon]="i.icon" matPrefix class="mr-2"></mat-icon>
                <mat-label>
                    <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </mat-label>
                <mat-select [formControl]="input" [value]="input.value" [attr.name]="i.name" [required]="i.required || i.readonly">
                    <mat-option *ngIf="i.enableSearchOption" disabled style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);position: sticky;z-index: 1;top: 0;background-color: inherit;">
                        <input type="text" matInput #f (keyup)="handleFilterOption(f.value)" autocomplete="off" style="border: none;font-size: 1rem;width: 100%;" placeholder="Filter...">
                    </mat-option>
                    <mat-option *ngIf="!i.multiple && input.value && !i.disableReset" [value]="null"></mat-option>
                    <mat-option *ngFor="let opt of i.options" [ngStyle]="{'display': opt.hidden ? 'none' : ''}" [value]="opt.value">{{ opt.label }}</mat-option>
                </mat-select>

                <mat-hint *ngIf="i.hint"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-hint>
                <mat-error *ngIf="input?.errors"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-error>

            </mat-form-field>

        </ng-container>

        <!-- Textarea -->
        <ng-container *ngSwitchCase="'textarea'">

            <mat-form-field [appearance]="appearance">
                <mat-icon *ngIf="i.icon" [svgIcon]="i.icon" matPrefix class="mr-2"></mat-icon>
                <mat-label>
                    <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </mat-label>
                <textarea [name]="i.name" matInput [formControl]="input" [value]="input.value" rows="5" [required]="i.required" [readonly]="i.readonly"></textarea>
                <mat-hint *ngIf="i.hint"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-hint>
                <mat-error *ngIf="input?.errors"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-error>

            </mat-form-field>

        </ng-container>

        <!-- Radio -->
        <ng-container *ngSwitchCase="'radio'">

            <div class="fake-material-form-field-layout" tabindex="0" [ngClass]="{required: i.required, invalid: input.touched && input.invalid}" (focusout)="input.markAsTouched()">
                <div class="fake-material-form-field {{ appearance }}">
                    <div class="fake-mat-input-label">
                        <label>
                            <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                        </label>
                    </div>

                    <mat-radio-group class="radio-input" [formControl]="input" [value]="input.value" [style.--min-width]="i.minWidth" [disabled]="i.disabled">
                        <mat-radio-button *ngFor="let opt of i.options" color="primary" [value]="opt.value">{{ opt.label }}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <!-- Hint & Error Message -->
                <ng-container *ngIf="!(input.touched && !input.valid) && i.hint">
                    <span class="fake-mat-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
                <ng-container *ngIf="input.touched && input.errors">
                    <span class="fake-mat-input-err-label"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </div>

        </ng-container>

        <!-- Checkbox -->
        <ng-container *ngSwitchCase="'checkbox'">

            <div class="fake-material-form-field-layout" tabindex="0" [ngClass]="{required: i.required, invalid: input.touched && input.invalid}" (focusout)="input.markAsTouched()">
                <div class="fake-material-form-field {{ appearance }}">
                    <div class="fake-mat-input-label">
                        <label>
                            <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                        </label>
                    </div>

                    <div class="checkbox-input" [style.--min-width]="i.minWidth">
                        <mat-checkbox *ngFor="let opt of i.options" color="primary" [checked]="isChecked(opt)" (change)="handleCheckboxChange(opt.value, $event)" [disabled]="i.disabled">{{ opt.label }}</mat-checkbox>
                    </div>
                </div>

                <!-- Hint & Error Message -->
                <ng-container *ngIf="!(input.touched && !input.valid) && i.hint">
                    <span class="fake-mat-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
                <ng-container *ngIf="input.touched && input.errors">
                    <span class="fake-mat-input-err-label"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </div>

        </ng-container>

        <!-- File -->
        <ng-container *ngSwitchCase="'file'">

            <mat-form-field [appearance]="appearance">
                <mat-label>
                    <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </mat-label>
                <button mat-icon-button matPrefix type="button" (click)="finput.click()"> <mat-icon>attach_file</mat-icon> </button>
                <input type="text" [formControl]="input" matInput [value]="input.value" readonly [required]="i.required">
                <mat-hint *ngIf="i.hint"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-hint>
                <mat-error *ngIf="input?.errors"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-error>

                <input [name]="i.name" type="file" [multiple]="i.multiple" [accept]="i.accept" readonly style="display: none;" #finput (change)="handleFileSelect(i, finput.files)">
            </mat-form-field>

        </ng-container>

        <!-- Date Input -->
        <ng-container *ngSwitchCase="'date'">

            <mat-form-field [appearance]="appearance">
                <mat-label>
                    <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </mat-label>
                <input matInput readonly [matDatepicker]="picker" [formControl]="input" [min]="i.minDate" [max]="i.maxDate" [required]="i.required" [attr.autocomplete]="i.autoCompleteOff ? 'off' : 'on'">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-hint *ngIf="i.hint"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-hint>
                <mat-error *ngIf="input?.errors"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></mat-error>
            </mat-form-field>

        </ng-container>

        <!-- Time Input -->
        <ng-container *ngSwitchCase="'time'">

            <div class="fake-material-form-field-layout" tabindex="0" [ngClass]="{required: i.required, invalid: input.touched && input.invalid}" (focusout)="input.markAsTouched()">
                <div class="fake-material-form-field {{ appearance }}">
                    <div class="fake-mat-input-label">
                        <label>
                            <ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                        </label>
                    </div>

                    <div>
                        <mat-select #hour (selectionChange)="handleTimeChange(hour.value, minute.value)" placeholder="Hour" [attr.name]="i.name" style="width: 64px;text-align: center;" [value]="i.defaultValue?.hour">
                            <mat-option *ngFor="let opt of i.hourOptions" [value]="opt.value">{{ opt.label }}</mat-option>
                        </mat-select>

                        <span style="display: inline-block;margin: 0px 8px">:</span>

                        <mat-select #minute (selectionChange)="handleTimeChange(hour.value, minute.value)" placeholder="Minute" [attr.name]="i.name" style="width: 64px;text-align: center;" [value]="i.defaultValue?.minute">
                            <mat-option *ngFor="let opt of i.minuteOptions" [value]="opt.value">{{ opt.label }}</mat-option>
                        </mat-select>
                    </div>
                </div>

                <!-- Hint & Error Message -->
                <ng-container *ngIf="!(input.touched && !input.valid) && i.hint">
                    <span class="fake-mat-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
                <ng-container *ngIf="input.touched && input.errors">
                    <span class="fake-mat-input-err-label"><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </div>

        </ng-container>

        <!-- Unknow Setting -->
        <ng-container *ngSwitchDefault>
            <div class="unknow-box">
                Unknow input type <b>【{{ i.type }}】</b>.
            </div>
        </ng-container>

    </ng-container>

</ng-container>

<ng-template #defaultLabelTemplate let-config>{{ config.label | translate }}</ng-template>
<ng-template #defaultHintTemplate let-config>{{ config.hint | translate }}</ng-template>
<ng-template #defaultErrorTemplate let-config>{{ input?.errors | CfgFormErrorMessage: config.errorMessages | translate }}</ng-template>
