import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

/* Swiper */
import { SwiperModule } from 'swiper/angular';
import SwiperCore, { Navigation, Pagination, A11y, Zoom } from 'swiper/core';
SwiperCore.use([ Navigation, Pagination, A11y, Zoom ]);

/* Custom Modules */
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { CustomValidatorsModule } from './validators';
import { DataTableModule } from './data-table';
import { SplitHalfContainerModule } from './split-half-container/split-half-container.module';
import { FullscreenModule } from './fullscreen/fullscreen.module';

/* Components */
import { LoadingContentComponent } from './loading-content/loading-content.component';
import { SidenavLayoutComponent } from './layouts/sidenav-layout/sidenav-layout.component';
import { Status404Component } from './HttpStatusComponents/Status404Component.component';
import { PropertyDisplayComponent } from './property-display/property-display';
import { TranslateButtonComponent } from './translate-button/translate-button.component';

/* Pipes */
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { LongTextPipe } from './pipes/long-text.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { WebtagNamePipe } from './pipes/webtag-name.pipe';
import { LargeNumberPipe } from './pipes/large-number.pipe';
import { FormUiComponentsModule } from 'app/modules/form-ui-components/form-ui-components.module';
import { ZoomableComponent } from './zoomable/zoomable.component';
import { UserCanDeleteFormPipe } from './pipes/can-delete-form.pipe';
import { DebugPipe } from './pipes/debug.pipe';
import { SiteNamePipe } from './pipes/site_name.pipe';
import { HexToNumberPipe, NumberToHexPipe } from './pipes/hex2number.pipe';

import { EmptyPageComponent } from './HttpStatusComponents/empty-page.component';
import { DisplayToggleComponent } from './display-toggle/display-toggle.component';

const modules = [ RouterModule, FormsModule, ReactiveFormsModule, AngularMaterialModule, FormsModule, CustomValidatorsModule, TranslateModule, DataTableModule, FormUiComponentsModule, SwiperModule, SplitHalfContainerModule, FullscreenModule ] ;
const components = [ SidenavLayoutComponent, LoadingContentComponent, Status404Component, PropertyDisplayComponent, TranslateButtonComponent, ZoomableComponent, EmptyPageComponent, DisplayToggleComponent ];
const pipes = [ SafeUrlPipe, LongTextPipe, FileSizePipe, LargeNumberPipe, WebtagNamePipe, UserCanDeleteFormPipe, DebugPipe, SiteNamePipe, HexToNumberPipe, NumberToHexPipe ];

@NgModule({
    imports: [CommonModule, ...modules],
    declarations: [...pipes, ...components],
    exports: [...modules, ...pipes, ...components],
    providers: [],
})
export class SharedModule { }
