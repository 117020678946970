import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Site } from 'app/pages/sites/models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { IDType } from '../models';
import { FormUIService } from 'app/modules/form-ui-components/form-ui.service';
import { SiteSelectorService } from 'app/core/services/site.service';
import { SiteOption } from 'app/core/interfaces';

@Component({
    selector: 'dt-site-selector',
    templateUrl: './site-selector.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SiteSelectorComponent implements OnInit {

    // 電廠標題
    @Input() label: string = 'common.site';

    // ID Type
    @Input() id_type: IDType = IDType.UserCode;

    // 多選
    @Input() multiple: boolean = false;

    // 可讀的所有電廠
    private _sites: Array<VisiableSite> = [];

    idTypes = IDType;

    // 電廠清單 Store
    sitesSubject: BehaviorSubject<Array<VisiableSite>> = new BehaviorSubject([]);

    // 電廠清單
    sites$: Observable<Array<VisiableSite>> = this.sitesSubject.asObservable();

    // FormControl
    @Input('control') site: UntypedFormControl = new UntypedFormControl('');

    // 隱藏Webtag
    @Input() hideWebtag: boolean = false;

    // 篩選 FormControl
    siteFilter: UntypedFormControl = new UntypedFormControl('');

    // 訂閱者清單 - OnDestroy時解訂用
    private _subscriptions: Array<Subscription> = [];

    $SiteOptions: Observable<SiteOption[]>;

    constructor(
        private uiService: FormUIService,
        private chgDetect: ChangeDetectorRef,
        public siteSelectorService: SiteSelectorService,
    ) { }

    ngOnInit(): void {

        this._subscriptions.push(

            // 篩選器觸發
            this.siteFilter.valueChanges.pipe(
                debounceTime(100),
                startWith(''),
                map(event => event.value)
            ).subscribe(this.handleFilter.bind(this)),

            // 取得Webtag清單
            this.siteSelectorService.$SiteOptions.subscribe(sites => {
                this._sites = (sites as Array<VisiableSite>).map( s => {
                    s.visiable = true;
                    return s;
                });
                this.siteFilter.patchValue('');
            }),
            // this.uiService.readableSites$.subscribe(sites => {
            //     this._sites = (sites as Array<VisiableSite>).map(site => {
            //         site.visiable = true;
            //         return site;
            //     });
            //     this.siteFilter.patchValue('');
            // })

        );

    }

    handleFilter() {

        const site_id = ('' + this.siteFilter.value).toLowerCase();

        if( site_id ) {
            this._sites.forEach(w => w.visiable = false);

            this._sites.forEach(site => {

                switch( this.id_type )
                {
                    case IDType.UserCode:
                        site.visiable = site.user_site.toLowerCase().includes( site_id );
                        break;
                    case IDType.OwnerCode:
                        site.visiable = site.owner_site.toLowerCase().includes( site_id );
                        break;
                    case IDType.SystemCode:
                        site.visiable = site.site.toLowerCase().includes( site_id );
                        break;
                }

            });

        } else {
            this._sites.forEach(w => w.visiable = true);
        }

        this.sitesSubject.next( this._sites );

        this.chgDetect.markForCheck();

    }

    getSelectLabel(): string {
        const sites: Array<VisiableSite> = this.site.value;
        if( Array.isArray(sites) && sites.length ) {
            let site = '';
            switch(this.id_type)
            {
                case IDType.UserCode:
                    site = sites[0].user_site;
                    break;
                case IDType.OwnerCode:
                    site = sites[0].owner_site;
                    break;
                case IDType.SystemCode:
                    site = sites[0].site;
                    break;
            }

            if( sites.length > 1 ) {
                site += `(+${sites.length - 1} Items)`
            }

            return site;
        }

        return '';
    }

}

interface VisiableSite extends SiteOption {
    visiable: boolean;    
}
