import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-logout',
    templateUrl: 'Status404Component.component.html',
    styles: [],
})
export class Status404Component implements OnInit {

    constructor(private router: Router) {}

    ngOnInit(): void {}

    goBack() {
        window.history.back();
    }

}
