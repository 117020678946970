import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* Components */
import { ErrorMessageComponent } from './error-messages/error-messages.component';

@NgModule({
    imports:        [ CommonModule, FormsModule ],
    declarations:   [ ErrorMessageComponent ],
    exports:        [ ErrorMessageComponent ]
})

export class CustomValidatorsModule {}
