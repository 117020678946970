import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Site } from 'app/pages/sites/models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith, toArray } from 'rxjs/operators';
import { IDType } from 'app/modules/pv-ui-components/id-type-selector/models';
import { FormUIService } from '../form-ui.service';
import { SiteOption } from 'app/core/interfaces';
import { SiteSelectorService } from 'app/core/services/site.service';
import { MatSelect } from '@angular/material/select';

@Component({
    selector: 'site-selector',
    templateUrl: './site-selector.component.html',
    styles: []
})
export class SiteSelectorComponent implements OnInit, OnChanges {

    // 電廠標題
    @Input() label: string = 'common.site';

    // ID Type
    @Input() id_type: IDType = IDType.UserCode;

    // 多選
    @Input() multiple: boolean = false;

    // 可讀的所有電廠
    private _sites: Array<VisiableSite> = [];

    get sites(): Array<VisiableSite> {
        return this._sites;
    }

    // 電廠清單 Store
    sitesSubject: BehaviorSubject<Array<VisiableSite>> = new BehaviorSubject([]);

    // 電廠清單
    sites$: Observable<Array<VisiableSite>> = this.sitesSubject.asObservable().pipe( map(arr => arr.sort((a,b) => {
        switch( this.id_type )
        {
            case IDType.OwnerCode: return sortByString(a.owner_site, b.owner_site);
            case IDType.UserCode: return sortByString(a.user_site, b.user_site);
            case IDType.SystemCode: return sortByString(a.site, b.site);
            default: return sortByString(a.user_site, b.user_site);
        }
    })) );

    // FormControl
    @Input('control') site: UntypedFormControl = new UntypedFormControl('');

    // 隱藏Webtag
    @Input() hideWebtag: boolean = false;

    @ViewChild(MatSelect) selector: MatSelect;

    // 篩選 FormControl
    siteFilter: UntypedFormControl = new UntypedFormControl('');

    // 訂閱者清單 - OnDestroy時解訂用
    private _subscriptions: Array<Subscription> = [];

    constructor(
        private uiService: FormUIService,
        private siteSelectorService: SiteSelectorService,
        private chgDect: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {  

        this._subscriptions.push(

            // 篩選器觸發
            this.siteFilter.valueChanges.pipe(
                debounceTime(100),
                startWith(''),
                map(event => event.value)
            ).subscribe(this.handleFilter.bind(this)),

            // 取得Webtag清單
            this.siteSelectorService.$SiteOptions.subscribe(sites => {
                this._sites = (sites as Array<VisiableSite>).map(site => {
                    site.visiable = true;
                    return site;
                });
                this.siteFilter.patchValue('');
            })

        );

    }
 
    ngOnChanges(changes) {
        if( changes.site ) 
        {
            this._subscriptions.push( 
                this.site.valueChanges.subscribe(() => {
                    this.chgDect.detectChanges(); 
                }) 
            );
        }
    }

    ngAfterViewChecked() {
        this.chgDect.detectChanges();
    }

    handleFilter(site_id: string) {

        if (site_id) {
            site_id = site_id.toLowerCase();
            this._sites.forEach(w => w.visiable = false);

            this._sites.forEach(site => {

                switch( this.id_type )
                {
                    case IDType.UserCode:
                        site.visiable = site.user_site.toLowerCase().includes( site_id );
                        break;
                    case IDType.OwnerCode:
                        site.visiable = site.owner_site.toLowerCase().includes( site_id );
                        break;
                    case IDType.SystemCode:
                        site.visiable = site.site.toLowerCase().includes( site_id );
                        break;
                }

            });

        } else {
            this._sites.forEach(w => w.visiable = true);
        }

        this.sitesSubject.next( this._sites );

    }

}

interface VisiableSite extends SiteOption {
    visiable: boolean;    
}

const sortByString = (a: string, b: string): 0 | 1 | -1 => {
    if( a > b ) return 1;
    if( b > a ) return -1;

    return 0;
}


