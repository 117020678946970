import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, UntypedFormControl, Validator } from '@angular/forms';
@Directive({
    selector: '[matchWith][formControlName]'
})
export class MatchValidatorDirective implements Validator{
    @Input() matchWith: string;
    constructor() { }

    validate(c: UntypedFormControl) {
        return this.matchWith === c.value ? null : { matchWith: { valid: false } };
    }

}
