<ng-content></ng-content>

<ng-template>
    <mat-nav-list>
        <ng-container *ngFor="let lang of languages">
            <mat-list-item (click)="setLanguage(lang.code)">
                <span mat-line>{{ lang.label | translate }}</span>
            </mat-list-item>
        </ng-container>
    </mat-nav-list>
</ng-template>