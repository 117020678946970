import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../login.service';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/services/auth.service';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styles: [],
})
export class LogoutComponent implements OnInit {
    constructor(
        private loginService: LoginService,
        private router: Router,
        private auth: AuthService,
    ) {}

    ngOnInit(): void {
        this.loginService.logout().subscribe(
            (success) => {
                this.auth.setUser(null);
                this.router.navigate(['/login']);
            }
        );
    }
}
