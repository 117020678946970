<div mat-dialog-title>{{ 'change_password.title' | translate }}</div>
<form [formGroup]="form" (submit)="handleSubmit($event)">
    <div mat-dialog-content>
        <div class="flex flex-col gap-2">

            <mat-form-field class="w-full">
                <mat-label>{{ 'change_password.old_password' | translate }}</mat-label>
                <input formControlName="old_password" type="password" matInput required />
                <mat-error>
                    <error-messages [control]="form.get('old_password')"></error-messages>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>{{ 'change_password.new_password' | translate }}</mat-label>
                <input formControlName="new_password" type="password" matInput required />
                <mat-error>
                    <error-messages [control]="form.get('new_password')"></error-messages>
                </mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>{{ 'change_password.confirm_password' | translate }}</mat-label>
                <input formControlName="confirmed_password" type="password" matInput required [errorStateMatcher]="equalErrorMatcher" />
                <mat-error *ngIf="form.get('confirmed_password').invalid">
                    <error-messages [control]="form.get('confirmed_password')"></error-messages>
                </mat-error>
                <mat-error *ngIf="form.errors && !form.get('confirmed_password').invalid">
                    <error-messages [control]="form"></error-messages>
                </mat-error>
            </mat-form-field>

        </div>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close [disabled]="loading" class="mr-1">{{ 'common.btn_close' | translate }}</button>
        <button mat-stroked-button color="primary"  [disabled]="loading || form.invalid">
            <app-loading-content [loading]="loading"> {{ 'common.btn_confirm' | translate }} </app-loading-content>
        </button>
    </div>
</form>
