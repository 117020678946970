<ng-container *ngIf="mode == 'override' else MaskTemplate" [ngTemplateOutlet]="OverrideTemplate"></ng-container>

<ng-template #ContentTemplate>
    <ng-content></ng-content>
</ng-template>

<ng-template #OverrideTemplate>
    <div *ngIf="loading" class="py-1">
        <mat-spinner [diameter]="24" strokeWidth="2" style="margin: 0 auto;"></mat-spinner>
    </div>
    <ng-container *ngIf="!loading" [ngTemplateOutlet]="ContentTemplate"></ng-container>
</ng-template>
<ng-template #MaskTemplate>
    <ng-container [ngTemplateOutlet]="ContentTemplate"></ng-container>
    <div *ngIf="loading" class="mask px-4 py-4 position-absolute w-full h-full flex justify-center align-items-center left-0 top-0" style="z-index: 512;">
        <mat-spinner [diameter]="24" strokeWidth="2" style="margin: 0;"></mat-spinner>
    </div>
</ng-template>



