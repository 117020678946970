import { take } from 'rxjs/operators';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RealSiteSummary } from 'app/pages/statistics/models';

const unitSize = 1000;

@Pipe({ name: 'SiteName', pure: true })
export class SiteNamePipe implements PipeTransform {

    constructor(private i18n: TranslateService) {}

    transform(site: Partial<RealSiteSummary>) {
        const language = this.i18n.currentLang;

        return site[`${language}_site_name`] || site.traditional_chinese_site_name;
    }
}
