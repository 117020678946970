import { NgModule } from '@angular/core';

/* Custom Modules */
import { SnakeBarModule } from './snake-bar-module/snake-bar-module.module';
import { DialogModule } from './dialog-module/dialog.module';

/* State Matcher */
import { CustomErrorStateMatcher } from './error-matcher';

/* Angular Materials */
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatDividerModule } from '@angular/material/divider';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule, MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ErrorStateMatcher, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatAutocompleteModule  } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';

/* Configs */
import { MY_DATE_FORMATS } from './configs';
import { FullscreenOverlayContainer, OverlayContainer } from '@angular/cdk/overlay';

const modules = [
    MatCardModule, MatButtonModule, MatCheckboxModule,
    MatToolbarModule, MatFormFieldModule, MatInputModule,
    MatSelectModule, MatIconModule, MatTableModule,
    MatPaginatorModule, MatSortModule, MatSidenavModule,
    MatSlideToggleModule, MatRadioModule, MatDividerModule,
    MatListModule, MatMenuModule, /* MatChipsModule, */
    /* MatSliderModule, MatButtonToggleModule, */ MatProgressSpinnerModule,
    MatProgressBarModule, MatBottomSheetModule, MatDialogModule,
    SnakeBarModule, /* MatGridListModule, */ MatTooltipModule,
    MatTabsModule, DialogModule, MatDatepickerModule, MatMomentDateModule,
    MatButtonToggleModule, MatAutocompleteModule, MatBadgeModule,
];

@NgModule({
    exports: modules,
    declarations: [],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                floatLabel: 'auto',
                appearance: 'fill'
            }
        },
        {
            provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
            useValue: {
                strokeWidth: 2,
                diameter: 16
            }
        },
        { provide: MAT_DATE_LOCALE, useValue: 'zh-TW' },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {strict: true} },
        {
            provide: MAT_DATE_FORMATS,
            useValue: MY_DATE_FORMATS,
        },
        {
            provide: ErrorStateMatcher,
            useClass: CustomErrorStateMatcher
        },
        { provide: OverlayContainer, useClass: FullscreenOverlayContainer },
    ],
})
export class AngularMaterialModule { }
