import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AudioService {

    playAlertAudioSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    playAlertAudio$ = this.playAlertAudioSubject.asObservable();

    constructor(){}

    play(type: AudioType) {
        if (type === 'alert') {
            this.playAlertAudioSubject.next(true);
        }
    }

    stop(type: AudioType) {
        if (type === 'alert') {
            this.playAlertAudioSubject.next(false);
        }
    }

}

export type AudioType = 'alert';