import { Injectable } from '@angular/core';
import { ApiResponse } from 'app/core/interfaces';
import { ApiService } from 'app/core/services/api.service';
import { Observable } from 'rxjs';
import { SystemConfig } from './system-config-list.component';

@Injectable({ providedIn: 'root' })
export class SysConfigService {

    constructor(private api: ApiService) { }

    getSysConfigList(): Observable<ApiResponse<SystemConfig[]>>  {
        return this.api.get("/api/system/sys_configs");
    }

    getSysConfig(config_id: string): Observable<ApiResponse<SystemConfig>> {
        return this.api.get(`/api/system/sys_configs/${config_id}`);
    }

    putSysConfig(data: PutSysConfig): Observable<ApiResponse<SystemConfig>> {
        return this.api.put(`/api/system/sys_configs`, data);
    }

    putWebtagSysConfig(data: PutSysConfig): Observable<ApiResponse<SystemConfig>> {
        return this.api.put(`/api/system/webtag_sys_configs`, data);
    }

    deleteSysConfig(config_id: string): Observable<ApiResponse<any>> {
        return this.api.delete(`/api/system/sys_configs/${config_id}`);
    }

}

export interface PutSysConfig {
    config_id: string;
    comment?: string;
    data?: string;
    form_config?: string;
    is_create?: boolean;
    is_public?: boolean;
}