import { Component, Inject } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
import { CfgFormDialogData } from '../model';
import { FormDialogComponent } from './form-dialog.component';
import { CfgForm } from 'app/shared/cfg-form';

@Component({
    selector: 'app-cfg-form-dialog',
    templateUrl: './cfg-form-dialog.component.html',
})
export class CfgFormDialogComponent {

    form: CfgForm;
    columns: number = 1;
    loading: boolean = false;
    fileStore: {[key: string]: FileList} = {};
    requiredValidator = Validators.required;

    constructor(
        private i18n: TranslatePipe,
        @Inject(MAT_DIALOG_DATA) public data: CfgFormDialogData<any>,
        private dialogRef: MatDialogRef<FormDialogComponent>
    ){
        this.form = data.form;
        this.columns = data.columns || 1;
    }

    async close() {
        if( this.data.callback ) {
            this.loading = true;
            if( await this.data.callback(this.getFormValue()).catch(err => { console.error(err); return false }) ) {
                if (!this.data.keep_open) {
                    this.dialogRef.close(true);
                } else {
                    this.loading = false;
                }
            } else {
                this.loading = false;
            }
        } else {
            this.dialogRef.close(this.getFormValue());
        }
    }

    getFormValue() {
        const data = this.form.getValue();
        return data;
    }

}
