import { CfgFormFieldDefDirective } from '../../directives/cfg-form-input-template.directive';
import { FormInput, FormInputInterface, FormSelectOption, CfgForm } from '../../cfg-form.type';
import { FormArray, UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { ErrorLabelAnimation } from '../../animations';

@Component({
    selector: 'cfg-form-classic-input',
    templateUrl: './classic.component.html',
    styleUrls: ['./classic.component.scss'],
	animations: [ ErrorLabelAnimation('100ms', '50ms') ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassicComponent implements OnInit {

    @Input() CfgForm!: CfgForm;

    @Input() form!: UntypedFormGroup;

    @Input() input!: UntypedFormControl | FormArray | null;

    @Input('config') i!: FormInputInterface;

    @Input('template-def') templateDef!: CfgFormFieldDefDirective | null;

    form_arr_inputs: Array<UntypedFormControl> = [];

    TemplateVariables: any = {}

    private _checkbox_selected: Array<unknown> = [];

    private subscriptions: Subscription[] = [];

    datalist: Array<string> = [];

    @HostBinding('class') get getClassList() {
        const vars: any = {
            'classic-input-wrapper': true,
        };
        return Object.keys(vars).map( key => vars[key] ? key : '' ).filter( cls => !!cls ).join(' ');
    }

    constructor(private changeDet: ChangeDetectorRef) { }

    ngOnInit()
    {
        this.TemplateVariables = {
            $implicit: this.i,
            input: this.input,
            form: this.form,
        };
        if( this.input )
        {
            if (this.i.type == 'array') {
                this.form_arr_inputs = (this.input as FormArray).controls as Array<UntypedFormControl>;
                this.subscriptions.push(this.input.valueChanges.subscribe(() => this.changeDet.markForCheck()));
            } else {
                this.subscriptions.push( this.input.valueChanges.subscribe( () => {
                    this.changeDet.markForCheck();
                    if( this.i.type == 'time' ) {
                        const [hour, minute] = this.input.value.split(':');

                        this.i.defaultValue.hour = parseInt(hour);
                        this.i.defaultValue.minute = parseInt(minute);
                    }
                }) );
                this.subscriptions.push(
                    this.i.optionChanged$.subscribe( () => {
                        if( this.i.type == 'text' ) {
                            this.datalist = this.i.datalist;
                            this.changeDet.markForCheck();
                        }
                    }),
                )
            }
        }
    }

    handleCheckboxChange(value: unknown, e: MatCheckboxChange)
    {
        const selected = this._checkbox_selected.find( v => v === value );
        if( e.checked )
        {
            if( !selected )
            {
                this._checkbox_selected.push( value );
            }
        }
        else
        {
            if( selected )
            {
                this._checkbox_selected = this._checkbox_selected.filter( v => v !== value );
            }
        }

        this.input?.markAsDirty();
        this.input?.markAsTouched();
        this.input?.patchValue( this._checkbox_selected );
    }

    isChecked(opt: FormSelectOption)
    {
        return this._checkbox_selected.indexOf( opt.value ) >= 0;
    }

    handleTimeChange(hour: number, minute: number)
    {
        this.input?.patchValue( (''+hour).padStart(2, '0') + ':' + (''+minute).padStart(2, '0') );
    }

    handleFileSelect(input: FormInput, files?: FileList | null)
    {
        if( !files )
        {
            files = new FileList();
        }
        this.CfgForm.setFile(input.name, files);

        let fname = files[0]?.name || '';
        if( files.length > 1 ) { fname += `(+${files.length - 1} items)`; }

        this.input?.markAsTouched();
        this.input?.markAsDirty();
        this.input?.patchValue(fname);
    }

    handleFilterOption(target: string)
    {
        target = target.toLowerCase();
        this.i.options?.forEach(opt => {
            if( target )
            {
                opt.hidden = !opt.label.toLowerCase().includes(target);
            }
            else
            {
                opt.hidden = false;
            }
        });
        this.changeDet.markForCheck();
    }

    handleAddFormArrayItem()
    {
        this.CfgForm.formArrayAddRow(this.i.name);
    }

    handleRemoveFormArrayItem(index: number)
    {
        this.CfgForm.formArrayRemoveRow(this.i.name, index);
    }

}
