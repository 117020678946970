import { trigger, transition, style, animate, query, state } from "@angular/animations";

export const ToggleAnimation = trigger('toggle-animation', [
    state('collapsed', style({height: '0px', minHeight: '0', paddingTop: '0px', paddingBottom: '0px', overflow: 'hidden'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);

export const RowToggleAnimation = trigger('row-toggle-animation', [
    state('collapsed', style({height: '0px', maxHeight: '0px', paddingTop: '0px', paddingBottom: '0px'})),
    state('expanded', style({height: '*'})),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
]);
