import { InjectionToken } from "@angular/core";
import { ValidatorFn } from "@angular/forms";
import { DataTableColumn } from "./data-table/models";

export type DataTableRowTrackByCallback<T> = (idx: number, row: T) => any;
export type DataTableRowClickCallback<T> = (idx: number, row: T) => void;

export interface DataTableFilter {
    name: string;
    label: string;
    type: 'text' | 'number' | 'date' | 'select' | 'date-range' | 'sites' | 'id_type' | 'webtag' | 'equipment' | 'site_group';
    cols?: 1 | 2 | 3 | 4 | 5;

    hint?: string;
    defaultValue?: any;

    readonly?: boolean;
    disabled?: boolean;
    hidden?: boolean;

    selectTriggerCallback?: (i: DataTableFilter, value: any) => string;

    empty_label?: string;
    options?: Array<DataTableFilterOption>;
    doNotSortOptions?: boolean;
    multiple?: boolean;
}

export interface DataTableFilterOption {
    value: any;
    label: string;
    disabled?: boolean;
}

/** Define the prefix for config names in local storage */
export const DATATABLE_STORE_PREFIX = new InjectionToken<string>("data-table-store-prefix", { factory: () => 'DT-Config' });

export class DataTableDefaultOptions {
    paginator?: {
        Limit?: number;
        LimitOptions?: Array<number>;
    } = {
        Limit: 10,
        LimitOptions: [10, 20, 40, 80, 120],
    };

    table?: {
        Selectable?: boolean;
        Focusable?: boolean;
    } = { Selectable: false };

    wrapper?: {
        FilterMinWidth?: number;
        EventDebounceTime?: number;
        ShowHeader?: boolean;
        ShowColumnsConfigButton?: boolean;
        ShowFilters?: boolean;
    } = {
        FilterMinWidth: 150,
        EventDebounceTime: 100,
        ShowHeader: true,
        ShowColumnsConfigButton: true,
        ShowFilters: true,
    }
}
