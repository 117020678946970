import { ContentChild, Directive, Input, TemplateRef, ViewChild } from "@angular/core";

@Directive({ selector: '[dtCellDef],[dt-cell]', host: { class: 'dt-cell' } })
export class DataTableCellDef {
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[dtHeaderCellDef],[dt-header-cell]', host: { class: 'dt-header-cell' } })
export class DataTableHeaderCellDef {
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[dtPanelToggleDef],[dt-panel-toggle]', host: { class: 'dt-panel-toggle' } })
export class DataTablePanelToggleDef {
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[dtPanelContentDef],[dt-panel-content]', host: { class: 'dt-panel-content' } })
export class DataTablePanelContentDef {
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[dtColumnDef],[dt-column]' })
export class DataTableColumnDef {

    @Input('dt-column') 
    get dtColumnDefDash(): string { return this._name; }
    set dtColumnDefDash(name: string) { this._setNameInput(name); }
    @Input('dtColumnDef') 
    get dtColumnDef(): string { return this._name; }
    set dtColumnDef(name: string) { this._setNameInput(name); }

    get name() { return this._name; }

    protected _name: string = '';
    protected _setNameInput(value: string) {
        if (value) {
            this._name = value;
        }
    }

    @ContentChild(DataTableCellDef) cellDef!: DataTableCellDef;
    @ContentChild(DataTableHeaderCellDef) headerDef!: DataTableHeaderCellDef;
    @ContentChild(DataTablePanelToggleDef) panelToggleDef!: DataTablePanelToggleDef;
    @ContentChild(DataTablePanelContentDef) panelContentDef!: DataTablePanelContentDef;
}
