import { enableProdMode, Injector } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { AppInjector } from './app/core/app-injector';
import { environment } from './environments/environment';

// Injector Accessor
export var appInjector:Injector = null;

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(moduleRef => {
        AppInjector.setInjector(moduleRef.injector);
        return moduleRef;
    })
    .then(moduleRef => {
        appInjector = moduleRef.injector;
        return moduleRef;
    })
    // .then(moduleRef => {
    //     AppInjector.getInjector().get(AuthService).updateLanguage();
    // })
    .catch(err => console.error(err));
