import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { CfgFormDialogData, ConfirmDialogConfig, FormDialogData, InputDialogConfig } from './model';
import { ConfirmDialogComponent } from './components/confirm-dialog.component';
import { InputDialogComponent } from './components/input-dialog.component';
import { FormDialogComponent } from './components/form-dialog.component';
import { DialogLoadingComponent } from './components/dialog-loading.component';
import { CfgFormDialogComponent } from './components/cfg-form-dialog.component';

@Injectable({ providedIn: 'root' })
export class DialogService {

    constructor( private dialog: MatDialog ) {}

    confirm(config: ConfirmDialogConfig, dialogConfig: MatDialogConfig = { maxWidth: '480px' }): MatDialogRef<ConfirmDialogComponent> {
        const defaultConfig: ConfirmDialogConfig = {
            message: '',
            action_align: 'end',
            title: 'dialog.confirm.default.title',
            close_btn_text: 'common.btn_close',
            close_btn_color: '',
            confirm_btn_text: 'common.btn_confirm',
            confirm_btn_color: '',
        };
        if (config.matDialog) {
            return config.matDialog.open(ConfirmDialogComponent, { ...dialogConfig, data: Object.assign(defaultConfig, config) });
        } else {
            return this.dialog.open(ConfirmDialogComponent, { ...dialogConfig, data: Object.assign(defaultConfig, config) });
        }
    }

    input(config: InputDialogConfig, dialogConfig: MatDialogConfig = { maxWidth: '480px', minWidth: '350px' }): MatDialogRef<InputDialogComponent> {
        const defaultConfig: InputDialogConfig = {
            message: '',
            action_align: 'end',
            title: 'dialog.input.default.title',
            close_btn_text: 'common.btn_close',
            close_btn_color: '',
            confirm_btn_text: 'common.btn_submit',
            confirm_btn_color: 'primary',
            input: {
                label: '',
                default: '',
                require: true,
                placeholder: '',
                hint: '',
            }
        };
        return this.dialog.open(InputDialogComponent, { ...dialogConfig, data: Object.assign(defaultConfig, config) });
    }

    form<T>(data: FormDialogData<T>, config?: MatDialogConfig) {
        data = Object.assign({ 
            inputs: [],
            columns: 1,
            action_align: 'end',
            close_btn_text: 'common.btn_close',
            close_btn_color: '',
            confirm_btn_text: 'common.btn_submit',
            confirm_btn_color: '',
        } as FormDialogData<T>, data);
        let conf = { data, ...config };
        let base_conf = { hasBackdrop: true, disableClose: true, minWidth: 400 } as MatDialogConfig;

        const dialogRef = this.dialog.open(FormDialogComponent, { ...base_conf, ...conf });

        return dialogRef.afterClosed();
    }

    loading(config?: MatDialogConfig) {
        return this.dialog.open(DialogLoadingComponent, { width: '300px', height: '300px', ...config });
    }

    cfgForm(cfgForm: CfgFormDialogData<any>, config?: MatDialogConfig) {
        const base_cfgForm: CfgFormDialogData<any> = { title: '', form: null, columns: 1, close_btn_color: '', close_btn_text: 'common.btn_close', confirm_btn_color: 'primary', confirm_btn_text: 'common.btn_submit' }; 
        const base_conf = { hasBackdrop: true, disableClose: true, minWidth: 400 } as MatDialogConfig;
        const conf = { data: { ...base_cfgForm, ...cfgForm}, ...config };

        const dialogRef = this.dialog.open(CfgFormDialogComponent, { ...base_conf, ...conf });

        return dialogRef.afterClosed();
    }

}
