<ng-container *ngIf="config">
    <thead>
        <tr>
            <th *ngIf="selectable" style="vertical-align: middle;">
                <mat-checkbox class="row-checkbox"
                              (change)="handleSelectAll($event)"
                              [checked]="(selectedAll$ | async) == 1"
                              [indeterminate]="(selectedAll$ | async) == 2"
                              color="primary"></mat-checkbox>
            </th>
            <ng-container *ngFor="let col of config.cols">
                <ng-container *ngIf="getHeaderTemplate(col.name) else defaultHeaderTemplate">
                    <ng-container *ngTemplateOutlet="getHeaderTemplate(col.name); context: {$implicit: col}"></ng-container>
                </ng-container>
                <ng-template #defaultHeaderTemplate>
                    <th [ngStyle]="{width: col.width}" class="{{ col.headerPanelClass }}">
                        {{ col.label | translate }}
                        <ng-container *ngIf="col.sort" [ngTemplateOutlet]="SortButton" [ngTemplateOutletContext]="{ $implicit: col }"></ng-container>
                    </th>
                </ng-template>
            </ng-container>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="dataSource$ ? (dataSource$ | async) : config.data as data">
            <ng-container *ngIf="data && data.length == 0" [ngTemplateOutlet]="NoDataRow"></ng-container>
            <ng-container *ngFor="let row of data; trackBy:trackByFn">
                <tr>
                    <ng-container *ngIf="selectable">
                        <td> <mat-checkbox #select_row
                                           class="row-checkbox"
                                           (change)="handleSelectRow($event)"
                                           [checked]="isSelectedRow(row)"
                                           [value]="row"
                                           color="primary"></mat-checkbox> </td>
                    </ng-container>
                    <ng-container *ngFor="let col of config.cols; let idx = index">
                        <ng-container *ngIf="getTemplate(col.name) as temp else CellTemplate">
                            <ng-container *ngTemplateOutlet="temp; context: {$implicit: row, index: idx, rows: data, columnDef: col}"></ng-container>
                        </ng-container>
                        <ng-template #CellTemplate>
                            <ng-container [ngSwitch]="col.type">
                                <td *ngSwitchCase="'panel'" class="p-0">
                                    <ng-container *ngIf="getPanelTogglerTemplate(col.name) as temp else defaultPanelToggler">
                                        <ng-container [ngTemplateOutlet]="temp" [ngTemplateOutletContext]="{ $implicit: row, index: idx, rows: data, columnDef: col, isOpen: isPanelOpen(row) }"></ng-container>
                                    </ng-container>
                                    <ng-template #defaultPanelToggler>
                                        <button mat-icon-button (click)="togglePanel(row)">
                                            <mat-icon class="panel-icon" [ngClass]="{'icon-rotate-90': isPanelOpen(row)}">keyboard_arrow_right</mat-icon>
                                        </button>
                                    </ng-template>
                                </td>
                                <td *ngSwitchCase="'text'" class="{{ col.panelClass }}">{{ row[col.name] }}</td>
                                <td *ngSwitchCase="'date'" class="{{ col.panelClass }} no-wrap">{{ row[col.name] | date: "yyyy-MM-dd" }}</td>
                                <td *ngSwitchCase="'datetime'" class="{{ col.panelClass }} no-wrap">{{ row[col.name] | date: "yyyy-MM-dd HH:mm:ss" }}</td>
                                <td *ngSwitchCase="'number'" class="{{ col.panelClass }}" style="text-align: right;">{{ row[col.name] | number: col.format : 'en' }}</td>
                                <td *ngSwitchCase="'button'" class="py-1 px-2 align-middle no-wrap {{ col.panelClass }}" style="vertical-align: middle;">
                                    <button class="btn btn-sm btn-outline-secondary" (click)="col.callback(row, idx, col)">{{ col.button_label | translate }}</button>
                                </td>
                                <td *ngSwitchCase="'link'" class="py-1 px-2 align-middle no-wrap {{ col.panelClass }}">
                                    <a class="btn btn-sm btn-outline-secondary" [routerLink]="col.link(row)">{{ col.button_label | translate }}</a>
                                </td>
                                <td *ngSwitchDefault class="{{ col.panelClass }}">{{ row[col.name] }}</td>
                            </ng-container>
                        </ng-template>
                    </ng-container>
                </tr>
                <ng-container *ngIf="getPanelCols().length">
                    <ng-container *ngFor="let col of getPanelCols(); let idx = index">
                        <tr class="panel-row"></tr>
                        <tr class="panel-row">
                            <td [colSpan]="config.cols.length + (selectable ? 1 : 0)" class="{{ col.panelClass }}">
                                <div class="panel-detail" *ngIf="isPanelOpen(row)" [@slideInOut]>
                                    <ng-container *ngTemplateOutlet="getPanelContentTemplate(col.name); context: {$implicit: row, index: idx, rows: data, columnDef: col}"></ng-container>
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>

    </tbody>
</ng-container>

<ng-template #NoDataRow>
    <tr>
        <td [attr.colspan]="config.cols.length + (selectable ? 1 : 0)">No Data</td>
    </tr>
</ng-template>

<ng-template #SortButton let-col>
    <dt-sort-button [config]="config" [column]="col"></dt-sort-button>
</ng-template>
