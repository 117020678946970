import { DataTableColumnDef } from './../directives/cell.directive';
import { Animations } from '../animations';
import { DataTable, DataTableColumn } from './models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { ChangeDetectorRef, Component, ContentChildren, Input, OnInit, QueryList, TemplateRef, EventEmitter, Output, ViewChildren, HostBinding, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { debounceTime } from 'rxjs/operators';

@Component({
    selector: 'data-table,[data-table]',
    templateUrl: './data-table.component.html',
    styleUrls: ['./data-table.component.scss'],
    animations: Animations,
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent implements OnInit {

    @Input('data') config: DataTable<any> | null = null;
    @Output() configChange: EventEmitter<DataTable<any>> = new EventEmitter();

    @Input('dataSource') dataSource$: Observable<Array<any>> | null = null;

    @Input() selectable: boolean = false;
    @Output() selectRow:  EventEmitter<Array<any>> = new EventEmitter();

    @Input() stickyHeader: boolean = true;
    @Input() stickyFooter: boolean = true;

    @ContentChildren(DataTableColumnDef) column_defs!: QueryList<DataTableColumnDef>;

    @ViewChildren('select_row') selected_row_checkboxes!: QueryList<MatCheckbox>;

    @HostBinding('attr.sticky-header') get AttrStickyHeader() { return this.stickyHeader; }
    @HostBinding('attr.sticky-footer') get AttrStickyFooter() { return this.stickyFooter; }

    public panelTarget: any;

    private selectedAllSubject = new BehaviorSubject<0 | 1 | 2>(0);
    public selectedAll$ = this.selectedAllSubject.asObservable();

    private subscriptions: Array<Subscription> = [];

    constructor(private changeDetect: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.selectedAllSubject.next( this.isSelectedAll() );
        if( this.config ) {
            this.subscriptions.push(
                this.config.change$.pipe( debounceTime( 10 ) ).subscribe( () => {
                    this.selectedAllSubject.next( this.isSelectedAll() );
                    this.changeDetect.markForCheck();
                }),
            )
        }

        // setInterval(() => this.changeDetect.markForCheck(), 1000)
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    ngOnChanges(changes: SimpleChanges) {
        if( changes.config ) {
            this.selectedAllSubject.next( this.isSelectedAll() );
            this.changeDetect.detectChanges();
            this.subscriptions.forEach(s => s.unsubscribe());
            if (this.config) {
                this.subscriptions.push(
                    this.config.change$.subscribe( () => {
                        this.selectedAllSubject.next( this.isSelectedAll() );
                        // this.changeDetect.markForCheck();
                    }),
                )
            }
        }
    }

    getTemplate(col: string): TemplateRef<any> | null {
        const def = this.getColumnDef(col);
        if ( !def || !def.cellDef ) return null;

        return def.cellDef.template;
    }

    getHeaderTemplate(col: string): TemplateRef<any> | null {
        const def = this.getColumnDef(col);
        if ( !def || !def.headerDef ) return null;

        return def.headerDef.template;
    }

    getPanelTogglerTemplate(col: string): TemplateRef<any> | null {
        const def = this.getColumnDef(col);
        if ( !def || !def.panelToggleDef ) return null;

        return def.panelToggleDef.template;
    }

    getPanelContentTemplate(col: string): TemplateRef<any> | null {
        const def = this.getColumnDef(col);
        if ( !def || !def.panelContentDef ) return null;

        return def.panelContentDef.template;
    }

    getColumnDef(col: string): DataTableColumnDef | null {
        if( !(this.column_defs instanceof QueryList) ){ return null;}
        return this.column_defs.find( c => c.name == col ) || null;
    }

    getPanelCols(): Array<DataTableColumn<any>> {
        if( !this.config ) return [];

        return this.config.cols.filter( c => c.type == 'panel' );
    }

    togglePanel(row: any): void {
        this.panelTarget = this.panelTarget != row ? row : null;
        this.changeDetect.markForCheck();
    }

    isPanelOpen(row: any): boolean {

        if( !this.panelTarget ) return false;

        if( this.config?.isPanelOpenCallback ) {
            return this.config.isPanelOpenCallback( this.panelTarget )( row );
        } else {
            return this.panelTarget == row;
        }

    }

    handleSelectRow(e: MatCheckboxChange): void {
        if( !this.config ) return;
        if( !this.config.isSelected ) {
            e.source.checked = false;
            return;
        }

        if( e.checked ) {
            this.config.addSelectedRow( e.source.value );
        } else {
            this.config.removeSelectedRow( e.source.value );
        }

        this.selectedAllSubject.next( this.isSelectedAll() );
    }

    isSelectedRow(row: any): boolean {
        if( !this.config || !this.config.isSelected ) return false;
        return !!this.config.isSelectedRow( row );
    }

    // 0 - no selected , 1 - all selected , 2 - some selected
    isSelectedAll(): 0 | 1 | 2 {
        if( !this.config || !this.config.data ) return 0;

        const count = this.config.data.map( r => this.config && this.config.isSelectedRow(r) ).filter(selected => selected).length

        if( count ) { return count == this.config.data.length ? 1 : 2 }
        else { return 0 }
    }

    handleSelectAll(e: MatCheckboxChange): void {
        if( !this.config && this.selected_row_checkboxes ) return;
        if( e.checked ) {
            const unselected = this.selected_row_checkboxes.filter(c => !c.checked);
            unselected.forEach( c => this.config?.addSelectedRow(c.value) )
        } else {
            const selected = this.selected_row_checkboxes.filter(c => c.checked)
            selected.forEach( c => this.config?.removeSelectedRow(c.value) )
        }

        this.selectedAllSubject.next( this.isSelectedAll() );
    }

    trackByFn(idx, row) {
        return row.id || idx;
    }

    trackByFnForPanel(idx, row) {
        return idx+'-panel'
    }

}
