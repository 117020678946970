import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DataTable } from 'app/shared/data-table';
import { FormWork, WorkRepo } from 'app/pages/forms/models';
import { FormFile } from '../forms/models';
import { DialogService } from 'app/shared/angular-material/dialog-module/dialog.service';
import { TranslatePipe } from '@ngx-translate/core';
import { TranslatePipeProvider } from 'app/core/i18n/ngx-translate-pipe';
import { AlertService } from 'app/shared/angular-material/snake-bar-module/services/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { FormUIService } from './form-ui.service';
import { AuthService } from 'app/core/services/auth.service';
import { Validators } from '@angular/forms';
import { FormDialogInputOption, FormDialogData } from 'app/shared/angular-material/dialog-module/model';
import * as moment from 'moment';

@Component({
    selector: 'form-work-detail',
    templateUrl: './form-work-detail.component.html',
    styleUrls: ['./form-work-detail.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ TranslatePipeProvider ],
})
export class FormWorkDetailComponent implements OnInit {

    @Input() work: FormWork;
    @Output() workChange: EventEmitter<FormWork> = new EventEmitter();

    @Output() deleteFile: EventEmitter<FormFile> = new EventEmitter();

    repos: DataTable<WorkRepo> = new DataTable({
        columns: [
            // { name: 'id', label: 'forms.work_repo.id' },
            // { name: 'webtag_id', label: 'forms.work_repo.webtag_id' },
            // { name: 'form_type', label: 'forms.work_repo.form_type' },
            // { name: 'form_id', label: 'forms.work_repo.form_id' },
            // { name: 'worker_id', label: 'forms.work_repo.worker_id' },
            { name: 'worker_name', label: 'forms.worker.name', panelClass: 'text-nowrap' },
            { name: 'repo_content', label: 'forms.work_repo.repo_content' },
            { name: 'repo_work_time', label: 'forms.work_repo.repo_work_time' },
            { name: 'work_start', label: 'forms.common.work_start', type: 'datetime', panelClass: 'text-nowrap' },
            { name: 'work_end', label: 'forms.common.work_end', type: 'datetime', panelClass: 'text-nowrap' },
            // { name: 'webtag_sn', label: 'forms.work_repo.webtag_sn' },
            // { name: 'form_sn', label: 'forms.work_repo.form_sn' },
            // { name: 'work_sn', label: 'forms.work_repo.work_sn' },
            // { name: 'created_at', label: 'forms.work_repo.created_at' },
            // { name: 'updated_at', label: 'forms.work_repo.updated_at' },
            { name: 'menu', label: '', description: 'common.tools' },
        ]
    });

    canModifyWork: boolean = false;

    constructor(
        private auth: AuthService,
        private dialog: DialogService,
        private i18n: TranslatePipe,
        private alert: AlertService,
        private matDialog: MatDialog,
        private formService: FormUIService,
        public cdr: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
        this.repos.data = this.work.work_repos;
    }

    ngOnChanges(chg) {

        if( chg.work ) {
            this.repos.data = this.work.work_repos;

            const user = this.auth.getUser();
            if( user ) {
                this.canModifyWork =
                    user.isPlatformAdmin ||
                    (
                        user.webtag_id == this.work.webtag_id &&
                        (
                            user.isManager ||
                            user.isWebtagAdmin ||
                            user.account == this.work.worker_id
                        )
                    );
            } else {
                this.canModifyWork = false;
            }
            this.cdr.detectChanges();
        }

    }

    handleRemoveFile(f: FormFile) {
        this.dialog.confirm({
            title: this.i18n.transform('dialog.confirm.delete.title', { resource: this.i18n.transform('common.file') }),
            message: this.i18n.transform(`dialog.confirm.delete`, { resource: this.i18n.transform('common.file'), name: f.name }),
            confirm_btn_color: 'warn',
            confirm_btn_text: 'common.btn_delete'
        }).afterClosed().subscribe(
            confirm => {
                if( confirm ) {
                    this.formService.deleteFormFile( f ).subscribe(
                        res => {
                            this.alert.success(
                                this.i18n.transform('msg.delete_success', {resource: this.i18n.transform('common.file')})
                            );
                            this.deleteFile.emit( f );
                            this.work.files = this.work.files.filter( file => file != f );
                        },
                        error => {
                            this.alert.error(this.i18n.transform('msg.delete_fail', {resource: this.i18n.transform('common.file'), msg: error.message}))
                        }
                    );
                }
            }
        );
    }

    handleViewFile(template: TemplateRef<any>) {
        this.matDialog.open(template, { width: '80%' });
    }

    handleUpdateRepo(report: WorkRepo) {

        const hourOptions: FormDialogInputOption[] = [];
        const timeOptions: FormDialogInputOption[] = [];
        for( let i = 0 ; i < 24 ; i ++ ){
            const label = (''+i).padStart(2, '0');
            hourOptions.push({ label, value: i, disabled: false });
        }
        for( let i = 0 ; i < 60 ; i ++ ){
            const label = (''+i).padStart(2, '0');
            timeOptions.push({ label, value: i, disabled: false });
        }

        this.dialog.form({
            title: 'forms.work.repo.update_repo.title',
            columns: 2,
            inputs: [
                { type: 'select', name: 'worker_start_hour', label: 'forms.work.repo.start_time.hour', defaultValue: 8, options: hourOptions, validators: [Validators.required] },
                { type: 'select', name: 'worker_start_time', label: 'forms.work.repo.start_time.time', defaultValue: 30, options: timeOptions, validators: [Validators.required] },
                { type: 'select', name: 'worker_end_hour', label: 'forms.work.repo.end_time.hour', defaultValue: 8, options: hourOptions, validators: [Validators.required] },
                { type: 'select', name: 'worker_end_time', label: 'forms.work.repo.end_time.time', defaultValue: 30, options: timeOptions, validators: [Validators.required] },
                { type: 'textarea', name: 'repo_content', label: 'forms.work.repo.content', defaultValue: report.repo_content, validators: [Validators.required], columns: 2 },
            ],
            callback: (data) => {
                const m = moment();

                // Start Time
                m.set({ hour: data.worker_start_hour, minute: data.worker_start_time, second: 0, millisecond: 0 })
                const work_start_time = m.toDate();

                // End Time
                m.set({ hour: data.worker_end_hour, minute: data.worker_end_time, second: 0, millisecond: 0 })
                const work_end_time = m.toDate();

                // Data
                const repo = {
                    repo_content: data.repo_content,
                    work_start_time, work_end_time,
                }

                return new Promise(resolver => {
                    this.formService.updateWorkReport(this.work.form_id, this.work.work_id, report.id, repo)
                        .subscribe(
                            res => {
                                this.alert.success(this.i18n.transform('msg.create_success'));
                                const idx = this.work.work_repos.findIndex(r => r.id == report.id);
                                if( idx >= 0 ) {
                                    this.work.work_repos[idx] = res.data;
                                    this.workChange.emit(this.work);
                                }
                                resolver(true);
                            },
                            err => {
                                this.alert.error(err.message + this.i18n.transform('msg.create_fail', { msg: err.message }));
                                resolver(false);
                            }
                        )
                });
            }
        } as FormDialogData<{
            repo_content: string,
            worker_start_hour: number,
            worker_start_time: number,
            worker_end_hour: number,
            worker_end_time: number,
        }>);

    }

    handleRemoveRepo(report: WorkRepo) {
        this.dialog.confirm({
            title: this.i18n.transform('dialog.confirm.delete.title', { resource: this.i18n.transform('forms.work.repo.title') }),
            message: this.i18n.transform(`dialog.confirm.delete`, { resource: this.i18n.transform('forms.work.repo.title'), name: '' }),
            confirm_btn_color: 'warn',
            confirm_btn_text: 'common.btn_delete'
        }).afterClosed().subscribe(
            confirm => {
                if( confirm ) {
                    this.formService.deleteWorkReport(this.work.form_id, this.work.work_id, report.id).subscribe(
                        res => {
                            this.alert.success(
                                this.i18n.transform('msg.delete_success', {resource: this.i18n.transform('forms.work.repo.title')})
                            );
                            const idx = this.work.work_repos.findIndex(r => r.id == report.id);
                            if( idx >= 0 ) {
                                this.work.work_repos[idx] = res.data;
                                this.workChange.emit(this.work);
                            }
                        },
                        error => {
                            this.alert.error(this.i18n.transform('msg.delete_fail', {resource: this.i18n.transform('common.file'), msg: error.message}))
                        }
                    );
                }
            }
        );
    }

}
