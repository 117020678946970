import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { IDType } from '../models';

@Component({
    selector: 'dt-id-type-selector',
    templateUrl: './id-type-selector.component.html',
    styles: [
    ]
})
export class IDTypeSelectorComponent implements OnInit {

    idTypes = IDType;

    // FormControl
    @Input('control') id_type: UntypedFormControl = new UntypedFormControl('');

    // 訂閱者清單 - OnDestroy時解訂用
    private _subscriptions: Array<Subscription> = [];

    constructor() {}

    ngOnInit(): void {}

}
