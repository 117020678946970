import { NgModule, APP_INITIALIZER } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Interceptors
import { HttpJsonInterceptor } from './interceptors/json-content.interceptor';

// Validators
import { MatchValidatorDirective } from './validators';

// Services
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';

// Providers
import { TranslatePipeProvider } from './i18n/ngx-translate-pipe';
import { PwaService } from './services/pwa.service';

// Imports
const services = [];
const directives = [MatchValidatorDirective];

export function loginStatusFactory(authService: AuthService) {
    return () => authService.initData();
}

export function siteWebtagFactory(authService: AuthService) {
    return () => authService.initWebtag();
}

export function readableWebtagFactory(authService: AuthService) {
    return () => authService.initReadableWebtags();
}

export function pwaServiceFactory(service: PwaService) {
    return () => service.initAttacheEvents();
}

@NgModule({
    declarations: [...directives],
    imports: [CommonModule, RouterModule, ReactiveFormsModule],
    providers: [
        {
            provide: APP_INITIALIZER,
            deps: [AuthService],
            useFactory: loginStatusFactory,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            deps: [AuthService],
            useFactory: siteWebtagFactory,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            deps: [AuthService],
            useFactory: readableWebtagFactory,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpJsonInterceptor,
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            deps: [PwaService],
            useFactory: pwaServiceFactory,
            multi: true
        },
        // TranslatePipeProvider,
        ...directives,
        ...services,
    ],
    exports: [...directives]
})
export class CoreModule {}
