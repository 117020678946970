<ng-container *ngFor="let i of CfgForm.inputs">
    <div *ngIf="!(i.type == 'text' && i.htmlInputType == 'hidden')" class="cfg-form-col-{{ columnsMap[i.name] || 1 }}" [ngStyle]="{
        'min-width': CfgForm.colMinWidth,
        'flex': i.disableAutoScale ? '0 0 auto' : ''
    }">

		<ng-container *ngIf="getBeforeAreaTemplate(i.name) as t" [ngTemplateOutlet]="t" [ngTemplateOutletContext]="{$implicit: i, input: getFormControl(i), form: CfgForm.form}"></ng-container>

        <ng-container *ngIf="CfgForm.style === 'classic'">
            <cfg-form-classic-input [CfgForm]="CfgForm" [form]="CfgForm.form" [input]="getFormControl(i)" [template-def]="getFieldDef(i.name)" [config]="i"></cfg-form-classic-input>
        </ng-container>

        <ng-container *ngIf="CfgForm.style === 'float'">
            <cfg-form-material-input [CfgForm]="CfgForm" [form]="CfgForm.form" [input]="getFormControl(i)" [template-def]="getFieldDef(i.name)" [config]="i"></cfg-form-material-input>
        </ng-container>

        <ng-container *ngIf="CfgForm.style === 'fill'">
            <cfg-form-material-input [CfgForm]="CfgForm" [form]="CfgForm.form" [input]="getFormControl(i)" [template-def]="getFieldDef(i.name)" [config]="i" appearance="fill"></cfg-form-material-input>
        </ng-container>

        <ng-container *ngIf="CfgForm.style === 'outline'">
            <cfg-form-material-input [CfgForm]="CfgForm" [form]="CfgForm.form" [input]="getFormControl(i)" [template-def]="getFieldDef(i.name)" [config]="i" appearance="outline"></cfg-form-material-input>
        </ng-container>

        <ng-container *ngIf="CfgForm.style === 'mobile'">
            <cfg-form-mobile-input [CfgForm]="CfgForm" [form]="CfgForm.form" [input]="getFormControl(i)" [template-def]="getFieldDef(i.name)" [config]="i" appearance="outline"></cfg-form-mobile-input>
        </ng-container>

		<ng-container *ngIf="getAfterAreaTemplate(i.name) as t" [ngTemplateOutlet]="t" [ngTemplateOutletContext]="{$implicit: i, input: getFormControl(i), form: CfgForm.form}"></ng-container>

    </div>
	<ng-container *ngIf="i.breakLine">
		<div class="w-full text-center">
			<ng-container *ngIf="getBreakLineTemplate(i.name) as t" [ngTemplateOutlet]="t" [ngTemplateOutletContext]="{$implicit: i, input: getFormControl(i), form: CfgForm.form}"></ng-container>
		</div>
	</ng-container>
</ng-container>
