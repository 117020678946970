import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../../core/services/api.service';
import { LoginData, Account } from './models';
import { map } from 'rxjs/operators';
import { BackendHost, BackendPath } from 'app/configs/backend';

const api = `${BackendHost}${BackendPath}/auth`;

@Injectable({ providedIn: 'root' })
export class LoginService {

    constructor(private api: ApiService) {}

    login(loginData: LoginData): Observable<Account> {
        return this.api.post(`${api}/login`, loginData).pipe( map( account => account.data ) );
    }

    logout(): Observable<any> {
        return this.api.post(`${api}/logout`);
    }

}
