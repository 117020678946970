import {HttpClient} from "@angular/common/http";
import {TranslateLoader} from "@ngx-translate/core";
import {Observable} from "rxjs";
import {finalize, map, shareReplay} from "rxjs/operators";
import { LanguageService } from './language.service';


export class CachedHttpTranslationLoader implements TranslateLoader {
  
    cache$: Observable<Object> = null;
    cachedLang: string = null;
    
    constructor(
        private langService: LanguageService,
        private http: HttpClient,
        public prefix: string = "/api/auth/site/language",
        public suffix: string = ""
    ) {}
  
    /**
     * Gets the translations from the server
     */
    public getTranslation(lang: string): Observable<Object> {
        if (!this.cache$ || this.cachedLang !== lang) {
            this.langService.languageLoadingSubject.next(true);
            this.cache$ = this.http.get<any>(`${this.prefix}${lang}${this.suffix}`).pipe(
                shareReplay(1), 
                map(value => value.data),
                finalize(() => {
                    this.langService.languageLoadingSubject.next(false)
                }),
            );
            this.cachedLang = lang;
        }
        return this.cache$;
    }
}
