<mat-form-field [appearance]="appearance" (click)="picker.open()" class="h-full" [ngClass]="{ 'no-hint-area': noMargin }">
    <mat-label>{{ 'common.date.range' | translate }}</mat-label>

    <ng-content select="[at-form-field]"></ng-content>

    <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="{{ 'common.date.start' | translate }}" readonly>
        <input matEndDate formControlName="end" placeholder="{{ 'common.date.end' | translate }}" readonly [errorStateMatcher]="_ErrorStateMatcher">
    </mat-date-range-input>
    <div matSuffix style="width: 80px; display: flex;" *ngIf="show_prev_next_btn">
        <button mat-icon-button (click)="handlePrev();$event.stopPropagation();"><mat-icon>keyboard_arrow_left</mat-icon></button>
        <button mat-icon-button (click)="handleNext();$event.stopPropagation();"><mat-icon>keyboard_arrow_right</mat-icon></button>
    </div>
    <mat-date-range-picker #picker>
        <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>{{ 'common.cancel' | translate }}</button>
            <button mat-raised-button color="primary" matDateRangePickerApply>{{ 'common.confirm' | translate }}</button>
          </mat-date-range-picker-actions>
    </mat-date-range-picker>

    <mat-error *ngIf="form.controls.start.hasError('matStartDateInvalid')">{{ 'msg.invalid_start_date' | translate }}</mat-error>
    <mat-error *ngIf="form.controls.end.hasError('matEndDateInvalid')">{{ 'msg.invalid_end_date' | translate }}</mat-error>
    <mat-error *ngIf="form.hasError('max-days')">{{ form.errors['max-days'].msg | translate: { days: form.errors['max-days'].days } }}</mat-error>
</mat-form-field>