<div class="wrapper">
    <ng-container *ngIf="(webtags$ | async).length > 1 && !targetWebtag && !hideWebtag">
        <mat-form-field class="webtag" appearance="fill" floatLabel="always">
            <mat-label>{{ label | translate }} - Webtag</mat-label>
            <mat-select [formControl]="webtag_id">
                <ng-container *ngFor="let webtag of webtags$ | async">
                    <mat-option [ngStyle]="{'display': webtag.visiable ? '' : 'none'}" [value]="webtag.domain">{{ webtag.domain }}</mat-option>
                </ng-container>
            </mat-select>
            <mat-error><error-messages [control]="webtag_id"></error-messages></mat-error>
        </mat-form-field>
    </ng-container>
    <mat-form-field class="workType" appearance="fill" floatLabel="always" >
        <mat-label>{{ label | translate }}</mat-label>
        <mat-select [formControl]="workType">
            <mat-option [value]="null">( Empty )</mat-option>
            <ng-container *ngIf="workTypes$ | async as workTypes">
                <ng-container *ngIf="workTypes.length == 0">
                    <mat-option class="multiple-line">{{ 'common.no-option' | translate }}</mat-option>
                </ng-container>
                <ng-container *ngFor="let workType of workTypes">
                    <mat-option class="multiple-line" [value]="workType">
                        <b>{{ workType.work_type_id }}</b> - 
                        <small class="text-gray-300" *ngIf="workType.comment">{{ workType.comment }}</small>
                    </mat-option>
                </ng-container>
            </ng-container>
        </mat-select>
        <mat-error><error-messages [control]="workType"></error-messages></mat-error>
    </mat-form-field>
</div>