import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageService } from './core/i18n/language.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { AudioService } from './core/services/audio.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        'app.component.scss'
    ],
    encapsulation: ViewEncapsulation.None,
    animations: [
        trigger('myanime', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.3s 0.2s ease-in-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('0.2s', style({ opacity: 0 }))
            ])
        ])
    ],
})
export class AppComponent implements OnInit {

    languageLoading: boolean = true;

    constructor(
        public langService: LanguageService,
        public audioService: AudioService,
    ) {}

    ngOnInit(): void {}

}
