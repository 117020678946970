<!-- Input Templates -->
<ng-container *ngIf="input">

    <ng-container [ngSwitch]="i.type">

        <!-- Text -->
        <ng-container *ngSwitchCase="'text'">

            <ng-container *ngIf="i.htmlInputType !== 'hidden'">
                <label>
                    <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

                    <div class="input-group" [ngClass]="{ 'ng-touched': input.touched, 'ng-invalid': input.invalid, 'disabled': input.disabled }">
                        <span *ngIf="i.icon" class="input-prefix">
                            <mat-icon>{{ i.icon }}</mat-icon>
                        </span>
                        <input [attr.id]="i.name" [name]="i.name" [type]="i.htmlInputType" [formControl]="input" [value]="input.value" [attr.autocomplete]="i.autoCompleteOff ? 'off' : 'on'" class="classic-input" [attr.placeholder]="i.placeholder | translate" [attr.list]="i.datalist ? i.name + '-dl' : undefined" [readonly]="i.readonly">
                        <ng-container *ngIf="i.datalist">
                            <datalist [id]="i.name + '-dl'">
                                <option *ngFor="let item of i.datalist">{{ item }}</option>
                            </datalist>
                        </ng-container>
                        <ng-container *ngIf="templateDef?.inputDef" [ngTemplateOutlet]="templateDef?.inputDef?.template" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                    </div>

                    <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                        <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                    </ng-container>

                    <ng-container *ngIf="input.touched && input?.errors">
                        <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                    </ng-container>
                </label>
            </ng-container>

            <ng-container *ngIf="i.htmlInputType === 'hidden'">
                <input [name]="i.name" matInput type="hidden" [formControl]="input" [value]="input.value" [attr.autocomplete]="i.autoCompleteOff ? 'off' : 'on'" class="classic-input">
            </ng-container>

        </ng-container>

        <!-- Password -->
        <ng-container *ngSwitchCase="'password'">
            <label>
                <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

                <div class="input-group" [ngClass]="{ 'ng-touched': input.touched, 'ng-invalid': input.invalid, 'disabled': input.disabled }">
                    <span *ngIf="i.icon" class="input-prefix">
                        <mat-icon>{{ i.icon }}</mat-icon>
                    </span>
                    <input [name]="i.name" matInput type="password" [formControl]="input" [value]="input.value" [attr.autocomplete]="i.autoCompleteOff ? 'off' : 'on'" class="classic-input"  [readonly]="i.readonly">
                    <ng-container *ngIf="templateDef?.inputDef" [ngTemplateOutlet]="templateDef?.inputDef?.template" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </div>

                <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                    <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>

                <ng-container *ngIf="input.touched && input?.errors">
                    <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </label>
        </ng-container>

        <!-- Number -->
        <ng-container *ngSwitchCase="'number'">
            <label>
                <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

                <div class="input-group" [ngClass]="{ 'ng-touched': input.touched, 'ng-invalid': input.invalid, 'disabled': input.disabled }">
                    <span *ngIf="i.icon" class="input-prefix">
                        <mat-icon>{{ i.icon }}</mat-icon>
                    </span>
                    <input [name]="i.name" matInput type="number" [formControl]="input" [value]="input.value" class="classic-input" [min]="i.min" [max]="i.max" [step]="i.step" [placeholder]="i.placeholder | translate"  [readonly]="i.readonly">
                    <ng-container *ngIf="templateDef?.inputDef" [ngTemplateOutlet]="templateDef?.inputDef?.template" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </div>

                <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                    <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>

                <ng-container *ngIf="input.touched && input?.errors">
                    <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </label>
        </ng-container>

        <!-- Select -->
        <ng-container *ngSwitchCase="'select'">
            <label>
                <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

                <div class="input-group" [ngClass]="{ 'ng-touched': input.touched, 'ng-invalid': input.invalid, 'disabled': input.disabled }">
                    <span *ngIf="i.icon" class="input-prefix">
                        <mat-icon>{{ i.icon }}</mat-icon>
                    </span>
                    <mat-select [formControl]="input" class="classic-input" [attr.name]="i.name"  [disabled]="i.readonly || i.disabled">
                        <mat-option *ngIf="i.enableSearchOption" disabled style="border-bottom: 1px solid rgba(0, 0, 0, 0.15);position: sticky;z-index: 2;top: -8px;opacity: 1;" class="bg-gray-100">
                            <input type="text" matInput #f (keyup)="handleFilterOption(f.value)" autocomplete="off" style="border: none;font-size: 1rem;background-color: inherit;" class="w-full py-2 focus:outline-none" placeholder="Filter...">
                        </mat-option>
                        <mat-option *ngIf="!i.multiple && input.value && !i.disableReset" [value]="null"></mat-option>
                        <mat-option *ngFor="let opt of i.options" [ngStyle]="{'display': opt.hidden ? 'none' : ''}" [value]="opt.value">{{ opt.label | translate }}</mat-option>
                    </mat-select>
                    <ng-container *ngIf="templateDef?.inputDef" [ngTemplateOutlet]="templateDef?.inputDef?.template" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </div>

                <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                    <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>

                <ng-container *ngIf="input.touched && input?.errors">
                    <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </label>
        </ng-container>

        <!-- Textarea -->
        <ng-container *ngSwitchCase="'textarea'">
            <label>
                <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

                <div class="input-group" [ngClass]="{ 'ng-touched': input.touched, 'ng-invalid': input.invalid, 'disabled': input.disabled }">
                    <span *ngIf="i.icon" class="input-prefix">
                        <mat-icon>{{ i.icon }}</mat-icon>
                    </span>
                    <textarea [name]="i.name" matInput [formControl]="input" [value]="input.value" class="classic-input" rows="5"  [readonly]="i.readonly"></textarea>
                    <ng-container *ngIf="templateDef?.inputDef" [ngTemplateOutlet]="templateDef?.inputDef?.template" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </div>

                <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                    <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>

                <ng-container *ngIf="input.touched && input?.errors">
                    <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </label>
        </ng-container>

        <!-- Radio -->
        <ng-container *ngSwitchCase="'radio'">

            <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

            <mat-radio-group class="classic-input radio-input" [formControl]="input" [value]="input.value" [style.--min-width]="i.minWidth"  [disabled]="i.readonly || i.disabled">
                <mat-radio-button *ngFor="let opt of i.options" color="primary" [value]="opt.value">{{ opt.label | translate }}</mat-radio-button>
            </mat-radio-group>

            <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
            </ng-container>

            <ng-container *ngIf="input.touched && input?.errors">
                <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
            </ng-container>

        </ng-container>

        <!-- Checkbox -->
        <ng-container *ngSwitchCase="'checkbox'">

            <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

            <div class="classic-input checkbox-input" [style.--min-width]="i.minWidth">

                <mat-checkbox *ngFor="let opt of i.options" color="primary" [checked]="isChecked(opt)" (change)="handleCheckboxChange(opt.value, $event)"  [disabled]="i.readonly || i.disabled">{{ opt.label | translate }}</mat-checkbox>

            </div>

            <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
            </ng-container>

            <ng-container *ngIf="input.touched && input?.errors">
                <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
            </ng-container>

        </ng-container>

        <!-- File -->
        <ng-container *ngSwitchCase="'file'">
            <label>
                <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

                <div class="classic-input file-input">
                    <button *ngIf="!i.readonly" mat-icon-button type="button" (click)="finput.click()"> <mat-icon>file</mat-icon> </button>
                    <input type="text" [formControl]="input" matInput [value]="input.value" readonly>
                    <ng-container *ngIf="templateDef?.inputDef" [ngTemplateOutlet]="templateDef?.inputDef?.template" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </div>

                <input [name]="i.name" type="file" [multiple]="i.multiple" [accept]="i.accept" readonly style="display: none;" #finput (change)="handleFileSelect(i, finput.files)">

                <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                    <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>

                <ng-container *ngIf="input.touched && input?.errors">
                    <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </label>
        </ng-container>

        <!-- Date Input -->
        <ng-container *ngSwitchCase="'date'">
            <label>
                <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>

                <div class="classic-input date-input">
                    <input matInput readonly [matDatepicker]="picker" [formControl]="input" [min]="i.minDate" [max]="i.maxDate" [attr.autocomplete]="i.autoCompleteOff ? 'off' : 'on'">
                    <mat-datepicker-toggle matSuffix [for]="picker" [disabled]="i.readonly || i.disabled"></mat-datepicker-toggle>
                    <mat-datepicker #picker>
                        <mat-datepicker-actions>
                            <button matDatepickerCancel class="btn btn-outline-secondary mr-2">{{ 'common.cancel' | translate }}</button>
                            <button matDatepickerApply class="btn btn-primary">{{ 'common.confirm' | translate }}</button>
                        </mat-datepicker-actions>
                    </mat-datepicker>

                    <ng-container *ngIf="templateDef?.inputDef" [ngTemplateOutlet]="templateDef?.inputDef?.template" [ngTemplateOutletContext]="TemplateVariables"></ng-container>
                </div>

                <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                    <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>

                <ng-container *ngIf="input.touched && input?.errors">
                    <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </label>
        </ng-container>

        <!-- Time Input -->
        <ng-container *ngSwitchCase="'time'">
            <label>
                <div class="classic-input-label" [ngClass]="{'required': i.required}" [attr.required]="i.required"><ng-container [ngTemplateOutlet]="templateDef?.labelDef?.template || defaultLabelTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></div>
                <div>
                    <mat-select #hour (selectionChange)="handleTimeChange(hour.value, minute.value)" placeholder="Hour" class="classic-input" [attr.name]="i.name" style="width: 100px;" [value]="i.defaultValue?.hour"  [disabled]="i.readonly || i.disabled">
                        <mat-option *ngFor="let opt of i.hourOptions" [value]="opt.value">{{ opt.label | translate }}</mat-option>
                    </mat-select>

                    <span style="display: inline-block;margin: 0px 8px">:</span>

                    <mat-select #minute (selectionChange)="handleTimeChange(hour.value, minute.value)" placeholder="Minute" class="classic-input" [attr.name]="i.name" style="width: 100px;" [value]="i.defaultValue?.minute"  [disabled]="i.readonly || i.disabled">
                        <mat-option *ngFor="let opt of i.minuteOptions" [value]="opt.value">{{ opt.label | translate }}</mat-option>
                    </mat-select>

                    <ng-container *ngIf="templateDef?.inputDef" [ngTemplateOutlet]="templateDef?.inputDef?.template" [ngTemplateOutletContext]="TemplateVariables"></ng-container>

                </div>

                <ng-container *ngIf="(!input.invalid || !input.touched) && (i.hint || templateDef?.hintLabelDef?.template)">
                    <span class="classic-input-hint-label"><ng-container [ngTemplateOutlet]="templateDef?.hintLabelDef?.template || defaultHintTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>

                <ng-container *ngIf="input.touched && input?.errors">
                    <span class="classic-input-err-label" @error-label><ng-container [ngTemplateOutlet]="templateDef?.errLabelDef?.template || defaultErrorTemplate" [ngTemplateOutletContext]="TemplateVariables"></ng-container></span>
                </ng-container>
            </label>
        </ng-container>

        <!-- Form Array -->
        <ng-container *ngSwitchCase="'array'">
            <div class="form-array-wrapper">
                <ng-container *ngFor="let fg of form_arr_inputs;let idx = index"> <!-- this input is form array -->
                    <div [ngClass]="i.wrapper_class" [formGroup]="fg">
                        <ng-container *ngFor="let si of i.inputs">
                            <cfg-form-classic-input [CfgForm]="CfgForm" [form]="fg" [input]="fg.controls[si.name]" [config]="si"></cfg-form-classic-input>
                        </ng-container>
                    </div>
                    <div class="flex items-center">
                        <button mat-icon-button type="button" (click)="handleRemoveFormArrayItem(idx)" [disabled]="i.readonly || i.disabled">
                            <mat-icon>remove_circle_outline</mat-icon>
                        </button>
                    </div>
                </ng-container>
                <div></div>
                <div>
                    <button mat-icon-button type="button" (click)="handleAddFormArrayItem()" [disabled]="i.readonly || i.disabled">
                        <mat-icon>add_circle_outline</mat-icon>
                    </button>
                </div>
            </div>
        </ng-container>

        <!-- Unknow Setting -->
        <ng-container *ngSwitchDefault>
            <div class="unknow-box">
                Unknow input type <b>【{{ i.type }}】</b>.
            </div>
        </ng-container>

    </ng-container>

</ng-container>

<ng-template #defaultLabelTemplate let-config>{{ config.label | translate }}</ng-template>
<ng-template #defaultHintTemplate let-config>{{ config.hint | translate }}</ng-template>
<ng-template #defaultErrorTemplate let-config>{{ input?.errors | CfgFormErrorMessage: config.errorMessages | translate }}</ng-template>
