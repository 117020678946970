import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ResizerDirective } from './resizer.directive';
import { SplitHalfContainerComponent } from './split-half-container.component';

@NgModule({
  declarations: [ResizerDirective, SplitHalfContainerComponent],
  imports: [CommonModule],
  exports: [ResizerDirective, SplitHalfContainerComponent],
})
export class SplitHalfContainerModule { }
