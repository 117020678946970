import { Injectable } from '@angular/core';
import { ApiService } from 'app/core/services/api.service';
import { ApiResponse } from 'app/core/interfaces';
import { BackendHost, BackendPath } from 'app/configs/backend';
import { Account } from 'app/pages/admin/accounts/models';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { Webtag } from 'app/pages/admin/webtags/models';
import { Observable, of } from 'rxjs';
import { FormFile, WorkType, WorkTypesWork } from 'app/modules/forms/models'
import { Site } from 'app/pages/sites/models';

const backend = `${BackendHost}${BackendPath}`;

@Injectable({ providedIn: 'root' })
export class FormUIService {

    readableWebtags$ = this.api.get(`${backend}/webtags`).pipe( 
        map( (res: ApiResponse<Array<Webtag>>) => res.data ),
        shareReplay(1),
        catchError( err => of([] as Array<Webtag>) ),
    );

    readableAccounts$ = this.api.get(`${backend}/accounts`).pipe( 
        map( (res: ApiResponse<Array<Account>>) => res.data ),
        shareReplay(1),
        catchError( err => of([] as Array<Account>) ),
    );

    readableSites$ = this.api.get(`${backend}/sites`).pipe(
        map( (res: ApiResponse<Array<Site>>) => res.data ),
        shareReplay(1),
        catchError( err => of([] as Array<Site>) ),
    );

    constructor(private api: ApiService) { }

    getWorkTypes(offset: number = 0, limit: number = 10, conditions: any = {}): Observable<ApiResponse<WorkType[]>> {
        return this.api.get(`${backend}/forms/work_types`, { total_query: 1, offset, limit, ...conditions});
    }

    getWorkTypeWorks(webtag_id: string, work_type_id: string, offset: number = 0, limit: number = 10, conditions: any = {}): Observable<ApiResponse<WorkTypesWork[]>> {
        return this.api.get(`${backend}/forms/work_types/${webtag_id}/${work_type_id}/works`, { total_query: 1, offset, limit, ...conditions});
    }

    getWebtagOptions(filterFn?: FilterFn<Webtag>): Observable<Array<{ label: string, value: any }>> {
        return this.readableWebtags$.pipe(
            map(
                webtags => filterFn ? webtags.filter(filterFn) : webtags,
            ),
            map( 
                webtags => webtags.map(
                    webtag => ({label: webtag.domain, value: webtag.domain })
                )
            )
        );
    }

    getAccountOptions(filterFn?: FilterFn<Account>): Observable<Array<{ label: string, value: any }>> {
        return this.readableAccounts$.pipe(
            map(
                accounts => filterFn ? accounts.filter(filterFn) : accounts,
            ),
            map(
                account => account.map(
                    account => ({label: `${account.name}(${account.account})`, value: account.account })
                )
            )
        );
    }

    getAccounts(webtag_id: string): Observable<Account[]> {
        return this.api.get(`${backend}/accounts`, { webtag_id }).pipe( 
            map( (res: ApiResponse<Array<Account>>) => res.data ),
            catchError( err => of([] as Array<Account>) ),
        );
    }

    // 刪除表單檔案
    deleteFormFile(file: FormFile): Observable<any> {
        return this.api.delete(`${backend}/forms/files/${file.id}`);
    }

    // 更新工作回報
    updateWorkReport(form_id: string, work_id: string, repo_id: number, data: any): Observable<any> {
        return this.api.patch(`${backend}/forms/eqp_alarm_dispatch_form/${form_id}/works/${work_id}/repos/${repo_id}`, data);
    }

    // 刪除工作回報
    deleteWorkReport(form_id: string, work_id: string, repo_id: number): Observable<any> {
        return this.api.delete(`${backend}/forms/eqp_alarm_dispatch_form/${form_id}/works/${work_id}/repos/${repo_id}`);
    }

}

export type FilterFn<T> = (row: T) => boolean;