<div class="position-relative login-bg" [ngStyle]="{ 'background-image': webtag.login_bg ? 'url('+webtag.login_bg+')' : '' }">

    <div class="flex flex-wrap w-full">
        <div class="w-full md:w-1/2" class="content-box text-center">
            <div class="title" [ngStyle]="{ color: styles.login_title_font_color || '#FFF', fontWeight: styles.login_title_font_weight, textShadow: styles.login_title_text_shadow, fontSize: styles.login_title_font_size }">
                <ng-container *ngIf="webtag.logo">
                    <img src="{{ webtag.logo }}" style="height: 5rem; width: auto;">
                </ng-container>
                <span>{{ webtag.name }}</span>
            </div>
            <h2 class="title" [ngStyle]="{ color: styles.login_title_font_color || '#FFF', fontWeight: styles.login_title_font_weight, textShadow: styles.login_title_text_shadow, fontSize: styles.login_title_font_size }">{{ 'login.pv_system' | translate }}</h2>
        </div>
        <div class="flex w-full md:w-1/2 justify-center md:justify-end">
            <div>
                <form [formGroup]="form" (submit)="handleSubmit()">
                    <div class="login-content" [ngStyle]="{
                        color: styles.login_form_font_color || '#FFF',
                        backgroundColor: styles.login_form_bg_color,
                        borderColor: styles.login_form_border_color,
                        borderWidth: styles.login_form_border_width,
                    }">
                        <div class="text-center" [ngStyle]="{
                            color: styles.login_form_title_font_color || '#FFF',
                            fontSize: styles.login_form_title_font_size || '26px',
                        }" style="font-weight: 600;">{{ 'login.title' | translate | titlecase }}</div>
                        <div class="form-group mb-2">
                            <label>{{ 'login.input.account' | translate | titlecase }}</label>
                            <div class="flex items-center">
                                <mat-icon class="absolute w-8 h-8">person</mat-icon>
                                <input [ngStyle]="{ color: styles.login_form_input_font_color || '#FFF' }" formControlName="account" autocomplete="off" type="text" (keyup.enter)="pwd.focus()" placeholder="" class="form-control pl-8 bg-transparent border-x-0 border-t-0 border-b border-b-blue-white rounded-none">
                            </div>
                            <mat-error><error-messages [control]="form.get('account')"></error-messages></mat-error>
                        </div>
                        <div class="form-group mb-1">
                            <label>{{ 'login.input.password' | translate | titlecase }}</label>
                            <div class="flex items-center">
                                <mat-icon class="absolute w-8 h-8">lock</mat-icon>
                                <input [ngStyle]="{ color: styles.login_form_input_font_color || '#FFF' }" formControlName="password" #pwd autocomplete="off" type="password" placeholder="" class="form-control pl-8 bg-transparent border-x-0 border-t-0 border-b border-b-blue-white rounded-none">
                            </div>
                            <mat-error><error-messages [control]="form.get('password')"></error-messages></mat-error>
                        </div>
                        <div class="mb-4">
                            <mat-checkbox style="--mdc-checkbox-selected-checkmark-color: #FFF;--mdc-theme-text-primary-on-background:#FFF;--mdc-checkbox-unselected-icon-color:#FFF;" [checked]="keep_account_pwd" (change)="keep_account_pwd = $event.checked">{{ 'login.keep_account_pwd' | translate }}</mat-checkbox>
                        </div>
                        <div class="text-center">
                            <button mat-flat-button color="primary" [disabled]="loading || form.invalid" style="display: block;width: 100%;--mdc-filled-button-disabled-container-color:#FFF9;">
                                <app-loading-content [loading]="loading">{{ 'common.btn_submit' | translate | titlecase }}</app-loading-content>
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<button mat-icon-button class="translate-button" (click)="btn.open()"> <mat-icon>translate</mat-icon> </button>
<button mat-icon-button class="translate-button" color="primary" (click)="dialog.open(PWA_Installation, { width: '360px' })" style="right: 72px;" matTooltip="{{ 'pwa.installaction_dialog_tooltip' | translate }}"> <mat-icon>lightbulb</mat-icon> </button>
<change-language-button #btn></change-language-button>
<ng-template #PWA_Installation let-ref="dialogRef">
    <h1 mat-dialog-title>{{ 'pwa.installaction_dialog.title' | translate }}</h1>
    <div mat-dialog-content class="mb-4">
        <div>{{ 'pwa.installaction_dialog.content' | translate }}</div>
        <mat-hint class="text-sm mt-4 block">{{ 'pwa.installaction_dialog.notice' | translate }}</mat-hint>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-stroked-button (click)="pwa.rejectInstallation(); ref.close();">{{ 'pwa.installaction_dialog.action.reject' | translate }}</button>
        <button mat-stroked-button (click)="pwa.promptConfirm(); ref.close();" cdkFocusInitial color="primary">{{ 'pwa.installaction_dialog.action.allow' | translate }}</button>
    </div>
</ng-template>
