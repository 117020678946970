/* eslint-disable @angular-eslint/no-host-metadata-property */

import { ContentChild, Directive, Input, TemplateRef } from '@angular/core';

@Directive({ selector: '[cfg-form-input]', host: { class: 'cfg-form-input' } })
export class CfgFormInputDefDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[cfg-form-label]', host: { class: 'cfg-form-label' } })
export class CfgFormLabelDefDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[cfg-form-error-label]', host: { class: 'cfg-form-error' } })
export class CfgFormErrorLabelDefDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[cfg-form-hint]', host: { class: 'cfg-form-hint' } })
export class CfgFormHintLabelDefDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[cfg-form-before-area]', host: { class: 'cfg-form-before-area' } })
export class CfgFormBeforeAreaDefDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Directive({ selector: '[cfg-form-after-area]', host: { class: 'cfg-form-after-area' } })
export class CfgFormAfterAreaDefDirective
{
    constructor(public template: TemplateRef<any>) { }
}
@Directive({ selector: '[cfg-form-break-line]', host: { class: 'cfg-form-after-area' } })
export class CfgFormBreakLineDefDirective
{
    constructor(public template: TemplateRef<any>) { }
}

/**
 * Define the form filed
 *
 * ex:
 *  <ng-container cfg-form-field="password">
 *      <div *cfg-form-input="let form" let-input="input">
 *          <input [formControl]="input" class="my-class" />
 *      </div>
 *
 *      <div *cfg-form-label="let form" let-config="config">
 *          <mat-icon>person</mat-icon> {{ config.label }}
 *      </div>
 *
 *      <div *cfg-form-label="let form" let-config="config" let-input="input">
 *          <span *ngIf="form.errors?.confirm_pwd"> Please confirm password </span>
 *          <span *ngIf="input.errors?.required"> This field is requred </span>
 *      </div>
 *  </ng-container>
 */
@Directive({ selector: '[cfg-form-field]' })
export class CfgFormFieldDefDirective {

    @Input('cfg-form-field')
    get formInputDef(): string { return this._name; }
    set formInputDef(name: string) { this._setNameInput(name); }

    get name() { return this._name; }
    protected _name: string = '';
    protected _setNameInput(value: string) {
        if (value) {
            this._name = value;
        }
    }

    @ContentChild(CfgFormInputDefDirective) public inputDef!: CfgFormInputDefDirective;
    @ContentChild(CfgFormLabelDefDirective) public labelDef!: CfgFormLabelDefDirective;
    @ContentChild(CfgFormErrorLabelDefDirective) public errLabelDef!: CfgFormErrorLabelDefDirective;
    @ContentChild(CfgFormHintLabelDefDirective) public hintLabelDef!: CfgFormHintLabelDefDirective;
    @ContentChild(CfgFormBeforeAreaDefDirective) public beforeAreaDef!: CfgFormBeforeAreaDefDirective;
    @ContentChild(CfgFormAfterAreaDefDirective) public afterAreaDef!: CfgFormAfterAreaDefDirective;
    @ContentChild(CfgFormBreakLineDefDirective) public breakLineDef!: CfgFormBreakLineDefDirective;

}
