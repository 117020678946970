<mat-select [formControl]="site" [multiple]="multiple" class="form-control">

    <mat-select-trigger>{{ getSelectLabel() }}</mat-select-trigger>

    <input [formControl]="siteFilter" placeholder="{{ 'common.filter' | translate }}..." autocomplete="off" class="w-full dt-filter-input" style="border: none;outline: none;">

    <mat-option *ngIf="!multiple" [value]="null">( Empty )</mat-option>

    <ng-container *ngFor="let site of sites$ | async">
        <mat-option [ngStyle]="{'display': site.visiable ? '' : 'none'}" [value]="site">
            <ng-container *ngIf="id_type == idTypes.UserCode">{{ site.user_site }} <span class="mat-hint">({{ site.name }})</span></ng-container>
            <ng-container *ngIf="id_type == idTypes.OwnerCode">{{ site.owner_site }} <span class="mat-hint">({{ site.name }})</span></ng-container>
            <ng-container *ngIf="id_type == idTypes.SystemCode">{{ site.site }} <span class="mat-hint">({{ site.name }})</span></ng-container>
        </mat-option>
    </ng-container>

</mat-select>
