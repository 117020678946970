import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/services/auth.service';
import { AlertService } from 'app/shared/angular-material/snake-bar-module/services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

    constructor(
        private auth: AuthService,
        private alert: AlertService,
        private i18n: TranslateService,
        private router: Router,
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        let c = route.root;

        while( c.children.length ) {
            c = c.firstChild;
        }

        if( c.data.permission ) {

            const user = this.auth.getUser();

            const pass = user && this.auth.userHasPermission(c.data.permission);

            if( !pass ) {
                // this.i18n.getTranslation('msg.no_permission').pipe( take(1) ).subscribe( label => this.alert.error( label ) );
                this.router.navigate(['/welcome']);
            }

            return pass;
        }

        return true;
    }

}
