import { Injectable, OnDestroy } from '@angular/core';
import { BackendHost, BackendPath } from 'app/configs/backend';
import { BehaviorSubject, Observable } from 'rxjs';

const api = `${BackendHost}${BackendPath}`;

@Injectable({ providedIn: 'root' })
export class PwaService {

    installed: BehaviorSubject<boolean> = new BehaviorSubject(false);
    installed$: Observable<boolean> = this.installed.asObservable();

    // confirmed: BehaviorSubject<boolean> = new BehaviorSubject(true);
    // confirmed$: Observable<boolean> = this.confirmed.asObservable();

    get not_confirmed(): boolean {
        return !!window['deferredPrompt'];
    }

    // PWA mode
    isPwaMode: BehaviorSubject<boolean> = new BehaviorSubject(false);
    isPwaMode$: Observable<boolean> = this.isPwaMode.asObservable();

    constructor() {}

    initAttacheEvents(): Promise<any>
    {
        return new Promise((res, rej) => {
            window.addEventListener('beforeinstallprompt', (event) => {
                // 防止安裝提示欄出現。
                event.preventDefault();

                // 隐藏事件，以便以後再觸發。
                window['deferredPrompt'] = event;
            });
            window.addEventListener('appinstalled', (event) => {
                // 清除 deferredPrompt
                window['deferredPrompt'] = null;
                this.installed.next(true);
            });

            const mode = this.getDisplayMode();
            this.isPwaMode.next( mode == 'standalone' || mode == 'twa' );

            res(true);
        });
    }

    // 彈出確認視窗
    async promptConfirm() {
        if( this.not_confirmed )
        {
            const promptEvent = window['deferredPrompt'];

            // 彈出安裝提示
            promptEvent.prompt();

            // Log the result
            const result = await promptEvent.userChoice;

            // 重置延迟提示变量，因为
            // prompt() 只能调用一次。
            window['deferredPrompt'] = null;
        }
    }

    getDisplayMode(): 'twa' | 'standalone' | 'browser' {
        const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
        if (document.referrer.startsWith('android-app://')) {
            return 'twa';
        } else if (navigator['standalone'] || isStandalone) {
            return 'standalone';
        }
        return 'browser';
    }

    rejectInstallation() {
        // window['deferredPrompt'] = null;
    }
}
