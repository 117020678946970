import { Component, OnInit, OnDestroy, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, RouterOutlet, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { trigger, transition, query, style, animate, group } from '@angular/animations';
import { AuthService } from 'app/core/services/auth.service';
import { filter } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'app/pages/personal/change-password.component';

import { AlertService } from 'app/shared/angular-material/snake-bar-module/services/alert.service';
import { Account } from 'app/pages/login/models';
import { Webtag } from 'app/pages/admin/webtags/models';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'app/core/i18n/language.service';
import { paths, standalone_paths } from '../../../../environments/routes';
import { PwaService } from 'app/core/services/pwa.service';

interface IsActiveMatchOptions {
    matrixParams?: 'exact' | 'subset' | 'ignored'
    queryParams?: 'exact' | 'subset' | 'ignored'
    paths?: 'exact' | 'subset'
    fragment?: 'exact' | 'ignored'
}

export enum AccountRoles {
    SystemAdmin = 0,
    ProductAdmin = 1,
    PlatformAdmin = 2,
    WebtagAdmin = 3,
    NormalUser = 4,
}

export enum AccountRoleLanguages {
    'account.role.system_admin'= 0,
    'account.role.product_admin'= 1,
    'account.role.platform_admin'= 2,
    'account.role.webtag_admin'= 3,
    'account.role.normal_user'= 4,
}

export interface NavLinkItem {
    path?: string | string[];
    icon: string;
    name: string;
    open?: boolean;
    permission?: string;
    hidden?: boolean;
    children?: NavLinkItem[];
    target?: string;
    external?: boolean;
    routerLinkActiveOptions?: IsActiveMatchOptions | {exact: boolean};
}

@Component({
    selector: 'app-sidenav-layout',
    templateUrl: './sidenav-layout.component.html',
    styleUrls: ['./sidenav-layout.component.scss'],
    animations: [
        trigger('routingAnimate', [
            transition('* <=> *', [
                query(':enter', [
                    style({ opacity: 0, transform: 'translateY(-10px)' }),
                    animate('350ms ease-in-out', style({ opacity: 1, transform: 'translateY(0px)' }))
                ]),
            ])
        ])
    ],
    encapsulation: ViewEncapsulation.None,
})
export class SidenavLayoutComponent implements OnInit, OnDestroy, AfterViewInit {

    subscriptions: Subscription[] = [];
    title = 'system.default.title';

    mobileWidth = 768;
    _opened = false;
    get opened(): boolean { return this._opened; }
    set opened(opened: boolean) {
        let fire_event = opened != this.opened;
        this._opened = opened;

        if( fire_event ) {
            const e = new Event('resize');
            setTimeout(() => window.dispatchEvent( e ), 500);
        }
    }
    loading = false;
    fullScreen = false;

    breadcrumbs: string[] = [];
    user: Account;
    webtag: Webtag;

    accountRoles = AccountRoleLanguages;

    langLoading: boolean = false;

    defaultMatchOption: IsActiveMatchOptions | { exact: boolean } = { exact: false };

    paths: NavLinkItem[] = paths;

    year = new Date().getFullYear();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        public dialog: MatDialog,
        private alertService: AlertService,
        private translate: TranslateService,
        private langService: LanguageService,
        public pwa: PwaService,
    ) {
        this.subscriptions.push(
            this.router.events
                .pipe(
                    filter(event => event instanceof NavigationEnd),
                ).subscribe(
                    event => {
                        this.breadcrumbs = this.getBreadcrumbs(this.route.root);
                        this.title = this.getTitle(this.route.root);
                    }
                ),
            this.router.events
                .pipe(
                    filter(event => event instanceof NavigationEnd || event instanceof NavigationStart)
                ).subscribe(
                    event => {
                        if (event instanceof NavigationStart) { this.loading = true; }
                        if (event instanceof NavigationEnd) { this.loading = false; }
                    }
                ),
            this.langService.languageLoading.subscribe(loading => this.langLoading = loading)
        );
    }

    ngOnInit(): void {
        this.subscriptions.push(
            this.authService.loginUser.subscribe(usr => {
                this.user = usr;
                this.updateMenuVisible(this.paths);
            }),
            this.authService.siteWebtag.subscribe(webtag => this.webtag = webtag),
        );
        this.handleLoad();
        // window.addEventListener('resize', this.handleResize.bind(this));
        document.addEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
        this.fullScreen = !!document.fullscreenElement;
    }

    get Language(): string {
        return this.authService.getLanguage();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
        window.removeEventListener('resize', this.handleResize);
        document.addEventListener('fullscreenchange', this.handleFullScreenChange.bind(this));
    }

    ngAfterViewInit(): void {
        document.querySelectorAll('.sidenav .navigators .mat-list-item')
            .forEach((el: HTMLElement) => {
                const fm: HTMLElement = el.querySelector('.sidebar-float-menu');
                const open = () => {
                    if ( !fm ){ return; }

                    const menuWidth = 56;
                    const offsetTop = el.offsetTop;
                    const offsetHeight = fm.offsetHeight;
                    const scrollTop = el.parentElement.scrollTop;

                    if ( (offsetTop - scrollTop) + offsetHeight > window.innerHeight ) {
                        fm.style.transform = `translate3d(${menuWidth}px, ${ offsetTop - scrollTop - offsetHeight + 40 }px, 0px)`;
                        fm.classList.add('reverse');
                    } else {
                        fm.style.transform = `translate3d(${menuWidth}px, ${ offsetTop - scrollTop }px, 0px)`;
                        fm.classList.remove('reverse');
                    }
                };

                el.addEventListener('mouseover', open);
                el.addEventListener('focus', open);
            });
    }

    handleLoad() {
        this.opened = !(window.innerWidth < this.mobileWidth);
    }

    handleResize() {
        // if (window.innerWidth < this.mobileWidth && this.opened == true) {
        //     this.opened = false;
        // }
    }

    handleToggleOpened() {
        this.opened = !this.opened;
    }

    private getBreadcrumbs(rootRoute: ActivatedRoute): string[] {
        this.breadcrumbs = [];
        let r = rootRoute;
        const breadcrumbs: string[] = [];
        while (r) {
            if (r.snapshot.data.breadcrumbs && Array.isArray(r.snapshot.data.breadcrumbs)) {
                const crumbs = r.snapshot.data.breadcrumbs;
                crumbs.forEach((str: string) => {
                    if (str.indexOf(':') === 0) {
                        const variable = str.substring(1);
                        if (r.snapshot.params[variable]) {
                            breadcrumbs.push(r.snapshot.params[variable]);
                        } else {
                            breadcrumbs.push(str);
                        }
                    } else {
                        breadcrumbs.push(str);
                    }
                });
            }
            r = r.firstChild;
        }

        return ['Home'].concat(breadcrumbs);
    }

    getTitle(rootRoute: ActivatedRoute): string {
        let route = rootRoute;
        while (route.firstChild) {
            route = route.firstChild;
        }

        let title = route.snapshot.data.title as string || '';
        if( title.indexOf(':') >= 0 ) {
            title = title.split(' ').map((item: string) => {
                if( item.indexOf(':') == 0 ) {
                    let r = rootRoute;
                    let label = '';
                    const variable = item.substr(1);
                    while (r) {
                        if( r.snapshot.params[variable] ){
                            label = r.snapshot.params[variable];
                            break;
                        }

                        r = r.firstChild;
                    }
                    if( !label ) { label = variable }

                    return label;
                }
            }).join(' ');
        }

        return title || this.webtag?.name;
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData;
    }

    toggleNestedMenu(linksIdx: number) {
        this.paths[linksIdx].open = !this.paths[linksIdx].open;
    }

    handleChangePassword() {
        this.dialog.open(ChangePasswordComponent, { data: {}, width: '480px', maxWidth: '480px' });
    }

    handleFullScreenChange() {
        this.fullScreen = !!document.fullscreenElement;
    }

    enableFullScreen() {
        const elem = document.documentElement;
        const methodToBeInvoked = elem[`requestFullscreen`] || elem[`webkitRequestFullScreen`] || elem[`mozRequestFullscreen`] || elem[`msRequestFullscreen`];
        if (methodToBeInvoked) {
            methodToBeInvoked.call(elem);
        } else {
            this.alertService.warning(`Your device does not support full screen mode.`);
        }
    }

    exitFullScreen() {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document[`msExitFullscreen`]) {
            document[`msExitFullscreen`]();
        } else if (document[`mozCancelFullScreen`]) {
            document[`mozCancelFullScreen`]();
        } else if (document[`webkitExitFullscreen`]) {
            document[`webkitExitFullscreen`]();
        }
    }

    updateMenuVisible(menu: NavLinkItem[]): void {
        menu.forEach(link => {
            if( link.children ) {
                this.updateMenuVisible(link.children);
                link.hidden = !this.childrenHasPermission(link);
            } else {
                link.hidden = !this.hasPermission(link);
            }
        });
    }

    hasPermission(navlink: NavLinkItem): boolean {
        // 不管權限
        if( !navlink.permission ){ return true; }
        // 使用者為SuperUser
        if( this.authService.isPlatformAdmin() ) { return true; }
        // 無menu_group者代表其無任何權限
        if( !this.user.menu_group ) { return false; }

        const permission = this.user.menu_group.modules.find(m => m.module_id == navlink.permission);
        // 無設定
        if( !permission ) { return false; }

        const   C = permission.create,
                R = permission.read,
                U = permission.update,
                D = permission.delete;
        // 無任何權限
        if( (C || R || U || D) == false ) { return false; }

        return true;
    }

    childrenHasPermission(navlink: NavLinkItem): boolean {
        if( this.user.role <= AccountRoles.PlatformAdmin ) {
            return true;
        }
        if( navlink.children ) {
            return navlink.children.filter( link => !link.hidden ).length > 0;
        } else {
            return navlink.hidden;
        }
    }

}
