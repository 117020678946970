import { Pipe, PipeTransform } from '@angular/core';

const unitSize = 1000;

@Pipe({ name: 'filesize' })
export class FileSizePipe implements PipeTransform {
    transform(size: number, target: string = null) {
        const units = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        let unit = 0;

        while ( size / unitSize >= 1 ) {
            if ( (target && units[unit] === target.toUpperCase()) || unit >= units.length - 1 ){ break; }

            size = (size / unitSize);
            unit++;
        }

        return (size).toFixed(2) + units[unit];
    }
}
