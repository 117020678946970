import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadingStrategy, NoPreloading } from '@angular/router';

/* Guards */
import { AuthGuard } from './core/guards/auth.guard';
import { UnauthGuard } from './core/guards/unauth.guard';
import { PermissionGuard } from './core/guards/permission.guard';

/* Components */
import { Status404Component } from './shared/HttpStatusComponents/Status404Component.component';
import { SidenavLayoutComponent } from './shared/layouts/sidenav-layout/sidenav-layout.component';
import { LoginComponent } from 'app/pages/login/components/login/login.component';
import { LogoutComponent } from 'app/pages/login/components/logout/logout.component';
import { EmptyPageComponent } from 'app/shared/HttpStatusComponents/empty-page.component';

const routes: Routes = [
    { path: '', pathMatch: 'full', redirectTo: '/login' },
    { path: 'login', component: LoginComponent, canActivate: [ UnauthGuard ] },
    { path: 'logout', component: LogoutComponent, canActivate: [ AuthGuard ] },
    { path: '', canActivate: [ AuthGuard, PermissionGuard ], component: SidenavLayoutComponent, pathMatch: 'prefix',
      children: [
        { path: 'welcome', component: EmptyPageComponent, data: { breadcrumbs: ['Welcome'], title: 'Welcome' } },
        { path: 'quick-menu', loadChildren: () => import('./pages/quick-menu/quick-menu.module').then(m => m.QuickMenuModule), data: { breadcrumbs: ['navigation.quick_menu'] } },
        { path: 'personal', loadChildren: () => import('./pages/personal/personal.module').then(m => m.PersonalModule), data: { breadcrumbs: ['personal'] } },
        { path: 'dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumbs: ['modules.dashboard'] } },
        { path: 'accounts', loadChildren: () => import('./pages/admin/accounts/accounts.module').then(m => m.AccountsModule), data: { breadcrumbs: ['navigation.system', 'modules.account_manage'] } },
        { path: 'menu_groups', loadChildren: () => import('./pages/admin/menu_groups/groups.module').then(m => m.MenuGroupsModule), data: { breadcrumbs: ['navigation.system', 'modules.menu_group_manage'] } },
        { path: 'site_groups', loadChildren: () => import('./pages/admin/site_groups/groups.module').then(m => m.SiteGroupsModule), data: { breadcrumbs: ['navigation.system', 'modules.site_group_manage'] } },
        { path: 'webtags', loadChildren: () => import('./pages/admin/webtags/webtags.module').then(m => m.WebtagsModule), data: { breadcrumbs: ['navigation.system', 'modules.webtag_manage'] } },
        { path: 'equipments', loadChildren: () => import('./pages/equipments/equipments.module').then(m => m.EquipmentsModule), data: { breadcrumbs: ['modules.equipment_manage'] } },
        { path: 'sites', loadChildren: () => import('./pages/sites/sites.module').then(m => m.SitesModule), data: { breadcrumbs: ['modules.site_list'] } },
        { path: 'statistics', loadChildren: () => import('./pages/statistics/statistics.module').then(m => m.StatisticsModule), data: { breadcrumbs: [] } },
        { path: 'forms', loadChildren: () => import('./pages/forms/forms.module').then(m => m.FormsModule), data: { breadcrumbs: ['navigation.forms.manage'] } },
        { path: 'service_logs', loadChildren: () => import('./pages/service-logs/service-logs.module').then(m => m.ServiceLogsModule), data: { breadcrumbs: ['navigation.forms.manage', 'navigation.service_summary'] } },
        { path: 'dc_output_analysis', loadChildren: () => import('./pages/dc-output-analysis/dc-output-analysis.module').then(m => m.DcOutputAnalysisModule), data: { breadcrumbs: ['navigation.dc_output_analysis'] } },
        { path: 'equipment_analysis', loadChildren: () => import('./pages/equipment-analysis/equipment-analysis.module').then(m => m.EquipmentAnalysisModule), data: { breadcrumbs: ['navigation.equipment_analysis'] } },
        { path: 'equipment_analysis_v2', loadChildren: () => import('./pages/equipment-analysis-v2/equipment-analysis-v2.module').then(m => m.EquipmentAnalysisV2Module), data: { breadcrumbs: ['navigation.equipment_analysis_v2'] } },
        { path: 'standalone', loadChildren: () => import('./pages/standalone/standalone.module').then(m => m.StandaloneModule), data: { breadcrumbs: [] } },
        { path: 'modbus_config', loadChildren: () => import('./pages/admin/mobus-config/mobus-config.module').then(m => m.MobusConfigModule), data: { breadcrumbs: ['navigation.modbus_config'] } },
        { path: 'ipvboxes', loadChildren: () => import('./pages/ipvboxes/ipvboxes.module').then(m => m.IpvboxesModule), data: { breadcrumbs: ['navigation.ipvboxes'] } },
        { path: 'ipvbox_firmware', loadChildren: () => import('./pages/ipvbox-firmware/ipvbox-firmware.module').then(m => m.IpvboxFirmwareModule), data: { breadcrumbs: ['navigation.ipvbox_firmware'] } },
        { path: 'system', data: { breadcrumbs: ['navigation.system'] }, children: [
            { path: 'languages', loadChildren: () => import('./pages/admin/languages/languages.module').then(m => m.LanguagesModule), data: { breadcrumbs: ['modules.language_manage'] } },
            { path: 'sys_configs', loadChildren: () => import('./pages/admin/sys-config/sys-config.module').then(m => m.SysConfigModule), data: { breadcrumbs: ['navigation.sys_config'] } },
            { path: 'api_keys', loadChildren: () => import('./pages/admin/api-keys/api-keys.module').then(m => m.ApiKeysModule), data: { breadcrumbs: ['modules.api_key_manage'] } },
          ]
        },
        { path: 'ipvbox_config', loadChildren: () => import('./pages/ipvbox-config/ipvbox-config.module').then(m => m.IpvboxConfigModule), data: { breadcrumbs: ['navigation.ipvbox-config'] } },
    ]},
    { path: 'monitors', loadChildren: () => import('./pages/monitors/monitors.module').then(m => m.MonitorsModule), data: { breadcrumbs: [] } },
    { path: '404', component: Status404Component },
    { path: '**', component: Status404Component },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
