import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { AuthService } from 'app/core/services/auth.service';
import { FormsSummary } from 'app/modules/forms/models';
import { Account } from 'app/pages/login/models';
import { Subscription } from 'rxjs';

const unitSize = 1000;

@Pipe({ name: 'user_can_delete_form', pure: true })
export class UserCanDeleteFormPipe implements PipeTransform, OnDestroy {

    private user: Account

    private subscriptions: Subscription[] = [];

    constructor(private authService: AuthService) {
        this.subscriptions.push(
            this.authService.loginUser.subscribe(user => this.user = user)
        );
    }

    ngOnDestroy() 
    {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    transform(form: FormsSummary): boolean 
    {
        if( !this.user ) return false;

        return this.user.id == form.creater_sn || this.user.isPlatformAdmin || (this.user.webtag_id == form.webtag_id && this.user.isWebtagAdmin);   
    }
}
