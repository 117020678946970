import { NavLinkItem } from '../app/shared/layouts/sidenav-layout/sidenav-layout.component';

// 模式
export const mode: 'standalone' | 'cloud' | any = 'cloud';

// 單機版
export const standalone_paths: NavLinkItem[] = [
    {
        path: '/dashboard',
        icon: 'dashboard',
        name: 'navigation.dashboard',
    },
    {
        path: '/dashboard/analyzeboard-v3',
        icon: 'dashboard',
        name: 'navigation.analyzeboard-v3',
        permission: 'analyzeboard-v3',
    },
    {
        icon: 'offline_bolt',
        name: 'navigation.monitoring',
        open: false,
        children: [
            { icon: '', path: '/sites',              name: 'navigation.sites',       permission: 'site_list',   },
            { icon: '', path: '/statistics/pr_list', name: 'navigation.pr_list',     permission: 'pr_list',     },
        ],
    },
    {
        icon: 'offline_bolt',
        name: 'navigation.analysis',
        open: false,
        children: [
            // 每日周月發電分析
            { icon: '', path: '/statistics/site_generation_analysis', name: 'navigation.site_generation_analysis', permission: 'site_generation_analysis',  },
            // 即時發電
            { icon: '', path: '/standalone/site-realpv', name: 'navigation.site-realpv', permission: 'site_realpv',  },
            // 歷史發電資料
            { icon: '', path: '/standalone/historypv-data', name: 'navigation.historypv-data', permission: 'site_historypv',  },
            // 歷史發電圖表
            { icon: '', path: '/standalone/historypv-chart', name: 'navigation.historypv-chart', permission: 'site_historypv',  },
            // 設備基本資訊
            { icon: '', path: '/standalone/equipments', name: 'navigation.equipments', permission: 'equipment_list',  },
            // 跨電廠發電曲線分析
            { icon: '', path: '/statistics/history_pv', name: 'navigation.history_pv', permission: 'history_pv',  },
            // 各Inverter發電分析
            { icon: '', path: '/statistics/inverter_generation_analysis', name: 'navigation.inverter_generation_analysis', permission: 'inverter_generation_analysis',  },
            // 案場比對分析
            { icon: '', path: '/statistics/site_compare_analysis', name: 'navigation.site_compare_analysis', permission: 'site_compare_analysis',  },
            // 日照值分析
            { icon: '', path: '/statistics/sunshine_analysis', name: 'navigation.sunshine_analysis', permission: 'sunshine_analysis',  },
            // PR值分析
            { icon: '', path: '/statistics/site_pr_analysis', name: 'navigation.site_pr_analysis', permission: 'site_pr_analysis',  },
            // 設備異常分析
            { icon: '', path: '/statistics/equipment_alarms', name: 'navigation.equipment_alarm', permission: 'eqp_alarms',  },
            // 台電能旬報表
            { icon: '', path: '/statistics/taipower_export', name: 'navigation.taipower_export', permission: 'taipower_export',  },
            // 售電分析
            { icon: '', path: '/statistics/site_power_sale_analysis', name: 'navigation.site_power_sale_analysis', permission: 'site_power_sale_analysis',  },
        ],
    },
    {
        icon: 'lock',
        name: 'navigation.system',
        open: false,
        children: [
            { name: 'navigation.accounts', icon: '', path: '/accounts', permission: 'account_manage',           },
            { name: 'navigation.menu_grouop', icon: '', path: '/menu_groups', permission: 'menu_group_manage',  },
            { name: 'navigation.site_grouop', icon: '', path: '/site_groups', permission: 'site_group_manage',  },
            { name: 'navigation.site_config', icon: '', path: '/sites/site_config', permission: 'site_config',  },
            { name: 'navigation.webtags', icon: '', path: '/webtags', permission: 'webtag_manage',              },
            { name: 'navigation.languages', icon: '', path: '/system/languages', permission: 'language_manage', },
            { name: 'navigation.ipvbox_config',         icon: '', path: '/ipvbox_config',               permission: 'ipvbox_config'     },
            { name: 'navigation.ipvboxes',              icon: '', path: '/ipvboxes',                    permission: 'ipvbox_control'    },
            { name: 'navigation.ipvbox_firmware',       icon: '', path: '/ipvbox_firmware',             permission: 'ipvbox_firmware'   },
            { name: 'navigation.modbus_config.editor',  icon: '', path: '/modbus_config/modbus_editor', permission: 'modbus_config'     },
            { name: 'navigation.modbus_config.address', icon: '', path: '/modbus_config/addresses',     permission: 'modbus_config'     },
            { name: 'navigation.modbus_config.alarms',  icon: '', path: '/modbus_config/alarms',        permission: 'modbus_config'     },
        ]
    },
];

// 雲端版
export const full_paths: NavLinkItem[] = [
    {
        icon: 'dashboard',
        name: 'navigation.dashboard',
        children: [
            { icon: '', path: '/dashboard',  name: 'navigation.dashboard.v1', permission: 'dashboard.v1', routerLinkActiveOptions: { exact: true } },
            { icon: '', path: '/dashboard/v2',  name: 'navigation.dashboard.v2', permission: 'dashboard.v2' },
            { icon: '', path: 'https://nkzone.ipvita.biz/monitors/nksp/MjGSDMmn_XmK5HNBp4yb2Q',  name: 'navigation.nkzone.dashboard', permission: 'nkzone_monitor_external_link', target: '_blank', external: true },
            { icon: '', path: '/dashboard/site_monitoring',  name: 'navigation.dashboard.site_monitoring', permission: 'dashboard.site_monitoring' },
            { icon: '', path: '/dashboard/site_monitoring_v2',  name: 'navigation.dashboard.site_monitoring_v2', permission: 'dashboard.site_monitoring_v2' },
        ]
    },
    {
        path: '/dashboard/analyzeboard',
        icon: 'dashboard',
        name: 'navigation.analyzeboard',
        permission: 'analyzeboard',
    },
    {
        path: '/dashboard/analyzeboard-v2',
        icon: 'dashboard',
        name: 'navigation.analyzeboard-v2',
        permission: 'analyzeboard-v2',
    },
    {
        path: '/dashboard/analyzeboard-v3',
        icon: 'dashboard',
        name: 'navigation.analyzeboard-v3',
        permission: 'analyzeboard-v3',
    },
    {
        icon: 'offline_bolt',
        name: 'navigation.monitoring',
        open: false,
        children: [
            { icon: '', path: '/sites',                     name: 'navigation.sites',           permission: 'site_list',        },
            { icon: '', path: '/statistics/pr_list',        name: 'navigation.pr_list',         permission: 'pr_list',          },
            { icon: '', path: '/statistics/site_pr_analysis', name: 'navigation.site_pr_analysis', permission: 'site_pr_analysis',  },
            { icon: '', path: '/statistics/chenya_scada',   name: 'navigation.chenya_scada',    permission: 'chenya_scada',     },
            { icon: '', path: '/statistics/scada_chart',    name: 'navigation.scada_chart',     permission: 'scada_chart',      },
            { icon: '', path: '/statistics/scada_points',   name: 'navigation.scada_points',    permission: 'chenya_scada',     },
            { icon: '', path: '/statistics/chenya_site_summary_report', name: 'navigation.chenya_site_summary_report', permission: 'chenya_site_summary_report',  },
            { icon: '', path: '/statistics/chenya_site_monthly_summary_report', name: 'navigation.chenya_site_monthly_summary_report', permission: 'chenya_site_monthly_summary_report',  },
            { icon: '', path: '/statistics/chenya_site_yearly_summary_report', name: 'navigation.chenya_site_yearly_summary_report', permission: 'chenya_site_yearly_summary_report',  },
        ],
    },
    {
        icon: 'offline_bolt',
        name: 'navigation.analysis',
        open: false,
        children: [
            { icon: '', path: '/statistics/site_generation_analysis', name: 'navigation.site_generation_analysis', permission: 'site_generation_analysis',  },
            { icon: '', path: '/standalone/site-realpv', name: 'navigation.site-realpv', permission: 'site_realpv',  },
            { icon: '', path: '/standalone/historypv-chart', name: 'navigation.historypv-chart', permission: 'site_historypv',  },
            { icon: '', path: '/statistics/history_pv', name: 'navigation.history_pv', permission: 'history_pv',  },
            { icon: '', path: '/statistics/equipment_alarms', name: 'navigation.equipment_alarm', permission: 'eqp_alarms',  },
            { icon: '', path: '/statistics/equipment_alarm_summary', name: 'navigation.equipment_alarm_summary', permission: 'eqp_alarms',  },
            { icon: '', path: '/dc_output_analysis', name: 'navigation.dc_output_analysis', permission: 'dc_output_analysis',  },
            { icon: '', path: '/equipment_analysis', name: 'navigation.equipment_analysis', permission: 'equipment_analysis',  },
            { icon: '', path: '/equipment_analysis_v2', name: 'navigation.equipment_analysis_v2', permission: 'equipment_analysis_v2',  },
            { icon: '', path: '/statistics/inverter_eff', name: 'navigation.inverter_eff_analysis', permission: 'inverter_eff_analysis',  },
            { icon: '', path: '/statistics/site_compare_analysis', name: 'navigation.site_compare_analysis', permission: 'site_compare_analysis',  },
            { icon: '', path: '/statistics/site_power_sale_analysis', name: 'navigation.site_power_sale_analysis', permission: 'site_power_sale_analysis',  },
            { icon: '', path: '/statistics/inverter_generation_analysis', name: 'navigation.inverter_generation_analysis', permission: 'inverter_generation_analysis',  },
            { icon: '', path: '/statistics/sunshine_analysis', name: 'navigation.sunshine_analysis', permission: 'sunshine_analysis',  },
            { icon: '', path: '/statistics/anemometer_analysis', name: 'navigation.anemometer_analysis', permission: 'anemometer_analysis',  },
            { icon: '', path: '/statistics/chenya_weather_analysis', name: 'navigation.chenya_weather_analysis', permission: 'chenya_weather_analysis',  },
            { icon: '', path: '/statistics/taipower_export', name: 'navigation.taipower_export', permission: 'taipower_export',  },
            { icon: '', path: '/statistics/monthly_summary', name: 'navigation.monthly_summary', permission: 'monthly_summary',  },
            { icon: '', path: '/statistics/trand_chart', name: 'navigation.trand_chart', permission: 'trand-charts',  },
        ],
    },
    {
        icon: 'feed',
        name: 'navigation.forms.manage',
        open: false,
        children: [
            { icon: '', path: '/service_logs', name: 'navigation.service_summary', permission: 'service_log_report', routerLinkActiveOptions: { exact: true } },
            { icon: '', path: '/forms/all_forms', name: 'navigation.forms.summary', permission: 'forms_functions', routerLinkActiveOptions: { exact: true } },
            { icon: '', path: '/forms/works', name: 'navigation.forms.work_summary', permission: 'forms_functions', routerLinkActiveOptions: { exact: true } },
        ]
    },
    {
        icon: 'lock',
        name: 'navigation.system',
        open: false,
        children: [
            { name: 'navigation.accounts',              icon: '', path: '/accounts',                    permission: 'account_manage'    },
            { name: 'navigation.menu_grouop',           icon: '', path: '/menu_groups',                 permission: 'menu_group_manage' },
            { name: 'navigation.site_grouop',           icon: '', path: '/site_groups',                 permission: 'site_group_manage' },
            { name: 'navigation.site_config',           icon: '', path: '/sites/site_config',           permission: 'site_config',      },
            { name: 'navigation.webtags',               icon: '', path: '/webtags',                     permission: 'webtag_manage'     },
            { name: 'navigation.languages',             icon: '', path: '/system/languages',            permission: 'language_manage'   },
            { name: 'navigation.ipvbox_config',         icon: '', path: '/ipvbox_config',               permission: 'ipvbox_config'     },
            { name: 'navigation.ipvboxes',              icon: '', path: '/ipvboxes',                    permission: 'ipvbox_control'    },
            { name: 'navigation.ipvbox_firmware',       icon: '', path: '/ipvbox_firmware',             permission: 'ipvbox_firmware'   },
            { name: 'navigation.modbus_config.editor',  icon: '', path: '/modbus_config/modbus_editor', permission: 'modbus_config'     },
            { name: 'navigation.modbus_config.address', icon: '', path: '/modbus_config/addresses',     permission: 'modbus_config'     },
            { name: 'navigation.modbus_config.alarms',  icon: '', path: '/modbus_config/alarms',        permission: 'modbus_config'     },
            { name: 'navigation.sys_configs',           icon: '', path: '/system/sys_configs',          permission: 'sys_config'        },
            { name: 'navigation.api_keys',              icon: '', path: '/system/api_keys',             permission: 'api_keys_manage'   },
        ]
    },
];


export const paths = mode == 'standalone' ? standalone_paths : full_paths;
