import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { Component, ContentChild, HostBinding, Inject, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CfgForm, FormInputInterface, FormInputStyles, FORM_DEFAULT_STYLE } from '..';
import { CfgFormFieldDefDirective } from '../directives/cfg-form-input-template.directive';

@Component({
	selector: '[cfg-input]',
	templateUrl: './cfg-input.component.html',
	styleUrls: ['./cfg-input.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CfgInputComponent implements OnInit {

	@Input('cfg-input') CfgForm!: CfgForm;

	@Input('name') name!: string;

    @ContentChild(CfgFormFieldDefDirective) fieldDef!: CfgFormFieldDefDirective;

    @HostBinding('class') get getClassName() {
        const vars: any = {
            'cfg-input': true,
            'mobile-style': this.CfgForm.style === 'mobile',
        };
        return Object.keys(vars).map( key => vars[key] ? key : '' ).filter( cls => !!cls ).join(' ');
    }

	style: FormInputStyles = 'classic';

	inited: boolean = false;

	config: FormInputInterface;

	form: UntypedFormGroup;

	control: UntypedFormControl;

    private subscriptions: Subscription[] = [];

	constructor(
		@Inject(FORM_DEFAULT_STYLE) private defaultStyle: FormInputStyles,
	) { }

	ngOnInit()
	{
		if( this.CfgForm )
		{
			this.form = this.CfgForm.form;
			this.control = this.CfgForm.form.get(this.name) as UntypedFormControl;
			this.style = this.CfgForm.style || this.defaultStyle;
			this.config = this.CfgForm.inputs.find( cfg => cfg.name === this.name );
		}
		this.inited = true;
	}

}
