<div class="sidenav-wrapper" [ngClass]="{'open': opened}">
    <div class="sidenav">
        <mat-nav-list style="padding-top: 0px;background-color: rgba(0, 0, 0, 0.1);">
            <mat-list-item (click)="handleToggleOpened()" style="height: 64px;">
                <!-- <img *ngIf="opened" mat-list-icon src="../../../../assets/images/chengya.png" style="max-height: 32px;width: auto;border-radius: 0px;transform: scale(1.5)"> -->
                <p mat-line style="color: #FFF;font-weight: 900;font-size: 19px;">{{ webtag.name }}</p>
                <mat-icon>{{ opened ? 'close' : 'menu' }}</mat-icon>
            </mat-list-item>
        </mat-nav-list>
        <mat-nav-list style="padding-top: 0px;" class="navigators">
            <ng-container *ngFor="let link of paths; let i = index;">
                <ng-container *ngIf="!link.children && !link.hidden">
                    <a  *ngIf="!link.external"
                        mat-list-item
                        [routerLink]="link.path"
                        routerLinkActive="active"
                        [attr.target]="link.target || '_self'"
                        [routerLinkActiveOptions]="link.routerLinkActiveOptions || defaultMatchOption">
                        <p mat-line >{{ link.name | translate }}</p>
                        <mat-icon>{{ link.icon }}</mat-icon>

                        <ul class="sidebar-float-menu list-group">
                            <li class="list-group-item single-node">
                                <a [routerLink]="link.path"
                                    routerLinkActive="active"
                                    [attr.target]="link.target || '_self'"
                                   [routerLinkActiveOptions]="link.routerLinkActiveOptions || defaultMatchOption">{{ link.name  | translate }}</a>
                            </li>
                        </ul>
                    </a>
                    <a  *ngIf="link.external"
                        mat-list-item
                        [attr.href]="link.path"
                        routerLinkActive="active"
                        [attr.target]="link.target || '_self'">
                        <p mat-line >{{ link.name | translate }}</p>
                        <mat-icon>{{ link.icon }}</mat-icon>

                        <ul class="sidebar-float-menu list-group">
                            <li class="list-group-item single-node">
                                <a [attr.href]="link.path"
                                    [attr.target]="link.target || '_self'">
                                    {{ link.name  | translate }}
                                </a>
                            </li>
                        </ul>
                    </a>
                </ng-container>
                <div class="nested-menu" *ngIf="link.children && link.children.length && !link.hidden">
                    <a mat-list-item (click)="toggleNestedMenu(i)">
                        <p mat-line [attr.title]="link.name | translate">{{ link.name | translate }}</p>
                        <mat-icon>{{ link.icon }}</mat-icon>
                        <ul class="sidebar-float-menu list-group">
                            <ng-container *ngIf="link.children">
                                <li class="list-group-item list-group-title" [attr.title]="link.name | translate">
                                    <b>{{ link.name | translate }}</b>
                                </li>
                                <ng-container *ngFor="let subLink of link.children">
                                    <li *ngIf="!subLink.hidden" class="list-group-item" [attr.title]="subLink.name | translate">
                                        <a  *ngIf="!subLink.external"
                                            [routerLink]="subLink.path"
                                            routerLinkActive="active"
                                            [attr.target]="subLink.target || '_self'"
                                           [routerLinkActiveOptions]="subLink.routerLinkActiveOptions || defaultMatchOption">
                                           {{ subLink.name | translate }}
                                        </a>
                                        <a  *ngIf="subLink.external"
                                            [attr.href]="subLink.path"
                                            [attr.target]="subLink.target || '_self'">
                                            {{ subLink.name | translate }}
                                        </a>
                                    </li>
                                </ng-container>
                            </ng-container>
                        </ul>
                    </a>
                    <div class="sub-menu" [ngClass]="{ 'open': link.open }">
                        <mat-nav-list>
                            <ng-container *ngFor="let subLink of link.children">
                                <ng-container *ngIf="!subLink.hidden">
                                    <a  *ngIf="!subLink.external"
                                        [attr.title]="subLink.name | translate"
                                        mat-list-item
                                        [routerLink]="subLink.path"
                                        routerLinkActive="active"
                                        [attr.target]="subLink.target || '_self'"
                                        [routerLinkActiveOptions]="subLink.routerLinkActiveOptions || defaultMatchOption">
                                        <p mat-line >{{ subLink.name | translate }}</p>
                                        <mat-icon>{{ subLink.icon }}</mat-icon>
                                    </a>
                                    <a  *ngIf="subLink.external"
                                        [attr.title]="subLink.name | translate"
                                        mat-list-item
                                        [attr.href]="subLink.path"
                                        [attr.target]="subLink.target || '_self'">
                                        <p mat-line >{{ subLink.name | translate }}</p>
                                        <mat-icon>{{ subLink.icon }}</mat-icon>
                                    </a>
                                </ng-container>
                            </ng-container>
                        </mat-nav-list>
                    </div>
                </div>
            </ng-container>
        </mat-nav-list>
        <div style="flex: 1 0 auto;"></div>
        <mat-list style="padding-top: 0px;">
            <mat-divider style="border-top-color: rgba(255, 255, 255, 0.2);"></mat-divider>
            <mat-list-item class="copyright">
                <div mat-line>&copy; {{ year }} {{ webtag.name }}</div>
                <div mat-line>All Rights Reserved.</div>
                <mat-icon *ngIf="!opened" matTooltip="&copy; {{ year }} {{ webtag.name }}; All Rights Reserved.">copyright</mat-icon>
            </mat-list-item>
        </mat-list>
    </div>
    <main class="page-wrapper">
        <div>
            <div class="page-header">
                <div class="page-title-wrapper">
                    <p class="page-title">{{ title | translate }}</p>
                    <ul class="breadcrumb" fxHide.xs>
                        <li *ngFor="let item of breadcrumbs">{{ item | translate }}</li>
                    </ul>
                </div>
                <div style="flex: 1 1 auto;"></div>
                <button mat-icon-button class="mr-2" *ngIf="pwa.not_confirmed" color="primary" matTooltip="{{ 'pwa.installaction_dialog_tooltip' | translate }}" (click)="dialog.open(PWA_Installation, { width: '360px' })"> <mat-icon color="primary">lightbulb</mat-icon> </button>
                <span class="md:hidden">
                    <a [routerLink]="['/quick-menu']" mat-icon-button [matTooltip]="'navigation.quick_menu' | translate"> <mat-icon>apps</mat-icon> </a>
                </span>
                <button mat-icon-button [matMenuTriggerFor]="profileMenu">
                    <mat-icon>person_outline</mat-icon>
                </button>
                <mat-menu #profileMenu="matMenu">
                    <div style="padding: 16px 48px;display: grid;justify-content: center;">
                        <div style="display: flex;justify-content: center;margin-bottom: 16px;">
                            <mat-icon style="font-size: 4rem;width: 4rem;height: 4rem;color: #30426a;" class="material-icons-outlined">person_pin</mat-icon>
                        </div>
                        <div class="text-center">
                            <b>{{ user.name }}</b><br>
                            <small>
                                {{ accountRoles[user.role] | translate }}
                            </small>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div mat-menu-item (click)="chgLang.open(); $event.stopPropagation()">
                        <change-language-button #chgLang></change-language-button>
                            <mat-icon mat-list-icon>language</mat-icon>
                            {{ 'languages.' + Language | translate }}
                    </div>
                    <mat-divider></mat-divider>
                    <a mat-menu-item [routerLink]="['/', 'personal', 'profile']">
                        <mat-icon mat-list-icon>person</mat-icon>
                        {{ 'personal.profile' | translate }}
                    </a>
                    <mat-divider></mat-divider>
                    <a mat-menu-item (click)="enableFullScreen()" *ngIf="!fullScreen">
                        <mat-icon mat-list-icon>fullscreen</mat-icon>
                        {{ 'tools.full_screen' | translate }}
                    </a>
                    <a mat-menu-item (click)="exitFullScreen()" *ngIf="fullScreen">
                        <mat-icon mat-list-icon>fullscreen_exit</mat-icon>
                        {{ 'tools.exist_full_screen' | translate }}
                    </a>
                    <mat-divider></mat-divider>
                    <a mat-menu-item routerLink="/logout">
                        <mat-icon mat-list-icon>power_settings_new</mat-icon>
                        {{ 'logout' | translate }}
                    </a>
                </mat-menu>
            </div>
        </div>
        <div class="page-content" >
            <div class="page-content-box">
                <div class="sticky left-0">
                    <mat-progress-bar *ngIf="loading" style="flex: 1 1 4px;" mode="query"></mat-progress-bar>
                </div>
                <router-outlet #o="outlet"></router-outlet>
            </div>
        </div>
    </main>
</div>

<ng-template #PWA_Installation let-ref="dialogRef">
    <h1 mat-dialog-title>{{ 'pwa.installaction_dialog.title' | translate }}</h1>
    <div mat-dialog-content class="mb-4">
        <div>{{ 'pwa.installaction_dialog.content' | translate }}</div>
        <mat-hint class="text-sm mt-4 block">{{ 'pwa.installaction_dialog.notice' | translate }}</mat-hint>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-stroked-button (click)="pwa.rejectInstallation(); ref.close();">{{ 'pwa.installaction_dialog.action.reject' | translate }}</button>
        <button mat-stroked-button (click)="pwa.promptConfirm(); ref.close();" cdkFocusInitial color="primary">{{ 'pwa.installaction_dialog.action.allow' | translate }}</button>
    </div>
</ng-template>
