<ng-container *ngIf="inited">
	<ng-container *ngIf="(control && form) else NoControl">
		<ng-container *ngIf="config.htmlInputType != 'hidden'">
			<ng-container *ngIf="fieldDef?.afterAreaDef as t" [ngTemplateOutlet]="t.template" [ngTemplateOutletContext]="{$implicit: config, input: control, form: form}"></ng-container>
	
			<ng-container *ngIf="style === 'classic'">
				<cfg-form-classic-input [CfgForm]="CfgForm" [form]="form" [input]="control" [template-def]="fieldDef" [config]="config"></cfg-form-classic-input>
			</ng-container>
	
			<ng-container *ngIf="style === 'float'">
				<cfg-form-material-input [CfgForm]="CfgForm" [form]="form" [input]="control" [template-def]="fieldDef" [config]="config"></cfg-form-material-input>
			</ng-container>
	
			<ng-container *ngIf="style === 'fill'">
				<cfg-form-material-input [CfgForm]="CfgForm" [form]="form" [input]="control" [template-def]="fieldDef" [config]="config" appearance="fill"></cfg-form-material-input>
			</ng-container>
	
			<ng-container *ngIf="style === 'outline'">
				<cfg-form-material-input [CfgForm]="CfgForm" [form]="form" [input]="control" [template-def]="fieldDef" [config]="config" appearance="outline"></cfg-form-material-input>
			</ng-container>
	
			<ng-container *ngIf="style === 'mobile'">
				<cfg-form-mobile-input [CfgForm]="CfgForm" [form]="form" [input]="control" [template-def]="fieldDef" [config]="config" appearance="outline"></cfg-form-mobile-input>
			</ng-container>
	
			<ng-container *ngIf="fieldDef?.beforeAreaDef as t" [ngTemplateOutlet]="t.template" [ngTemplateOutletContext]="{$implicit: config, input: control, form: form}"></ng-container>
		</ng-container>
	</ng-container>
	<ng-template #NoControl>
		<p class="text-red-500">No form or form control founded ... </p>
	</ng-template>
</ng-container>
