<data-table-wrapper [tableConfig]="repos" class="card-panel" [ShowPaginator]="false">

    <div data-table-title> {{ 'forms.work.repos.title' | translate }} </div>

    <button mat-icon-button action-button (click)="handleViewFile(work.files && work.files.length ? ImagesSwiper : NoImages )" title="{{ 'common.view_files' | translate }}"> <mat-icon [matBadge]="work.files?.length || null">description</mat-icon> </button>

    <table class="table table-hover" data-table [data]="repos">

        <ng-container dt-column="repo_content">
            <td *dt-cell="let row" class="w-1/2" style="min-width: 200px;">
                <pre class="text-black text-md m-0">{{ row.repo_content }}</pre>
            </td>
        </ng-container>

        <ng-container dt-column="menu">
            <td *dt-cell="let row" class="p-0">
                <ng-container *ngIf="canModifyWork">
                    <button mat-icon-button [matMenuTriggerFor]="menu"> <mat-icon>more_vert</mat-icon> </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="handleUpdateRepo(row)">{{ 'forms.common.update_work_report' | translate }}</button>
                        <button mat-menu-item (click)="handleRemoveRepo(row)">{{ 'forms.common.delete_work_report' | translate }}</button>
                    </mat-menu>
                </ng-container>
            </td>
        </ng-container>

    </table>

</data-table-wrapper>

<ng-template #ImagesSwiper>
    <swiper [slidesPerView]="1" [loop]="true" [pagination]="{ clickable: true }" [zoom]="true" class="bg-black">
        <ng-container *ngFor="let f of work.files">
            <ng-template swiperSlide [zoom]="true">
                <div class="position-relative w-full">
                    <button (click)="handleRemoveFile(f)" mat-icon-button class="position-absolute top-2 right-2 border border-gray-200" color="primary" style="background-color: #FFF;" > <mat-icon> delete </mat-icon> </button>
                    <img src="/api/uploads{{ f.file_path }}" alt="{{ f.file_name }}" class="w-auto h-auto m-auto" style="max-height: 300px;" >
                </div>
            </ng-template>
        </ng-container>
    </swiper>
</ng-template>
<ng-template #NoImages>
    <div class="bg-gray-200 text-gray-500 flex items-center justify-center mx-3 my-3 rounded-sm" style="height: 280px;">
        <span>No Files</span>
    </div>
</ng-template>
