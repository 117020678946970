import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loading-content',
  templateUrl: './loading-content.component.html',
  styles: [
      `.mask { background-color: rgba(0, 0, 0, 0.15); }`
  ]
})
export class LoadingContentComponent implements OnInit {

    @Input() mode: 'override' | 'mask' = 'override';

    @Input() loading: boolean = false;

    constructor() { }

    ngOnInit(): void {}

}
