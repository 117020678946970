import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { take, tap, map } from 'rxjs/operators';
import { AlertService } from 'app/shared/angular-material/snake-bar-module/services/alert.service';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(private auth: AuthService, private router: Router, private alert: AlertService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.auth.loginUser.pipe(
            take(1),
            map( usr => !!usr ),
            tap( isLoggedIn => {
                if ( !isLoggedIn ) {
                    this.router.navigate(['/login']);
                }
            })
        );

    }

}
