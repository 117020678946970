<!-- Header -->
<div class="sticky-left" *ngIf="ShowHeader">
    <div class="data-table-header">

        <div class="data-table-header-actions title">
            <ng-content select="[data-table-title]"></ng-content>
        </div>

        <div class="data-table-header-actions actions">
            <ng-content select="[action-button-left]"></ng-content>
            <button *ngIf="tableConfig && tableConfig.filters.length" class="square-icon-button" mat-icon-button (click)="show_filters = !show_filters" [title]="(show_filters ? 'datatable.btn_hide_filters' : 'datatable.btn_show_filters') | translate">
                <mat-icon [matBadge]="getFilterCount() || undefined "
                          matBadgePosition="after"
                          matBadgeColor="primary"
                          fontSet="material-icons-outlined">filter_alt</mat-icon>
            </button>
            <ng-container *ngIf="tableConfig && tableConfig.columns.length && ShowColumnsConfigButton">
                <button class="square-icon-button" mat-icon-button [matMenuTriggerFor]="columnsConfigMenu" title="{{ 'datatable.column_config' | translate }}">
                    <mat-icon>rule</mat-icon>
                    <mat-menu #columnsConfigMenu="matMenu">
                        <data-table-column-list [(config)]="tableConfig" (click)="$event.stopPropagation()"></data-table-column-list>
                    </mat-menu>
                </button>
            </ng-container>
            <ng-content select="[action-button]"></ng-content>
        </div>
    </div>
</div>

<!-- Filter -->
<div class="sticky-left" *ngIf="tableConfig.filters.length">
    <div style="height: 1px;background-color: rgba(0, 0, 0, 0.1);"></div>
    <div [@toggle-animation]="show_filters ? 'expanded' : 'collapsed'" class="filters">
        <form [formGroup]="form">
            <div class="data-table-filters" [dtAutoGrid]="filter_min_width">
                <ng-container *ngFor="let i of tableConfig.filters;trackBy: filterLoopTrackBy;" [ngTemplateOutlet]="InpnutTemplate" [ngTemplateOutletContext]="{input: i}"></ng-container>
                <div class="button-list">
                    <button mat-icon-button (click)="paging.emit(last_paging);search.emit(last_paging)" class="square-icon-button" title="{{ 'common.btn_search' | translate | titlecase }}"> <mat-icon>search</mat-icon> </button>
                    <button *ngIf="getFilterCount()" action-button class="square-icon-button" mat-icon-button title="{{ 'datatable.btn_reset_filters' }}" (click)="resetFilter()"> <mat-icon>search_off</mat-icon> </button>
                </div>
            </div>
        </form>
        <div style="height: 1px;background-color: rgba(0, 0, 0, 0.1);"></div>
    </div>
</div>

<!-- Content -->
<!-- <div class="data-table-content" #content> -->
    <ng-content></ng-content>
<!-- </div> -->

<!-- Footer Paginator -->
<div class="paginator" #footer *ngIf="ShowPaginator">
    <data-table-paginator [total]="tableConfig.total" [(limit)]="tableConfig.limit" [limit_options]="limit_options" (limitChange)="limitChange.emit($event)" [(page)]="tableConfig.page" (paging)="handlePaging($event)"></data-table-paginator>
</div>


<!-- Input Templates -->
<ng-template #InpnutTemplate let-i="input">
    <ng-container [ngSwitch]="i.type">

        <!-- Text -->
        <ng-container *ngSwitchCase="'text'">
            <div class="form-group" [dtAutoGridColumn]="i.cols || 1">
                <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                <input class="form-control" type="text" [value]="form.get(i.name).value" [placeholder]="(i.hint || '') | translate" (change)="form.get(i.name).patchValue($event.target['value'])" (key.Enter)="$event.stopPropagation();" />
            </div>
        </ng-container>

        <!-- Number -->
        <ng-container *ngSwitchCase="'number'">
            <div class="form-group" [dtAutoGridColumn]="i.cols || 1">
                <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                <input class="form-control" type="number" [value]="form.get(i.name).value" [placeholder]="(i.hint || '') | translate" (change)="form.get(i.name).patchValue($event.target['value'], { emitEvent: false })" (key.Enter)="$event.stopPropagation()" />
            </div>
        </ng-container>

        <!-- Select -->
        <ng-container *ngSwitchCase="'select'">
            <div class="form-group" [dtAutoGridColumn]="i.cols || 1">
                <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                <mat-select *ngIf="!i.multiple" [formControl]="form.get(i.name)" class="form-control" [placeholder]="(i.hint || '') | translate">
                    <mat-select-trigger>{{ getSelectLabel(i) }}</mat-select-trigger>
                    <ng-container *ngIf="i.options as options">
                        <input #filter_input placeholder="{{ 'common.filter' | translate }}..." autocomplete="off" class="dt-filter-input" style="border: none;outline: none;" (keyup)="chg.detectChanges()">
                        <!-- <mat-divider></mat-divider> -->
                        <mat-option [value]="null" *ngIf="!i.multiple && form.get(i.name)?.value !== null" ></mat-option>
                        <ng-container *ngIf="filterOptions(filter_input.value, i) as filted_options">
                            <mat-option *ngIf="filted_options.length == 0" disabled>{{ 'common.form.no_option_found' | translate }}</mat-option>
                            <mat-option *ngFor="let opt of options" [value]="opt.value" [ngStyle]="{'display': filted_options.indexOf(opt) >= 0 ? '' : 'none', 'height': 'auto'}" [innerHTML]="opt.label | translate"></mat-option>
                        </ng-container>
                    </ng-container>
                </mat-select>
                <mat-select *ngIf="i.multiple" [formControl]="form.get(i.name)" [multiple]="true" class="form-control" [placeholder]="(i.hint || '') | translate">
                    <mat-select-trigger>{{ getSelectLabel(i) | translate }}</mat-select-trigger>
                    <input #filter_input placeholder="{{ 'common.filter' | translate }}..." autocomplete="off" style="border: none;outline: none;" class="w-full dt-filter-input" (keyup)="chg.detectChanges()">
                    <ng-container *ngIf="i.options as options">
                        <ng-container *ngIf="filterOptions(filter_input.value, i) as filted_options">
                            <mat-option *ngIf="filted_options.length == 0" disabled>{{ 'common.form.no_option_found' | translate }}</mat-option>
                            <mat-option *ngFor="let opt of options" [value]="opt.value" [ngStyle]="{'display': filted_options.indexOf(opt) >= 0 ? '' : 'none'}">
                                <div [innerHTML]="opt.label | translate"></div>
                            </mat-option>
                            <!-- <mat-option *ngFor="let opt of options" [value]="opt.value" [ngStyle]="{'display': filted_options.indexOf(opt) >= 0 ? '' : 'none'}" [innerHTML]="opt.label | translate"></mat-option> -->
                        </ng-container>
                    </ng-container>
                </mat-select>
            </div>
        </ng-container>

        <!-- Date Input -->
        <ng-container *ngSwitchCase="'date'">
            <div class="form-group" [dtAutoGridColumn]="i.cols || 1">
                <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                <input class="form-control" type="date" [value]="form.get(i.name).value" [placeholder]="(i.hint || '') | translate" (change)="form.get(i.name).patchValue($event.target['value'], { emitEvent: false })" (key.Enter)="$event.stopPropagation()" />
            </div>
        </ng-container>

        <!-- Date Range Input -->
        <ng-container *ngSwitchCase="'date-range'">
            <div class="form-group grid-col-{{ i.cols || 2 }}">
                <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                <div class="input-group">
                    <input class="form-control" style="padding-right: 4px;" type="date" [formControl]="form.get(i.name + '.from')" />
                    <span class="input-group-text" style="border-radius: 0px;border-left: 0;border-right: 0;"> ~ </span>
                    <input class="form-control" style="padding-right: 4px;" [ngClass]="{'is-invalid': form.get(i.name + '.to')?.invalid}" type="date" [formControl]="form.get(i.name + '.to')" />
                    <button *ngIf="form.get(i.name)?.value.from || form.get(i.name)?.value.to" class="input-group-btn text-center" style="padding: 0 4px;" type="button" (click)="form.get(i.name)?.reset()" title="Reset"> <mat-icon>close</mat-icon> </button>
                    <button *ngIf="form.get(i.name)?.value.from || form.get(i.name)?.value.to" class="input-group-btn text-center" style="padding: 0 4px;" type="button" (click)="handleDatePeriodBtnClick(i, -1);" title="Prev"> <mat-icon>navigate_before</mat-icon> </button>
                    <button *ngIf="form.get(i.name)?.value.from || form.get(i.name)?.value.to" class="input-group-btn text-center" style="padding: 0 4px;" type="button" (click)="handleDatePeriodBtnClick(i,  1);" title="Next"> <mat-icon>navigate_next</mat-icon> </button>
                </div>
            </div>
        </ng-container>

        <!-- Webtag Input -->
        <ng-container *ngSwitchCase="'webtag'">
            <div *ngIf="(authService.loginUser | async)?.isPlatformAdmin" class="form-group grid-col-{{ i.cols || 1 }}">
                <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                <mat-select [formControl]="form.get(i.name)" [multiple]="i.multiple" class="form-control" [value]="form.get(i.name)?.value">
                    <mat-select-trigger>{{ form.get(i.name)?.value ? form.get(i.name)?.value.webtag_id : '' }}</mat-select-trigger>
                    <input #filter_input placeholder="{{ 'common.filter' | translate }}..." autocomplete="off" style="border: none;outline: none;" class="w-full dt-filter-input" (keyup)="chg.detectChanges()">
                    <ng-container *ngIf="filterOptions(filter_input.value, i) as filted_options">
                        <mat-option *ngIf="filted_options.length == 0" disabled>{{ 'common.form.no_option_found' | translate }}</mat-option>
                        <mat-option *ngFor="let opt of filted_options" [value]="opt.value" [ngStyle]="{'display': filted_options.indexOf(opt) >= 0 ? '' : 'none'}">
                            {{ opt.label }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </div>
        </ng-container>

        <!-- Site Group Input -->
        <ng-container *ngSwitchCase="'site_group'">
            <ng-container *ngIf="(authService.loginUser | async)?.isWebtagAdmin || (authService.loginUser | async)?.isPlatformAdmin">
                <div class="form-group grid-col-{{ i.cols || 1 }}">
                    <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                    <mat-select [formControl]="form.get(i.name)" [multiple]="i.multiple" class="form-control" [value]="form.get(i.name)?.value">
                        <mat-select-trigger>{{ form.get(i.name)?.value ? form.get(i.name)?.value.group_id : '' }}</mat-select-trigger>
                        <input #filter_input placeholder="{{ 'common.filter' | translate }}..." autocomplete="off" style="border: none;outline: none;" class="w-full dt-filter-input" (keyup)="chg.detectChanges()">
                        <mat-option [value]="null"></mat-option>
                        <ng-container *ngIf="filterOptions(filter_input.value, i) as filted_options">
                            <mat-option *ngIf="filted_options.length == 0" disabled>{{ 'common.form.no_option_found' | translate }}</mat-option>
                            <mat-option *ngFor="let opt of filted_options" [value]="opt.value" [ngStyle]="{'display': filted_options.indexOf(opt) >= 0 ? '' : 'none'}">
                                {{ opt.label }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </div>
            </ng-container>
        </ng-container>

        <!-- Sites Input -->
        <ng-container *ngSwitchCase="'sites'">
            <div class="form-group grid-col-{{ i.cols || 1 }}">
                <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                <dt-site-selector [control]="form.get(i.name)" [multiple]="i.multiple" [id_type]="getIDType()"></dt-site-selector>
            </div>
        </ng-container>

        <!-- ID Type Input -->
        <ng-container *ngSwitchCase="'id_type'">
            <div class="form-group grid-col-{{ i.cols || 1 }}">
                <label class="form-label" [attr.for]="i.name">{{ i.label | translate }}</label>
                <dt-id-type-selector [control]="form.get(i.name)"></dt-id-type-selector>
            </div>
        </ng-container>

        <!-- Unknow Setting -->
        <ng-container *ngSwitchDefault>
            <div class="unknow-box" [dtAutoGridColumn]="i.cols || 1">
                <mat-icon class="mr-2">help</mat-icon>
                Unknow input type <b>【{{ i.type }}】</b>.
            </div>
        </ng-container>

    </ng-container>
</ng-template>
