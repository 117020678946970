import { Component, OnInit, Inject } from '@angular/core';

import { AlertService } from 'app/shared/angular-material/snake-bar-module/services/alert.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { ChangePassword } from './models';
import { equalValidator } from 'app/shared/validators';
import { CrossFieldErrorMatcher } from 'app/shared/angular-material/error-matcher';
import { PersonalService } from './personal.service';
import { Account } from 'auth/models';

@Component({
    selector: 'app-change-passoword-component',
    templateUrl: 'change-password.component.html',
    styles: []
})
export class ChangePasswordComponent implements OnInit {

    account: Account;
    form: UntypedFormGroup;
    loading = false;

    equalErrorMatcher: CrossFieldErrorMatcher = new CrossFieldErrorMatcher();

    constructor(
        private personalService: PersonalService,
        private alertService: AlertService,
        private fb: UntypedFormBuilder,
        public dialogRef: MatDialogRef<ChangePasswordComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {}
    ) {}

    ngOnInit(): void {
        this.initForm();
    }

    initForm() {
        this.form = this.fb.group({
            old_password: ['', [Validators.required, Validators.maxLength(32)] ],
            new_password: ['', [Validators.required, Validators.maxLength(32)] ],
            confirmed_password: ['', [Validators.required, Validators.maxLength(32)] ],
        }, { validators: [equalValidator('new_password', 'confirmed_password')] });
    }

    handleSubmit(e: Event) {
        if ( this.form.valid ) {
            this.loading = true;
            this.form.disable();
            this.dialogRef.disableClose = true;

            this.personalService.changePassword(this.form.getRawValue() as ChangePassword)
                .pipe(
                    finalize(() => { this.loading = false; this.form.enable(); this.dialogRef.disableClose = false; })
                )
                .subscribe(
                    res => {
                        this.alertService.success('Changed Password Successfully');
                        this.dialogRef.close(res.data);
                    },
                    err => {
                        this.alertService.error(`Failed to Change Password: ${err.message}`);
                    }
                );
        }
    }

}
