import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SiteSelectorComponent } from './site-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { CustomValidatorsModule } from 'app/shared/validators';
import { MatOptionModule } from '@angular/material/core';
import { ReactiveFormsModule } from '@angular/forms';

const components = [SiteSelectorComponent];
const modules = [
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    CustomValidatorsModule,
    ReactiveFormsModule,
];

@NgModule({
    declarations: [...components],
    imports: [CommonModule, ...modules],
    exports: [...components],
})
export class SiteSelectorModule { }
