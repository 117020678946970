import { ChangeDetectorRef } from '@angular/core';
import { TranslateModule, TranslateLoader, TranslateService, TranslatePipe } from "@ngx-translate/core";


export let TranslatePipeProvider = {
    provide: TranslatePipe,
        useFactory: (translateService: TranslateService, changeDetectorRef: ChangeDetectorRef) => {
        return new TranslatePipe(translateService, changeDetectorRef)
    },
    deps: [TranslateService, ChangeDetectorRef]
};