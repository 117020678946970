import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'app/core/services/api.service';
import { map } from 'rxjs/operators';
import { BackendHost, BackendPath } from 'app/configs/backend';
import { ApiResponse } from 'app/core/interfaces';
import { ChangePassword } from './models';

const api = `${BackendHost}${BackendPath}`;

@Injectable({ providedIn: 'root' })
export class PersonalService {

    constructor(private api: ApiService) {}

    changePassword(password: ChangePassword): Observable<ApiResponse<any>> {
        return this.api.patch(`${api}/auth/user/change_password`, password);
    }

    updateProfile(data: { name: string, email: string, phone: string, language: string }): Observable<any>
    {
        return this.api.patch(`${api}/auth/user/profile`, data);
    }

}
