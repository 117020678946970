import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ValidatorFn, UntypedFormControl, AbstractControl, ValidationErrors, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Webtag } from 'app/pages/admin/webtags/models';
import { BindHostErrorStateMatcher } from 'app/shared/angular-material/error-matcher';
import { BehaviorSubject, merge, Observable, Subscription } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';
import { WorkType } from '../forms/models';
import { FormUIService } from './form-ui.service';

@Component({
    selector: 'work-type-selector',
    templateUrl: './work-type-selector.component.html',
    styles: [`

        :host {
            display: block;
        }
    
        .wrapper {
            /* background-color: rgb(245, 245, 245);  */
            border-top-left-radius: 0.25rem; 
            border-top-right-radius: 0.25rem;
            display: inline-flex;
            flex-wrap: wrap;
            width: 100%;
        }

        .webtag { flex: 1 1 160px; width: 160px; }

        .workType { flex: 1 1 auto; }

        /* 
        :host ::ng-deep .mat-form-field-wrapper {
            padding-bottom: 0px;
        }

        :host ::ng-deep .mat-form-field-flex {
            background-color: transparent;
        }

        :host ::ng-deep .mat-form-field-underline {
            bottom: 0;
        }
        */

        :host ::ng-deep .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
            transform: unset;
        } 
    `],
})
export class WorkTypeSelectorComponent implements OnInit {

    // 帳號標題
    @Input() label: string = 'forms.work_type';

    // Webtag 驗證器
    @Input('webtag-validators') webtagValidator: ValidatorFn | Array<ValidatorFn>;

    // WorkType 驗證器
    @Input('work-type-validators') workTypeValidator: ValidatorFn | Array<ValidatorFn>;

    // 可讀的所有Webtag
    private _webtags: Array<VisiableWebtag> = [];
    
    // 可讀的所有派工類別
    private _work_types: Array<VisiableWorkType> = [];

    // Webtag清單 Store
    webtagsSubject: BehaviorSubject<Array<VisiableWebtag>> = new BehaviorSubject([]);

    // Webtag清單
    webtags$: Observable<Array<VisiableWebtag>> = this.webtagsSubject.asObservable();
    
    // 帳號清單 Store
    workTypesSubject: BehaviorSubject<Array<VisiableWorkType>> = new BehaviorSubject([]);

    // 帳號清單
    workTypes$: Observable<Array<VisiableWorkType>> = this.workTypesSubject.asObservable();

    // 派工類別FormControl
    @Input('control') workType: UntypedFormControl = new UntypedFormControl(null);

    // 隱藏Webtag
    @Input() hideWebtag: boolean = false;

    // Webtag FormControl
    @Input('webtagIdFormControl') webtag_id: UntypedFormControl;

    // Target Webtag
    @Input('target-webtag') targetWebtag: string = '';

    // 帳戶篩選 FormControl
    workTypeFilter: UntypedFormControl = new UntypedFormControl('');

    // Webtag FormControl
    webtagFilter: UntypedFormControl = new UntypedFormControl('');

    // 訂閱者清單 - OnDestroy時解訂用
    private _subscriptions: Array<Subscription> = [];

    constructor( 
        private formUIService: FormUIService,
        private detectRef: ChangeDetectorRef,
    ) {}

    ngOnInit(): void 
    {

        this.workType.setValidators( this.workTypeValidator );

        if( !this.webtag_id )
        {
            this.webtag_id = new UntypedFormControl(null, this.webtagValidator);
        }

        this._subscriptions.push(

            // Webtag變更
            this.webtag_id.valueChanges.subscribe(chg => {
                this.workType.patchValue(null);
                this.detectRef.markForCheck();
            }),

            // 篩選器觸發
            merge(
                this.webtag_id.valueChanges.pipe(startWith(this.webtagFilter)),
                this.webtagFilter.valueChanges.pipe(startWith(this.webtagFilter)),
                this.workTypeFilter.valueChanges.pipe(startWith(this.workTypeFilter)), 
            ).pipe( debounceTime(100) ).subscribe( this.handleFilter.bind(this) ),

            // 取得work types
            this.formUIService.getWorkTypes(0, 0).pipe(
                map( res => res.data ),
            ).subscribe(workTypes => {
                this._work_types = (workTypes as Array<VisiableWorkType>).map( a => {
                    a.visiable = true;
                    return a;
                });
    
                this.workTypeFilter.patchValue('');
            }),

            // 取得Webtag清單
            this.formUIService.readableWebtags$.pipe(
                map( webtags => webtags.filter( 
                    w => this.targetWebtag ? w.domain == this.targetWebtag : true )
                ),
            ).subscribe(webtags => {
                this._webtags = (webtags as Array<VisiableWebtag>).map(w => {
                    w.visiable = true;
                    return w;
                });
                
                if( this._webtags.length ) {
                    this.webtag_id.patchValue( webtags[0].domain )
                }
    
                this.webtagFilter.patchValue('');
            }),

        );

    }

    handleFilter(values: Array<string>) 
    {
        
        // 已選的webtag
        let selected_webtag_id = this.webtag_id.value, 
        // 搜尋的Webtag
        webtag_id = this.webtagFilter.value, 
        // 搜尋的帳號
        work_type_id = this.workTypeFilter.value;

        if( !work_type_id && !webtag_id ) {
            this._work_types.forEach(a => a.visiable = true);
            this._webtags.forEach(w => w.visiable = true);
        } else {
            this._work_types.forEach(a => a.visiable = false);
            this._webtags.forEach(w => w.visiable = false);
        }

        this._webtags.forEach( webtag => {
            if( webtag.domain.includes(webtag_id) || webtag.name.includes(webtag_id) ) {
                webtag.visiable = true;
            }
        });

        this._work_types.forEach( work_type => {

            if( this.webtag_id.value && this.webtag_id.value != work_type.webtag_id ) {
                work_type.visiable = false
                return;
            }

            if( !work_type_id ) {
                work_type.visiable = true;
                return;    
            }

            work_type.visiable = work_type.work_type_id.includes(work_type_id) || work_type.comment.includes(work_type_id);

        });

        this.workTypesSubject.next( this._work_types );
        this.webtagsSubject.next( this._webtags );

    }

}

interface VisiableWorkType extends WorkType {
    visiable: boolean;
}
interface VisiableWebtag extends Webtag {
    visiable: boolean;
}