import { TranslatePipe } from '@ngx-translate/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatRippleModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MomentDateModule } from '@angular/material-moment-adapter';

import { CfgFormComponent } from './cfg-form/cfg-form.component';
import { ClassicComponent } from './styles/classic/classic.component';
import { MaterialComponent } from './styles/material/material.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MobileComponent } from './styles/mobile/mobile.component';
import { CfgFormErrorMessagePipe } from './pipes/cfg-form-error-message.pipe';
import { CfgFormInputDefDirective, CfgFormLabelDefDirective, CfgFormErrorLabelDefDirective, CfgFormHintLabelDefDirective, CfgFormFieldDefDirective, CfgFormBeforeAreaDefDirective, CfgFormAfterAreaDefDirective, CfgFormBreakLineDefDirective } from './directives/cfg-form-input-template.directive';
import { CfgInputComponent } from './cfg-input/cfg-input.component';

const components = [CfgFormComponent, ClassicComponent, MaterialComponent, MobileComponent, CfgInputComponent];
const pipes = [CfgFormErrorMessagePipe];
const directives = [CfgFormInputDefDirective, CfgFormLabelDefDirective, CfgFormErrorLabelDefDirective, CfgFormHintLabelDefDirective, CfgFormFieldDefDirective, CfgFormBeforeAreaDefDirective, CfgFormAfterAreaDefDirective, CfgFormBreakLineDefDirective]

@NgModule({
    declarations: [...components, ...pipes, ...directives],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatIconModule,
        MatButtonModule,
        MatDatepickerModule,
        MomentDateModule,
        MatFormFieldModule,
        MatRippleModule,
		TranslateModule,
    ],
    exports: [...components, ...directives],
    providers: [
        // { provide: FORM_DEFAULT_STYLE, useValue: 'classic' },
        {
			provide: MAT_RIPPLE_GLOBAL_OPTIONS,
			useValue: {
				disabled: true,
			},
		},
		{ provide: MAT_DATE_LOCALE, useValue: 'en'},
		{
			provide: MAT_DATE_FORMATS,
			useValue: {
				parse: {
					dateInput: 'YYYY-MM-DD'
				},
				display: {
					dateInput: 'YYYY-MM-DD',
					monthYearLabel: 'YYYY-MM',
					dateA11yLabel: 'YYYY-MM',
					monthYearA11yLabel: 'YYYY-MM',
					monthLabel: 'MMMM',
				},
			}
		},
        {
            provide: TranslatePipe
        }
    ]
})
export class CfgFormModule { }
