<div class="list">
    <div class="list-item title">{{ 'datatable.column_config' | translate }}</div>
</div>
<div class="list" cdkDropList (cdkDropListDropped)="drop($event)">
    <div *ngFor="let column of config.columns; trackBy: identify" cdkDrag [attr.name]="column.name">
        <mat-divider></mat-divider>
        <div class="list-item">
            <mat-checkbox
                [checked]="Checked(column.name)"
                [value]="column.name"
                color="primary"
                [disabled]="column.disableHideColumn"
                (change)="handleChecked($event)"
            >{{ (column.label || column.description || column.name) | translate }}</mat-checkbox>
            <div style="flex: 1 auto;"></div>
            <mat-icon cdkDragHandle style="cursor: move;margin-left: 1rem;" [matTooltip]="'datatable.column_config.drag_tip' | translate">drag_handle</mat-icon>
        </div>
    </div>
</div>

