import { ValidatorFn } from "@angular/forms";

export function OverThen(col: string): ValidatorFn {
    return function(control) {
        const group = control.parent;
        if( group ) {
            if( group.value[col] && group.value[col] > control.value ) {
                return {'over_then': { message: `min value need over then ${group.value[col]}` }};
            }
        }

        return null;
    }
}
