<div class="wrapper">
    <ng-container *ngIf="(webtags$ | async).length > 1 && !targetWebtag && !hideWebtag">
        <mat-form-field class="webtag" appearance="fill" floatLabel="always">
            <mat-label>{{ label | translate }} - Webtag</mat-label>
            <mat-select [formControl]="webtag_id">
                <ng-container *ngFor="let webtag of webtags$ | async">
                    <mat-option [ngStyle]="{'display': webtag.visiable ? '' : 'none'}" [value]="webtag.domain">{{ webtag.domain }}</mat-option>
                </ng-container>
            </mat-select>
            <mat-error><error-messages [control]="webtag_id"></error-messages></mat-error>
        </mat-form-field>
    </ng-container>
    <mat-form-field class="account" appearance="fill" floatLabel="always">
        <mat-label>{{ label | translate }}</mat-label>
        <mat-select [formControl]="account">
            <mat-option [value]="null">( Empty )</mat-option>
            <ng-container *ngFor="let account of accounts$ | async">
                <mat-option [ngStyle]="{'display': account.visiable ? '' : 'none'}" [value]="account">{{ account.name }} <label style="margin: 0px;">({{ account.webtag_id }}-{{ account.account }})</label> </mat-option>
            </ng-container>
        </mat-select>
        <mat-error><error-messages [control]="account"></error-messages></mat-error>
    </mat-form-field>
</div>