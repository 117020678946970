import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { BindHostErrorStateMatcher } from 'app/shared/angular-material/error-matcher';
import { Webtag } from 'app/pages/admin/webtags/models';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { debounceTime, startWith, map } from 'rxjs/operators';
import { FormUIService } from './form-ui.service';

@Component({
    selector: 'webtag-selector',
    templateUrl: './webtag-selector.component.html',
    styles: [`
        :host ::ng-deep .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
            transform: unset;
        }
    `],
})
export class WebtagSelectorComponent implements OnInit {

    // 帳號標題
    @Input() label: string = 'common.webtag';

    // 可讀的所有Webtag
    private _webtags: Array<VisiableWebtag> = [];

    // Webtag清單 Store
    webtagsSubject: BehaviorSubject<Array<VisiableWebtag>> = new BehaviorSubject([]);

    // Webtag清單
    webtags$: Observable<Array<VisiableWebtag>> = this.webtagsSubject.asObservable();

    // Webtag FormControl
    @Input('control') webtag_id: UntypedFormControl = new UntypedFormControl(null);

    // Webtag FormControl
    webtagFilter: UntypedFormControl = new UntypedFormControl('');

    // 訂閱者清單 - OnDestroy時解訂用
    private _subscriptions: Array<Subscription> = [];

    constructor(
        private formUIService: FormUIService,
        private detectRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {

        this._subscriptions.push(

            // 篩選器觸發
            this.webtagFilter.valueChanges.pipe(
                debounceTime(100),
                startWith(this.webtagFilter),
                map(event => event.value)
            ).subscribe(this.handleFilter.bind(this)),

            // 取得Webtag清單
            this.formUIService.readableWebtags$.subscribe(webtags => {
                this._webtags = (webtags as Array<VisiableWebtag>).map(w => {
                    w.visiable = true;
                    return w;
                });

                if (this._webtags.length) {
                    this.webtag_id.patchValue(webtags[0].domain)
                }

                this.webtagFilter.patchValue('');
            }),

        );

    }

    handleFilter(webtag_id: string) {

        if (webtag_id) {
            this._webtags.forEach(w => w.visiable = false);
        } else {
            this._webtags.forEach(w => w.visiable = true);
        }

        this._webtags.forEach(webtag => {
            if (webtag.domain.includes(webtag_id) || webtag.name.includes(webtag_id)) {
                webtag.visiable = true;
            }
        });

        this.webtagsSubject.next(this._webtags);

    }

}

interface VisiableWebtag extends Webtag {
    visiable: boolean;
}