import { take } from 'rxjs/operators';
import { Webtag } from 'app/pages/admin/webtags/models';
import { AuthService } from 'app/core/services/auth.service';
import { Pipe, PipeTransform } from '@angular/core';

const unitSize = 1000;

@Pipe({ name: 'WebtagName' })
export class WebtagNamePipe implements PipeTransform {

    webtags: Webtag[] = [];

    constructor(private authService: AuthService) {
        authService.readableWebtags.pipe(take(1)).subscribe(webtags => this.webtags = webtags);
    }

    transform(webtagId: number) {
        const webtag = this.webtags.find(w => w.id === webtagId);
        return webtag ? webtag.name : webtagId;
    }
}
