import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { AbstractControl, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Account } from 'app/pages/admin/accounts/models';
import { Webtag } from 'app/pages/admin/webtags/models';
import { BindHostErrorStateMatcher } from 'app/shared/angular-material/error-matcher';
import { BehaviorSubject, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, map, startWith, take } from 'rxjs/operators';
import { FormUIService } from './form-ui.service';

@Component({
    selector: 'account-selector',
    templateUrl: './account-selector.component.html',
    styleUrls: ['./account-selector.component.scss'],
})
export class AccountSelectorComponent implements OnInit {

    // 帳號標題
    @Input() label: string = 'common.account';

    // Webtag 驗證器
    @Input('webtag-validators') webtagValidator: ValidatorFn | Array<ValidatorFn>;
    
    //  驗證器
    @Input('account-validators') accountValidator: ValidatorFn | Array<ValidatorFn>;

    // 可讀的所有Webtag
    private _webtags: Array<VisiableWebtag> = [];
    
    // 可讀的所有帳號
    private _accounts: Array<VisiableAccount> = [];

    // Webtag清單 Store
    webtagsSubject: BehaviorSubject<Array<VisiableWebtag>> = new BehaviorSubject([]);

    // Webtag清單
    webtags$: Observable<Array<VisiableWebtag>> = this.webtagsSubject.asObservable();
    
    // 帳號清單 Store
    accountsSubject: BehaviorSubject<Array<VisiableAccount>> = new BehaviorSubject([]);

    // 帳號清單
    accounts$: Observable<Array<VisiableAccount>> = this.accountsSubject.asObservable();

    // 帳戶FormControl
    @Input('control') account: UntypedFormControl = new UntypedFormControl('');

    // 隱藏Webtag
    @Input() hideWebtag: boolean = false;

    // Webtag FormControl
    @Input('webtagIdFormControl') webtag_id: UntypedFormControl;

    // Target Webtag
    @Input('target-webtag') targetWebtag: string = '';

    // 帳戶篩選 FormControl
    accountFilter: UntypedFormControl = new UntypedFormControl('');

    // Webtag FormControl
    webtagFilter: UntypedFormControl = new UntypedFormControl('');

    // 訂閱者清單 - OnDestroy時解訂用
    private _subscriptions: Array<Subscription> = [];

    constructor( 
        private detectRef: ChangeDetectorRef,
        private formUIService: FormUIService 
    ) {}

    ngOnInit(): void {

        this.account.setValidators( this.accountValidator );
        
        if( !this.webtag_id )
        {
            this.webtag_id = new UntypedFormControl(null, this.webtagValidator);
        }

        this.updateAccountList();
        this.handleFilter([]);

    }

    ngOnChanges(changes: SimpleChanges): void {
        this._subscriptions.forEach(s => s.unsubscribe());
        
        this._subscriptions.push(

            // Webtag變更
            this.webtag_id.valueChanges.subscribe(chg => this.updateAccountList()),

            // 篩選器觸發
            merge(
                this.webtag_id.valueChanges.pipe(startWith(this.webtagFilter)),
                this.webtagFilter.valueChanges.pipe(startWith(this.webtagFilter)),
                this.accountFilter.valueChanges.pipe(startWith(this.accountFilter)), 
            ).pipe( debounceTime(100) ).subscribe( this.handleFilter.bind(this) ),

            // 取得Webtag清單
            this.formUIService.readableWebtags$.pipe(
                map( webtags => webtags.filter( 
                    w => this.targetWebtag ? w.domain == this.targetWebtag : true )
                ),
            ).subscribe(webtags => {
                this._webtags = (webtags as Array<VisiableWebtag>).map(w => {
                    w.visiable = true;
                    return w;
                });
                
                if( this._webtags.length ) {
                    this.webtag_id.patchValue( webtags[0].domain )
                }
    
                this.webtagFilter.patchValue('');
            }),

        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach( s => s.unsubscribe() );
    }

    handleFilter(values: Array<string>) {
        
        // 已選的webtag
        let selected_webtag_id = this.webtag_id.value,
        // 搜尋的Webtag
        webtag_id = this.webtagFilter.value, 
        // 搜尋的帳號
        account_id = this.accountFilter.value;

        if( !account_id && !webtag_id ) {
            this._accounts.forEach(a => a.visiable = true);
            this._webtags.forEach(w => w.visiable = true);
        } else {
            this._accounts.forEach(a => a.visiable = false);
            this._webtags.forEach(w => w.visiable = false);
        }

        this._webtags.forEach( webtag => {
            if( webtag.domain.includes(webtag_id) || webtag.name.includes(webtag_id) ) {
                webtag.visiable = true;
            }
        });

        this._accounts.forEach( account => {

            if( selected_webtag_id != account.webtag_id ) {
                account.visiable = false
                return;
            }

            if( !account_id ) {
                account.visiable = true;
                return;    
            }

            account.visiable = account.name.includes(account_id) || account.account.includes(account_id);

        });

        this.accountsSubject.next( this._accounts );
        this.webtagsSubject.next( this._webtags );

    }

    updateAccountList() {
        // 取得帳號清單
        this.formUIService.readableAccounts$.pipe(take(1)).subscribe(accounts => {
            this._accounts = (accounts as Array<VisiableAccount>).map( a => {
                a.visiable = true;
                return a;
            });

            this.handleFilter([]);
        });
    }

}

interface VisiableAccount extends Account {
    visiable: boolean;
}
interface VisiableWebtag extends Webtag {
    visiable: boolean;
}