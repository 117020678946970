import { DataTable } from '../data-table/models';
import { ToggleAnimation } from '../animations/toggle-animation';
import { DataTableFilter, DataTableFilterOption } from '../models';
import { Component, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild, AfterViewInit, ViewEncapsulation, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PaginatorPagingEvent } from '../data-table-paginator/models';
import { PagingEvent } from './models';
import { interval, Subscription } from 'rxjs';
import { debounceTime, map, throttleTime } from 'rxjs/operators';
import { TranslatePipe } from '@ngx-translate/core';
import { IDType } from '../common/models';
import { AuthService } from 'app/core/services/auth.service';
import { SiteSelectorService } from 'app/core/services/site.service';
import { SiteGroupOption, SiteOption, WebtagOption } from 'app/core/interfaces';
import * as _ from 'lodash';
import { cloneDeep } from 'lodash';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'data-table-wrapper,[data-table-wrapper]',
    templateUrl: './data-table-wrapper.component.html',
    styleUrls: [
        './data-table-wrapper.component.scss',
        '../directives/auto-grid.directive.scss',
    ],
    animations: [ ToggleAnimation ],
    providers: [ TranslatePipe, DatePipe ],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableWrapperComponent implements OnInit {

    /** total - total rows of the list, 0 by default */
    @Input() total: number = 0;

    /** limit - limit rows per page, 10 by default */
    @Input() limit: number = 10;
    @Output() limitChange = new EventEmitter<number>();

    /** filter_min_width - filter input min width , 220 by default */
    @Input() filter_min_width: number = 220;

    /** page - current page, 1 by default */
    @Input() page: number = 1;
    @Output() pageChange = new EventEmitter<number>();

    /** decounce - debounce time of the paging event. */
    @Input() debounce: number = 100;

    /** limit_options - limit options , [10, 20, 40, 80, 120] by default  */
    @Input() limit_options: Array<number> = [10, 20, 40, 80, 120];

    /** paging - paging event */
    @Output() paging: EventEmitter<PagingEvent<any>> = new EventEmitter();

    /** tableConfig - data table instance */
    @Input() tableConfig: DataTable<any> = new DataTable<any>({});

    /** ShowHeader - show or hide header */
    @Input() ShowHeader: boolean = true;

    /** ShowColumnsConfigButton - show or hide columns management button */
    @Input() ShowColumnsConfigButton: boolean = true;

    /** ShowPaginator - Control display or hide paginator */
    @Input() ShowPaginator: boolean = true;

    /** Search */
    @Output() search: EventEmitter<PagingEvent<any>> = new EventEmitter();

    /** Sticky Paginator */
    @Input() stickyPaginator: boolean = true;

    @ViewChild('content') contentRef!: ElementRef;

    @ViewChild('footer') footerRef!: ElementRef;

    @ViewChild('filters') filtersRef!: ElementRef;

    get form(): UntypedFormGroup { return this.tableConfig.form || new UntypedFormGroup({}); };
    public show_filters: boolean = true;
    public last_paging: PaginatorPagingEvent = {
        page:  1,   limit:      10,   offset: 0,
        total: 0,   total_page: 1,
    };

    private subscriptions: Array<Subscription> = [];

    constructor(
        private hostElement: ElementRef,
        private i18n: TranslatePipe,
        public authService: AuthService,
        public siteSelectorService: SiteSelectorService,
        private datePipe: DatePipe,
        public chg: ChangeDetectorRef,
    ) {}

    @HostBinding('attr.sticky-paginator') AttrStickyPaginator() { return this.stickyPaginator; }

    ngOnInit(): void {
        this.subscriptions.push(
            this.form.valueChanges.pipe( debounceTime(this.debounce) ).subscribe( this.handleFormChange.bind(this) ),
        );
        this.last_paging.limit = this.tableConfig.lastPagingEvent.limit;
        this.last_paging.page = this.tableConfig.lastPagingEvent.page;
        if( this.tableConfig.limit != this.last_paging.limit ) { this.last_paging.limit = this.tableConfig.limit; }
        if( this.tableConfig.page != this.last_paging.page ) { this.last_paging.page = this.tableConfig.page; }

        if( !this.ShowHeader ) { this.show_filters = false; }

        if( this.tableConfig.filters.find(f => f.type == 'webtag') )
        {
            this.subscriptions.push(
                this.siteSelectorService.$WebtagOptions.pipe(
                    map(options => options.map( opt => ({ label: opt.webtag_id, value: opt })))
                ).subscribe(options => {
                    this.tableConfig.filters.find(f => f.type == 'webtag').options = options
                })
            );
        }
        if( this.tableConfig.filters.find(f => f.type == 'site_group') )
        {
            this.subscriptions.push(
                this.siteSelectorService.$SiteGroupOptions.pipe(
                    map(options => options.map( opt => ({ label: opt.group_id, value: opt })))
                ).subscribe(options => {
                    this.tableConfig.filters.find(f => f.type == 'site_group').options = options
                })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach( s => s.unsubscribe() );
    }

    getFilters(): {[key: string]: any} {
        let f = cloneDeep(this.form.value);

        if( !f ) { return {}; }

        Object.keys(f).forEach(key => {
            if( f[key] === null || f[key] === undefined || f[key] === "" ) {
                delete f[key];
            } else if ( Array.isArray( f[key] ) && f[key].length == 0 ) {
                delete f[key];
            }
            const i = this.tableConfig.filters.find(fi => fi.name == key);
            if( i && i.type == 'date-range' ) {
                if( !(f[key].from && f[key].to) || f[key].from > f[key].to ) {
                    delete f[key];
                }
            }
        })

        return f;
    }

    objectToQuery(obj: {[key:string]: any}): string {
        let params = new URLSearchParams();
        for(let key in obj){
            params.set(key, obj[key]) ;
        }

        return params.toString();
    }

    getFilterCount(): number {
        return Object.keys( this.getFilters() ).length;
    }

    handleFormChange(filters: any): void {
        const event: PagingEvent<any> = this.last_paging as PagingEvent<any>;

        event.filters = this.getFilters();
        if( event.filters ) {
            event.query = this.objectToQuery( event.filters );
        }
        this.tableConfig.lastPagingEvent = event;
        this.paging.emit(event);
    }

    handlePaging(e: PaginatorPagingEvent, scroll: boolean = true): void {
        this.last_paging = e;
        const event: PagingEvent<any> = e;
        event.filters = this.getFilters();
        if( event.filters ) {
            event.query = this.objectToQuery( event.filters );
        }
        this.tableConfig.lastPagingEvent = event;

        this.paging.emit(this.tableConfig.lastPagingEvent);
        this.limitChange.emit(e.limit);
        this.pageChange.emit(e.page);

        setTimeout(() => {
            this.hostElement.nativeElement.scrollTop = 0;
        }, 50);
    }

    getSelectLabel(i: DataTableFilter, options?: Array<VisibleOption>): string {
        const control = this.form.get(i.name);

        if( !control || (!i.options && !options) ) return '';

        if( control.value === null || (Array.isArray(control.value) && control.value.length == 0) ) { return i.empty_label === undefined ? "" : i.empty_label; }
        if( i.selectTriggerCallback ) { return i.selectTriggerCallback(i, control.value); }

        if( !options ) options = i.options as Array<DataTableFilterOption>;

        if( Array.isArray( control.value ) ) {
            const opt = i.options.find(opt => opt.value == control.value[0]);
            const items = control.value.length > 1 ? `(+${control.value.length - 1} items)` : '';
            return `${opt? this.i18n.transform(opt.label) : control.value}${items}`;
        } else {
            const opt = i.options.find(opt => opt.value == control.value);
            return `${opt? this.i18n.transform(opt.label) : control.value}`;
        }
    }

    filterOptions(target: string, cfg: DataTableFilter): Array<{ value: any, label: string }> {
        if( Array.isArray( cfg.options ) ) {
            if( target ){
                const opts = Array.from(cfg.options);
                opts.forEach( opt => opt.label = this.i18n.transform(opt.label) );
                return opts.filter(f => f.label.toLowerCase().includes( target.toLowerCase() ));
            } else {
                return cfg.options;
            }
        } else {
            return [];
        }
    }

    filterAsVisibleOptions(target: string, options: Array<VisibleOption>, filterFunc?: (target: string, v: unknown) => boolean): Array<VisibleOption> {
        if( !target ) {
            return options.map(v => {
                v.hidden = false;
                return v;
            });
        }

        if( filterFunc ) {
            return options.map( v => {
                v.hidden = !filterFunc(target, v);
                return v;
            });
        }

        return options.map( v => {
            v.hidden = !v.label.includes( target );
            return v;
        });
    }

    filterWebtags(target: string, options: Array<WebtagOption> = []): Array<VisibleOption> {
        if( !options ) options = [];
        const opts = options.map( webtag => ({ value: webtag, label: webtag.webtag_id, hidden: false }) );

        return this.filterAsVisibleOptions(target, opts);
    }

    filterSiteGroups(target: string, options: Array<SiteGroupOption> = []): Array<VisibleOption> {
        if( !options ) options = [];
        const opts = options.map( site_group => ({ value: site_group, label: site_group.group_id, hidden: false }) );
        return this.filterAsVisibleOptions(target, opts);
    }

    filterSite(target: string, options: Array<SiteOption> = []): Array<VisibleOption> {
        if( !options ) options = [];
        const id_type_filter = this.tableConfig.filters.find( f => f.type == 'id_type' );
        const id_type = (id_type_filter ? this.tableConfig.form.get(id_type_filter.name).value : null) || IDType.UserCode;
        const opts = options.map( site => {
            let label = site.user_site;
            if( id_type == IDType.SystemCode ) {
                label = site.site;
            } else if ( id_type == IDType.OwnerCode ) {
                label = site.owner_site;
            }
            return { value: site, label, hidden: false };
        });
        return this.filterAsVisibleOptions(target, opts);
    }

    noOptionFound(options: Array<VisibleOption>): boolean {
        return options.filter( opt => !opt.hidden ).length == 0;
    }

    resetFilter(): void {
        this.form.reset();
        const id_type = this.tableConfig.filters.find(f => f.type == 'id_type');
        if( id_type ) {
            this.form.get(id_type.name).patchValue('user');
        }
    }

    getIDType(): IDType {
        const f = this.tableConfig.filters.find( f => f.type == 'id_type' );
        const value = this.tableConfig.form.get( f.name ).value;

        return value || IDType.UserCode;
    }

    hasWebtagFilter(): boolean {
        return !!this.tableConfig.filters.find(f => f.type == 'webtag')
    }

    filterLoopTrackBy(v: DataTableFilter): any {
        return v.name;
    }

    handleDatePeriodBtnClick(i: DataTableFilter, days: number) {

        let { from, to } = this.form.value[i.name] as { from: string, to: string };

        if( from )
        {
            const dt = new Date(from);
            dt.setTime( dt.getTime() + (86400000 * days) );
            const [year, month, day] = [dt.getFullYear(), dt.getMonth()+1+'', dt.getDate()+''];

            from = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }

        if( to )
        {
            const dt = new Date(to);
            dt.setTime( dt.getTime() + (86400000 * days) );
            const [year, month, day] = [dt.getFullYear(), dt.getMonth()+1+'', dt.getDate()+''];

            to = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
        }

        this.form.get(i.name).patchValue({ from, to });
    }

}

interface VisibleOption {
    value: any;
    label: string;
    hidden?: boolean;
}
