import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftRightSelectorComponent } from './left-right-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { LeftRightSelectorRowTemplate } from './directives';
import { ScrollingModule } from '@angular/cdk/scrolling';


@NgModule({
    declarations: [LeftRightSelectorComponent, LeftRightSelectorRowTemplate],
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, MatIconModule, MatCheckboxModule, MatButtonModule, ScrollingModule],
    exports: [LeftRightSelectorComponent, LeftRightSelectorRowTemplate]
})
export class LeftRightSelectorModule { }
