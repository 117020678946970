import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../../login.service';
import { LoginData } from '../../models';
import { Router } from '@angular/router';
import { finalize, take } from 'rxjs/operators';
import { AuthService } from 'app/core/services/auth.service';
import { AlertService } from 'app/shared/angular-material/snake-bar-module/services/alert.service';
import { ErrorResponse } from 'app/core/interfaces';
import { Webtag, WebtagLoginPageOptions } from 'app/pages/admin/webtags/models';
import { PwaService } from 'app/core/services/pwa.service';
import { MatDialog } from '@angular/material/dialog';
import { SysConfigService } from 'app/pages/admin/sys-config/sys-config.service';

const KEEP_KEY = 'keep_account_pwd';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['login.component.scss'],
})
export class LoginComponent implements OnInit {

    form: UntypedFormGroup;
    loading: boolean;
    webtag: Webtag;
    styles: WebtagLoginPageOptions = {};

    keep_account_pwd: boolean = false;

    constructor(
        private fb: UntypedFormBuilder,
        private loginService: LoginService,
        private authService: AuthService,
        private router: Router,
        private alertService: AlertService,
        public pwa: PwaService,
        public dialog: MatDialog,
        private sysConfigService: SysConfigService,
    ) {}

    ngOnInit(): void {
        this.authService.siteWebtag.pipe( take(1) )
            .subscribe( webtag => this.webtag = webtag );
        this.form = this.fb.group({
            account: this.fb.control('', [Validators.required]),
            password: this.fb.control('', [Validators.required]),
        });
        if (localStorage.getItem(KEEP_KEY)) {
            try {
                const data = JSON.parse(localStorage.getItem(KEEP_KEY));
                this.form.patchValue(data);
                this.keep_account_pwd = true;
            } catch (e) {
            }
        }
        this.sysConfigService.getSysConfig('webtag_'+this.webtag.domain+'_login_config')
            .subscribe(res => {
                if( res.data.data ) {
                    try {
                        this.styles = JSON.parse(res.data.data);
                    } catch(e) {}
                }
            }, err => {});
        this.loading = false;
    }

    handleSubmit() {
        if ( this.form.valid ) {
            this.loading = true;
            this.form.disable();
            this.loginService.login(this.form.getRawValue() as LoginData)
                .pipe(
                    finalize( () => {
                        this.loading = false;
                        this.form.enable();
                    })
                )
                .subscribe(
                    loggedUser => {
                        this.loading = true;

                        if (this.keep_account_pwd) {
                            localStorage.setItem(KEEP_KEY, JSON.stringify(this.form.getRawValue()));
                        } else {
                            localStorage.removeItem(KEEP_KEY);
                        }

                        this.authService.initData().then(() => {
                            if (window.screen.width < 768) {
                                this.router.navigate(['/quick-menu']);
                            } else {
                                this.router.navigate(['/dashboard']);
                            }
                        });
                    },
                    (error: ErrorResponse) => this.alertService.error(error.message, 5000)
                );
        }
    }

}
