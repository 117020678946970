import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { PaginatorPagingEvent } from './models';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ElementRef } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { throttle } from '../utils';
import { interval } from 'rxjs';


@Component({
    selector: 'data-table-paginator',
    templateUrl: './data-table-paginator.component.html',
    styleUrls: ['./data-table-paginator.component.scss']
})
export class DataTablePaginatorComponent implements OnInit {

    @Input() total: number = 0;

    @Input() limit: number = 10;
    @Output() limitChange = new EventEmitter<number>();

    @Input() page: number = 1;
    @Output() pageChange = new EventEmitter<number>();

    @Input() fixed_limit: boolean = false;

    public total_page_subject = new BehaviorSubject<number>(1);
    public total_page = this.total_page_subject.asObservable();

    @Input() debounce: number = 100;

    @Output() paging: EventEmitter<PaginatorPagingEvent> = new EventEmitter<PaginatorPagingEvent>();

    public form: UntypedFormGroup = new UntypedFormGroup({});
    @Input() limit_options: Array<number> = [10, 20, 30, 40];

    mode: 'mobile' | 'desktop' = 'desktop';

    private subscriptions: Array<Subscription> = [];

    constructor(private hostElement: ElementRef) {
        // this.subscriptions.push(interval(250).subscribe( this.onResize.bind(this) ));
    }

    ngOnInit(): void {
        this.form = new UntypedFormGroup({
            limit: new UntypedFormControl(this.limit),
            page: new UntypedFormControl(this.page),
        });
        this.subscriptions.push(
            this.form.valueChanges.pipe( debounceTime(100) ).subscribe(this.handleFormGroupChange.bind(this))
        );
    }

    ngAfterViewInit() {
        let total_page = Math.ceil(this.total / this.limit);
        if( total_page <= 0 ) { total_page = 1; }

        this.total_page_subject.next( total_page );
        setTimeout(this.onResize.bind(this), 10);
    }

    ngOnDestroy(): void { this.subscriptions.forEach(s => s.unsubscribe()); }

    ngOnChanges(changes: any) {
        if( changes.total ) {
            this.total_page_subject.next( Math.ceil(this.total / this.limit) || 1 );
            if( this.total_page_subject.value < this.page ) {
                this.form.get('page') && this.form.get('page').patchValue( this.total_page_subject.value );
            }
        }

        if( changes.page && this.page != this.form.value.page ) {
            this.form.get('page') && this.form.get('page').patchValue(this.page);
        }

        if( changes.limit && this.limit != this.form.value.limit ) {
            this.form.get('limit') && this.form.get('limit').patchValue(this.limit);
        }
    }

    handleFormGroupChange(v: { limit: number, page: number}) {
        let total_page = Math.ceil(this.total / v.limit) || 1;

        this.total_page_subject.next( total_page );
        if( v.page > total_page ) {
            v.page = total_page;
            this.form.get('page') && this.form.get('page').patchValue(total_page);
        }
        this.limitChange.emit(v.limit);
        this.pageChange.emit(v.page);
        this.paging.emit({
            limit: v.limit,
            page: v.page,
            total: this.total,
            total_page: this.total_page_subject.value,
            offset: (v.page - 1) * v.limit,
        });
    }

    // 0: final page, 1: next page
    handlePageForward(page: 0 | 1) {
        const current_page: number = parseInt(this.form.get('page') && this.form.get('page').value || this.page);
        const total_page = this.total_page_subject.value;

        if( page > 0 && current_page <= total_page ) {
            this.form.get('page') && this.form.get('page').patchValue( current_page + page );
        } else {
            this.form.get('page') && this.form.get('page').patchValue( total_page );
        }
    }

    // 0: first page, 1: prev page
    handlePageBackward(page: 0 | 1) {
        const current_page: number = parseInt(this.form.get('page') && this.form.get('page').value || this.page);

        if( page > 0 && current_page - page > 0 ) {
            this.form.get('page') && this.form.get('page').patchValue((current_page - page) || 1);
        } else {
            this.form.get('page') && this.form.get('page').patchValue(1);
        }
    }

    // forLoop in template
    forLoop(n: number | null): Array<number> {
        const arr: Array<number> = [];

        if( n == null ) { n = 1 }
        for(let i = 1 ; i <= n ; i++) { arr.push( i ); }

        return arr;
    }

    // Paginator Mode Detect
    @HostListener('window:resize', ['$event'])
    onResize = throttle((event?: any) => {
        // const width: number = this.hostElement.nativeElement.parentElement.offsetWidth;
        const width: number = window.screen.width;

        this.mode = width < 500 ? 'mobile' : 'desktop';
    }, this.debounce);

}
