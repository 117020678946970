import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DataTable, DataTableColumn } from '../../data-table/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'dt-sort-button',
  templateUrl: './dt-sort-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DtSortButtonComponent {

    @Input() column!: DataTableColumn<any>;

    @Input() config!: DataTable<any>;

    private sub: Subscription;

    constructor(
        private changeDetect: ChangeDetectorRef,
    ) { }

    ngOnInit() {
        const control = this.config.form.get('sort');
        if (control) {
            this.sub = control.valueChanges.subscribe(() => this.changeDetect.markForCheck());
        }
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }

    getSortIcon(col: DataTableColumn<any>): string {
        const form = this.config.form;
        const sort = form.get('sort');
        if( !sort || !col.sort ) return '';

        const sortName = col.sort?.sortName ?? col.name;
        const current = sort.value || '';
        const currentDir = current[0] == '-' ? 'DESC' : 'ASC';
        const currentName = current[0] == '-' ? current.substr(1) : current;

        if( currentName == sortName ) {
            return currentDir == 'ASC' ? 'arrow_drop_up' : 'arrow_drop_down';
        } else {
            return 'unfold_more';
        }
    }

    handleSortClick(col: DataTableColumn<any>): void {
        const form = this.config.form;
        const sort = form.get('sort');
        if( !sort ) return;
        const sortName = col.sort?.sortName ?? col.name;
        const current = sort.value || '';
        const currentDir = current[0] == '-' ? 'DESC' : 'ASC';
        const currentName = current[0] == '-' ? current.substr(1) : current;

        if( currentName == sortName ) {
            switch (currentDir) {
                case 'ASC':
                    sort.patchValue( '-'+sortName );
                    break;
                case 'DESC':
                    sort.patchValue(null);
                    break;
            }
        } else {
            sort.patchValue( sortName );
        }
    }

}
