import { animate } from '@angular/animations';
import { style } from '@angular/animations';
import { transition } from '@angular/animations';
import { trigger } from '@angular/animations';

export const ErrorLabelAnimation = (time: string, delay: string = '') => {
	if( delay ) time = time + ' ' + delay;

	return trigger('error-label',
	[
		transition(':enter', [
			style({ opacity: 0, transform: 'translateY(-10px)' }),
			animate(time, style({ opacity: 1, transform: 'translateY(0)' })),
		]),
	]);
};
