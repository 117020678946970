import {Component, Input} from '@angular/core';

@Component({
    selector: 'property-display',
    templateUrl: 'property-display.html',
    styleUrls : ['property-display.scss']
})
export class PropertyDisplayComponent {
    @Input() label = '';
}
